.object-iframe {
	min-height: 6.25em;
	width: 100%;
	-webkit-overflow-scroll: touch;

	> iframe {
		min-height: 6.25em;
		width: 100%;
		-webkit-overflow-scroll: touch;
	}
}


.content.popup-content{
	background-color: #fff;
	width: 70%;
	margin: 170px auto;
	iframe{
		width: 100%;
		min-height: 1100px;
	}
}