$item-border: 1px solid color(gray, lighter);

.-module-06b {
	//background: linear-gradient( color(gray, lightest) 0%, color(white) 100% );
	margin: 0 auto;
	max-width: 86em;

	&.-full {
		max-width: 100%;
	}

	&.-nobg {
		background: none;
	}


	.icon {
		@include icon($module-icon-sm);
	}

	.module-intro {
		text-align: center;

		@include media($xxlarge-up) {
			margin: 2em auto 0 auto;
			max-width: 42em;
		}

		@include media($xlarge-up) {
			padding-top: 6em;
		}

		@include media($xlarge-only) {
			margin: 2em auto 0 auto;
			padding: 4em $gutter*2 5em $gutter*2;
		}

		@include media($large-only) {
			margin: 2em auto;
			max-width: 40em;
			padding: 6em $gutter 5em $gutter;
		}

		@include media($medium-only) {
			padding: 3em $gutter*2 1em $gutter*2;
			margin: 1em 0;

			&:after {
				margin-left: -9.375em;
				width: 18.75em;
			}
		}

		@include media($small-only) {
			padding: 2em $gutter 1em $gutter;
			margin: 1em 0;

			&:after {
				margin-left: -8.625em;
				width: 17.25em;
			}
		}
	}

	.module-items {

		@include media($xlarge-up) {
			margin: 1em auto 4em;
		}

		@include media($large-up) {
			align-items: stretch;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
		}

		@include media($xlarge-only) {
			margin: 1em auto 5em auto;
			max-width: 42.375em;
		}

		@include media($large-only) {
			margin: 1em auto 3.75em auto;
			max-width: 46.375em;
			padding: 0 $gutter;
		}

		@include media($to-large) {
			margin: 0 auto;
			max-width: 28em;
			padding: 0 $gutter;
		}
	}

	.module-item {
		text-align: center;

		@include media($xxlarge-up) {
			border-right: $item-border;
			padding: 0 2.5em;
			margin: 2.5em 0;
			width: 25%;

			&:nth-child(4n),
			&:last-child {
				border-right: 0;
			}
		}

		// For XLarge and Large items
		@mixin large-xlarge-block {
			border-right: $item-border;
			padding: 3em;
			width: 50%;

			&:nth-child(odd) {
				padding-left: 0;
			}

			&:nth-child(even) {
				padding-right: 0;
			}

			// Never add a vertical border on the last item of each row
			&:nth-child(2n) {
				border-right: 0;
			}

			// Do not add border to the first two elements
			&:not(:nth-child(-n+2)), {
				border-top: $item-border;
			}

			// First two items
			&:nth-child(-n+2) {
				padding-top: 1.5em;
			}

			// Last two items
			&:nth-child(n+2) {
				padding-bottom: 1.5em;
			}
		}

		@include media($xlarge-only) {
			@include large-xlarge-block;
		}

		@include media($large-only) {
			@include large-xlarge-block;
		}

		@include media($to-large) {

			&:not(:last-child) {
				border-bottom: $item-border;
			}

		}

		@include media($medium-only) {
			padding: 3em 1em;
		}

		@include media($small-only) {
			padding: 2.5em 0;
		}

		// Set a margin for the icon
		.icon {
			display: block;
			margin: 0 auto 1.5em auto;
		}
	}
}
