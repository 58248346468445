//  $$\       $$\           $$\
//  $$ |      \__|          $$ |
//  $$ |      $$\ $$$$$$$\  $$ |  $$\  $$$$$$$\
//  $$ |      $$ |$$  __$$\ $$ | $$  |$$  _____|
//  $$ |      $$ |$$ |  $$ |$$$$$$  / \$$$$$$\
//  $$ |      $$ |$$ |  $$ |$$  _$$<   \____$$\
//  $$$$$$$$\ $$ |$$ |  $$ |$$ | \$$\ $$$$$$$  |
//  \________|\__|\__|  \__|\__|  \__|\_______/
//
//
//

// By default, no links shall be underlined
a {
	text-decoration: none;
}

@mixin copy-link {

	a {
		color: color(primary);

		&:hover {
			color: color(primary, light);
		}
	}
}
