//  $$\                                                $$\
//  $$ |                                               $$ |
//  $$ |      $$$$$$\  $$\   $$\  $$$$$$\  $$\   $$\ $$$$$$\
//  $$ |      \____$$\ $$ |  $$ |$$  __$$\ $$ |  $$ |\_$$  _|
//  $$ |      $$$$$$$ |$$ |  $$ |$$ /  $$ |$$ |  $$ |  $$ |
//  $$ |     $$  __$$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |  $$ |$$\
//  $$$$$$$$\\$$$$$$$ |\$$$$$$$ |\$$$$$$  |\$$$$$$  |  \$$$$  |
//  \________|\_______| \____$$ | \______/  \______/    \____/
//                     $$\   $$ |
//                     \$$$$$$  |
//                      \______/

.container {

	&.-outer {
		margin: 0 auto;

		@include media($xxlarge-up) {
			max-width: 74.375em + ($gutter*2);
			padding: 0 $gutter;
		}

		@include media($xlarge-only) {
			max-width: 59.25em + ($gutter*2);
		}

		@include media($to-xlarge) {
			max-width: 43.125em + ($gutter*2);
			padding: 0 $gutter;
		}

		// XXL and XL Max width is equal to "large" max-width
		&.-outer-limit1 {
			max-width: 60em;
		}

		// Even more condensed, used for copy, etc.
		&.-outer-limit2 {
			max-width: 48em;
		}
	}
}

section {
	@include clearfix;
}

.visually-hidden {
	display: none;
	visibility: hidden;
}
