// Main Footer
.footer-main {
	background-color: color(gray, lightest);
	border-top: 1px solid color(gray, lighter);

	> .content-widescreen {
		align-items: flex-start;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 66em;
		padding: 3.5em $gutter;
		text-align: left;

		@include media($to-xlarge) {
			flex-wrap: wrap;
			padding-bottom: 0;
		}

		> .block {

			&.-b01 {
				align-items: flex-start;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				width: 100%;
				border-right: 2px solid color(primary);
				padding-right: $gutter*2;
				@include media($to-xlarge) {
					flex-wrap: wrap;
					border-bottom: 2px solid color(primary);
					border-right: none;
					padding-right: 0;
				}

				nav{
					@include media($to-xlarge) {
						width: 50%;
						margin-bottom: rem(40);
					}
				}
			}

			&.-b02 {
				border-right: 2px solid color(primary);
				padding-right: $gutter*2;
				width: 30%;
				@include media($to-xlarge) {
					width: 100%;
				}
			}

			&.-b03{
				padding-left: $gutter*2;
			}

			&.-b04 {
				width: 40%;
				@include media($to-xlarge) {
					width: 100%;
				}
			}
		}

		// Site Links
		.site-links {

		}

		// Headers
		.header {
			@include p-xxsm();
			@include caps();
			color: color(primary);
			font-weight: $header-font-weight;
			margin-bottom: 1.25em;

			&.-big {
				@include p-xsm();
			}
		}

		// Link lists
		.items {

			> .item {
				@include p-xsm;
				padding: .5em 0;

				> .link {
					display: block;
				}
			}
		}

		// Address
		.address {
			@include p-sm(1.5);
			margin-bottom: 1em;
		}

		// Telephone
		.tel {
			@include p-xxsm();
		}

		// Social Links
		.social-links {
			@include clearfix;
			margin-top: 1.5em;
		}

		// Subscribe
		.email-subscribe {
			margin-bottom: 2.5em;
			min-width: 15.625em;
			position: relative;

			// Position submit button
			.button-arrow {
				position: absolute;
				right: -4px;
				top: -6px;
				@include z-index(priority6);
			}
		}

		.legal {

			@include media($to-xlarge) {
				display: none;
			}

			> p {
				@include p-xsm(1.4);
			}
		}

	}

	> .content-mobile {
		padding: 2.5em $gutter;
		text-align: center;

		@include media($xlarge-up) {
			display: none;
		}

		// Link lists
		.items {
			@include clearfix;
			margin: 0 auto;
			max-width: 28.25em;
			text-align: center;

			> .item {
				display: inline-block;

				@include media($small-only) {
					width: 100%;
				}

				> .link {
					@include p-md;
					@include caps();
					color: color(primary);
					display: block;
					font: {
						weight: $header-font-weight;
					}
					padding: 1em;
				}
			}
		}

		.legal {
			text-align: left;

			> p {
				@include p-xsm(1.4);
				display: block;
				padding-bottom: 1em;
				margin: 0 .5em;

				@include media($small-only) {
					display: block;
				}
			}
		}
	}
}
