.carousel-home-news {
    background: color(gray, lighter);
    padding: rem(54) 0;
}

.carousel-home-news__inner {
    max-width: 86em;
    margin: 0 auto;
    padding: 0 rem(26);
    display: flex;
    flex-direction: row;

    @include media($to-large) {
        flex-direction: column;
    }

    @include media($large-up) {
        padding: 0 rem(56);
    }
}

.carousel-home-news__heading {
    width: 25%;
    text-align: center;

    .title {
        font-size: 1.9rem;
        line-height: 1;
    }

    @include media($xlarge-up) {
        width: 10%;
        text-align: left;
    }
}

.carousel-home-news__slides {
    width: 75%;
    padding: 0 rem(80) 0 rem(20);

    @include media($to-large) {
        width: 100%;
    }

    .flickity-prev-next-button {
        &.previous {
            display: none;
        }

        &.next {
            right: 10px;
            height: 66px;
            top: 50%;
            @include flickity-next;

            @include media($xlarge-up) {
                right: 0;
            }
        }
    }

    @include media($xlarge-up) {
        width: 90%;
        padding: 0 rem(136) 0 rem(20);
    }

    @include flickity-enabled;
}

.carousel-home-news__slide {
    width: 100%;
    background: color(gray, lighter);
    padding: rem(12) 0 0;

    @include media($xlarge-up) {
        width: 33.3333%;
        padding: rem(12);
    }
}

.carousel-home-news__slide-heading {
    font-size: rem(12);
    margin-bottom: rem(10);

    @include caps;
}
