.nav-sections {
    $container-height: 4.625em;
    background-color: color(gray, lightest);
    border-bottom: 1px solid color(gray, lighter);

    > .container {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        height: $container-height;
        justify-content: center;
        overflow: hidden;
        position: relative;
        -ms-overflow-style: none;

        &::before {
            background: linear-gradient(90deg, color(gray, lightest) 0%, rgba( color(white), 0 ) 100%);
            content: '';
            display: block;
            height: 100%;
            width: 2em;
            position: absolute;
            top: 0;
            left: 0;
        }

        &::after {
            background: linear-gradient(-90deg, color(gray, lightest) 0%, rgba( color(white), 0 ) 100%);
            content: '';
            display: block;
            height: 100%;
            width: 2em;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    // .header {
    // 	align-items: center;
    // 	display: flex;
    // 	flex-shrink: 0;
    // 	height: 100%;
    // 	padding: 0 .5em 0 1em;
    // 	position: relative;
    //
    // 	&::after {
    // 		background: linear-gradient(90deg, color(gray, lightest) 0%, rgba( color(white), 0 ) 100%);
    // 		content: '';
    // 		display: block;
    // 		height: 100%;
    // 		width: 2em;
    // 		position: absolute;
    // 		top: 0;
    // 		right: -2em;
    // 	}
    // }

    .title {
        font: {
            weight: $header-font-weight;
        }
    }

    .items {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        // overflow-x: scroll; Removed to fix IE showing scrollbar on hover
        padding: 0 3em 0 .5em;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;

        > .item {
            flex-shrink: 0;

            &.-active {

                > .link {
                    color: color(secondary);
                    font: {
                        weight: $header-font-weight;
                    }

                    > .text {
                        border-bottom: 1px solid color(secondary);
                    }
                }
            }

            > .link {
                display: block;
                padding: 1.875em .75em;

                > .text {
                    font: {
                        size: 0.85em;
                    }
                }
            }
        }
    }
}
