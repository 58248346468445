.-module-07b {
	margin: 1.875em auto;
	max-width: 86em;

	.module-nav {
		@include clearfix;
		background: color(gray, light);
		max-width: 100%;

		@include media($xlarge-up) {
			height: 28.375em;
		}

		@include media($large-only) {
			height: 46em;
		}

		@include media($medium-only) {
			height: 29.375em;
		}

		@include media($small-only) {
			height: 19.375em;
		}

	}

	.module-nav-item {
		background: color(white);
		overflow-x: hidden;
		position: relative;

		@include media($xlarge-up) {
			height: 29.375em;
			width: 50%;
		}

		@include media($to-xlarge) {
			width: 100%;
		}

		@include media($large-only) {
			height: 47em;
		}

		@include media($medium-only) {
			height: 29.375em;
		}

		@include media($small-only) {
			height: 19.375em;
		}

		.inner {
			background: color(white);
			overflow: hidden;
			width: 100%;

			@include media($xlarge-up) {
				height: 28.375em;
			}

			@include media($large-only) {
				height: 46em;
			}

			@include media($medium-only) {
				height: 28.375em;
			}

			@include media($small-only) {
				height: 18.375em;
			}

			.visual {
				display: block;
				position: relative;
				height: 100%;
				width: 100%;

				img {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					max-height: 150%;
					min-height: 100%;
					max-width: 150%;
					min-width: 100%;
				}
			}
		}

		img {
			opacity: 0.65;
			transition: opacity 0.85s ease-in;
		}

		// Acive Bar
		&:before {
			background-color: color(gray);
			bottom: 1em;
			content: '';
			display: block;
			height: 0;
			left: 0;
			opacity: 0;
			position: absolute;
			transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
			width: 100%;
			z-index: 2;
		}

		// Active Triangle
		&:after {
			// We only show this for X-Larte up
			@include media($xlarge-up) {
				@include triangle(down, color(gray), 2em);
				content: '';
				position: absolute;
				bottom: 1em;
				left: 50%;
				margin-left: -1em;
				transition: all 0.5s ease-in-out, transform 0.4s $ease-in-out-cubic, visibility 0.5s $ease-in-out-cubic;
				opacity: 0;
				visibility: hidden;
				z-index: 3;
			}
		}

		&.is-selected {

			.inner {
				background-color: color(secondary);
			}

			img {
				mix-blend-mode: multiply;
				opacity: 1;
			}

			&:before {
				height: 1em;
				opacity: 1;
			}

			&:after {

				@include media($xlarge-up) {
					opacity: 1;
					transform: translateY(1em);
					visibility: visible;
				}
			}
		}
	}

	.module-content {
		position: relative;

		// This psudeo element blocks the ability to drag. Drag has to be enabled for this to work in flickity, but we don't want to drag the navigation items so we will fake an element on top to block interaction with them.
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			width: 100%;

			// @include media($xlarge-up) {
			// 	height: 28.375em;
			// 	top: -28.375em;
			// }

			@include media($large-only) {
				height: 46em;
				top: -46em;
			}

			@include media($medium-only) {
				height: 29.375em;
				top: -29.375em;
			}

			@include media($small-only) {
				height: 20.375em;
				top: -20.375em;
			}
		}

		// Disable/Enable Flickity
		&:after {

			// Disable Flickity
			@include media($xlarge-up) {
				content: '';
			}

			// Enable Flickity
			@include media($to-xlarge) {
				content: 'flickity';
				display: none;
			}
		}

		.flickity-prev-next-button {
			transition: background-color 0.6s $ease-in-out-cubic, opacity 0.4s $ease-in-out-cubic;

			@include media($xlarge-up) {
				display: none;
			}

			@include media($xxlarge-up) {
				width: 7.0625em;
			}

			@include media($xlarge-only) {
				width: 4.25em;
			}

			@include media($to-xlarge) {
				top: 30%;
			}

			&.previous {
				// Hide Previous Button for large views I guess?
				@include media($xlarge-up) {
					display: none;
				}
			}
		}
	}

	.module-content-block {
		width: 100%;

		> .module-item {
			@include clearfix;

			@include media($xxlarge-up) {
				margin: 0 auto;
				max-width: 72em;
				padding: 6.825em 0;
			}

			@include media($xlarge-only) {
				margin: 0 auto;
				max-width: 54.875em;
				padding: 5em 0;
			}

			@include media($large-only) {
				padding: 5em 8em;
			}

			@include media($medium-only) {
				padding: 4em 3em;
			}

			@include media($small-only) {
				padding: 2em 3em;
			}

			> .content {

				@include media($xlarge-up) {
					display: flex;
					flex-flow: row wrap;
				}

				@include media($to-xlarge) {
					text-align: center;
				}

				.title {

					@include media($xlarge-up) {
						flex: 1 100%;
					}
				}

				.description {

					@include media($xlarge-up) {
						flex: 1 55%;
					}
				}

				.action {

					@include media($xlarge-up) {
						flex: 1 45%;
						text-align: right;
					}

					@include media($to-xlarge) {
						padding-top: 4em;
					}

					> .button {

						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}
}
