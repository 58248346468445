.-module-03a {
	$bg-img-name: bg-module-03a;
	@include bg-default;

	// --------------------------------------
	// Moudle 03a Layouts & Styling
	// --------------------------------------

	.icon {
		@include icon();
	}

	// XXLarge-up
	@include media($xlarge-up) {
		padding-bottom: 4em;

		.module-content {
			max-width: 56em;
		}

		// Wrapping Container
		.module-presentation {
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}#{$bg-img-name}-xl.png) center top no-repeat;
			height: 33em;
			width: 100%;

			@include image-2x(url(#{$bg-img-path}#{$bg-img-name}-xl@2x.png) center top no-repeat, 861px, 498px)

			// Position the graphic(s)
			> .graphic {
				top: 30px;
				left: 50%;
				margin-left: -328px;
				width: 656px;
				height: 410px;
			}
		}

		.module-items {
			margin: 0 auto;
			max-width: 50em;
		}

		.module-item {
			padding: 2em 5.25em 0 5.25em;
			width: 100%;
		}

	}

	// Small - Large
	@include media($to-xlarge) {

		.module-items-outer {
			@include clearfix;
			background-color: color(gray, lighter);
			padding-top: 2em;
		}

		.module-visual {
			width: 100%;
		}
	}

	// Large Only
	@include media($large-only) {

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}#{$bg-img-name}-l.png) center top no-repeat;
			height: 32em;

			@include image-2x(url(#{$bg-img-path}#{$bg-img-name}-l@2x.png) center top no-repeat, 706px, 408px)

			// Position the graphic(s)
			> .graphic {
				top: 24px;
				left: 50%;
				margin-left: -269px;
				height: 336px;
				width: 538px;
			}
		}
	}

	// Medium Only
	@include media($medium-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}#{$bg-img-name}-m.png) center top no-repeat;
			height: 22em;

			@include image-2x(url(#{$bg-img-path}#{$bg-img-name}-m@2x.png) center top no-repeat, 480px, 276px)

			// Position the graphic(s)
			> .graphic {
				top: 17px;
				left: 50%;
				margin-left: -183px;
				height: 228px;
				width: 366px;
			}
		}
	}

	// Small Only
	@include media($small-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}#{$bg-img-name}-s.png) center top no-repeat;
			height: 16em;

			@include image-2x(url(#{$bg-img-path}#{$bg-img-name}-s@2x.png) center top no-repeat, 380px, 218px)

			// Position the graphic(s)
			> .graphic {
				top: 13px;
				left: 50%;
				margin-left: -145px;
				height: 180px;
				width: 290px;
			}
		}
	}
}
