.copy-visual-3col {
	@include clearfix;

	@include media($xlarge-up) {
		display: flex;
		margin: 0 auto;
		max-width: 69.5em;
		padding: 0 $gutter/2;
	}

	.col {

		@include media($xlarge-up) {
			margin: $gutter/2;
			width: 33.3%;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		@include media($to-xlarge) {

		}

		.visual {

			img {
				display: block;
			}
		}

		.content {
			padding: 3em 2em;
			text-align: center;

			@include media($large-only) {
				margin: 0 auto;
				max-width: 29em;
			}

			p {
				@include p-sm();
			}
		}

		.title {
			@include h-content-h3;
			color: color(sky, dark);
			margin-bottom: 1em;
		}
	}
}
