.-module-10a {
	background-color: color(white);
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: 86em;

	@include media($xlarge-up) {
		margin: {
			top: 1.25em;
			bottom: 1.25em;
		}
	}

	@include media($large-up) {
		padding: 6em 4em 4em 2em;
	}

	@include media($large-only) {
		margin: .75em 0;
	}

	@include media($to-large) {
		align-items: flex-start;
		border-bottom: 1px solid color(gray, lighter);
		justify-content: space-between;
		padding: 4em 2em;
	}

	@include media($small-only) {
		padding: 1.5em;
	}

	&:first-child {
		margin-top: 0;
	}

	.brand {

		@include media($large-up) {
			align-items: flex-start;
			display: flex;
			margin-right: 5%;
			justify-content: center;
			width: 30%;
		}

		@include media($to-large) {
			order: 3;
			max-width: 9em;
		}

		@include media($small-only) {
			display: block;
			text-align: left;
		}

		img {
			max-height: 100%;
			max-width: 100%;

			@include media($xxlarge-up) {
				max-width: 17.5em;
			}

			@include media($xlarge-only) {
				max-width: 14em;
			}

			@include media($large-only) {
				max-width: 12em;
			}
		}
	}

	.quote-testimonial {
		padding-bottom: 2em;

		@include media($large-up) {
			width: 65%;
		}

		@include media($to-large) {
			order: 1;
		}
	}

	.footer-blockquote {

		@include media($large-up) {
			margin: 0 0 0 35%;
		}

		@include media($to-large) {
			margin: 0;
			max-width: 30em;
			order: 2;
			padding: 0 $gutter $gutter 0;
		}

		@include media($small-only) {
			width: 100%;
		}


		.title {

			@include media($large-up) {

				&:before {
					content: '\2013';
					padding-right: .25em;
				}
			}

			@include media($to-large) {
				display: block;
				padding-top: .5em;
			}
		}
	}
}
