//  $$\      $$\ $$\       $$\
//  $$ | $\  $$ |\__|      $$ |
//  $$ |$$$\ $$ |$$\  $$$$$$$ | $$$$$$\   $$$$$$$\  $$$$$$$\  $$$$$$\   $$$$$$\   $$$$$$\  $$$$$$$\
//  $$ $$ $$\$$ |$$ |$$  __$$ |$$  __$$\ $$  _____|$$  _____|$$  __$$\ $$  __$$\ $$  __$$\ $$  __$$\
//  $$$$  _$$$$ |$$ |$$ /  $$ |$$$$$$$$ |\$$$$$$\  $$ /      $$ |  \__|$$$$$$$$ |$$$$$$$$ |$$ |  $$ |
//  $$$  / \$$$ |$$ |$$ |  $$ |$$   ____| \____$$\ $$ |      $$ |      $$   ____|$$   ____|$$ |  $$ |
//  $$  /   \$$ |$$ |\$$$$$$$ |\$$$$$$$\ $$$$$$$  |\$$$$$$$\ $$ |      \$$$$$$$\ \$$$$$$$\ $$ |  $$ |
//  \__/     \__|\__| \_______| \_______|\_______/  \_______|\__|       \_______| \_______|\__|  \__|
//
//

// Main Site Navigation
.redesign-nav-main-outer {
	background: color(white);
}

body.no-scroll{
	position: fixed;
}

.redesign-nav-main{
	&.-widescreen {
		@include clearfix;
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin: 0 auto;
		justify-content: space-between;
		max-width: $nav-max-width;
		padding: 1em 0;

		.nav-brand.nav-block {
		    width: rem(250);
		}

		.navs-container{
			display: flex;
			flex-direction: column;
			width: 100%;

			> .nav-primary {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				flex: 1;
				height: 100%;
				margin-top: 1em;

				.button{
					&:hover,
					&:active,
					&:focus{
						background-color: color(white);
						color: color(primary);
						border: 1px solid color(primary);
					}
				}

				// 'Parent' items that should be inline-blocked
				> .nav-block {
					display: flex;
					height: 100%;
				}

				// Container for Brand
				> .nav-brand {
					align-items: center;
					display: flex;
					background-color: color(primary);
					margin-right: 1em;
					justify-content: center;
					width: 10.25em;

					// Logo item (probably an <a>)
					> .logo {
						display: block;

						&:hover {
							opacity: 0.85;
						}

						&:active {
							transform: scale(0.98);
						}
					}
				}

				// The parent container for links (probably an <ul>)
				> .nav-items {
					@include clearfix;
					align-items: center;
					display: flex;

					// Item container (probably a <li>)
					> .nav-item {
						@include inline-block;

						&.nav-dropdown{
							&.open{
								background-color: color(gray, lightest);
							}
						}

						.nav-dropdown_content{
							display: none;
							width: 100%;
							position: absolute;
							left: 0;
							background-color: #eee;
							border-bottom: 6px solid color(secondary);
							opacity: 0;
							-webkit-transition: opacity 0.3s;
						    -moz-transition: opacity 0.3s;
						    transition: opacity 0.3s;
						    background-color: color(gray, lightest);
						    z-index: 9999;

							&.open{
								opacity: 1;
								display: block;
							}
							.text{
								width: 100%;
								text-align: center;
								max-width: 962px;
								margin: 40px auto 0 auto;
								font-size: 22px;
								line-height: 26px;
								h3{
									font-size: 28px;
									line-height: 36px;
									margin-bottom: 15px;
								}
							}
							.markets{
								display: flex;
								flex-wrap: wrap;
								justify-content: center;
								margin: 0 -1%;
								padding: 40px;
								.card{
									flex-direction: row;
									width: 23%;
									margin: 1%;
									max-height: 160px;
								}
							}
							.dropdown-subnavigation{
								display: flex;
								flex-direction: row;

								.tab-navigation{
									display: flex;
									flex-direction: column;
									width: 20%;
									background-color: color(blue, new);
									.tab-title{
										padding: 31px 55px;
										color: color(white);
										font-size: 28px;
										line-height: 36px;
										border-bottom: 1px solid color(gray, lightest);
										&:last-child{
											border-bottom: none;
										}
										&.active{
											background-color: color(gray, lightest);
											color: color(gray, base);
										}
									}
								}

								.tabs{
									display: flex;
									flex-direction: column;
									width: 80%;

									.tab{
										display: flex;
										flex-direction: row;
										-webkit-transition: opacity 0.3s;
									    -moz-transition: opacity 0.3s;
									    transition: opacity 0.3s;
									    opacity: 0;

									    .logo{
									    	max-width: 56px;
									    }
										
										.tab-contnent{
											display: none;
										}
										&.active {
											opacity: 1;
											.tab-contnent{
												display: flex;
												flex-direction: row;
												.tab-main{
													width: 70%;
													padding: 43px 31px;
													p{
														margin-bottom: 36px;
													}
													.tab-tags{
														margin-top: 60px;
														display: flex;
														flex-direction: row;
														span{
															width: 10%;
														}
														ul{
															width: 80%;
															li{
																display: inline-block;
															}
														}
														.logo{
															width: 10%;
														}
													}
												}
												.tab-sidebar{
													width: 30%;
													background-color: color(sky, lighter);
													padding: 40px;
													.story-row{
														display: flex;
														flex-direction: row;
														align-items: center;
													}
													.story-head{
														svg{
															width: 35%;
															fill: color(gray, dark);
															stroke: color(gray, dark);
															opacity: .5;
															& > *{
																fill: color(gray, dark);
																stroke: color(gray, dark);
																& > *{
																	fill: color(gray, dark);
																	stroke: color(gray, dark);
																	*{
																		stroke: color(gray, dark);
																	}
																}
															}
														}
														h5{
															width: 65%;
														}
													}
													.story-bottom{
														margin-top: 29px;
														justify-content: space-between;
														a{
															width: 40%;
														}
														.logo{
															width: 70%;
														}
													}
													p{
														margin-top: 13px;
													}
													button{
														margin-top: 29px;
													}
												}
											}
										}
									}
								}
								
							}
						}

						// Item link element - should be block level
						> .nav-link {
							color: color(primary);
							display: block;
							font-weight: $header-font-weight;
							padding: .65em 1.6em;

							&:hover {
								opacity: 0.7;
							}

							> .text {
								border: {
									top: 6px solid transparent;
									bottom: 6px solid transparent;
								}
								display: inline-block;
								padding: 0.35em 0;
								text-transform: uppercase;
								font-weight: 600;
							}

							> .icon-arrow {
								display: inline-block;
								fill: color(primary, light);
								margin-left: .2em;
								transition: transform 0.5s $ease-in-out-sine;
								width: 5px;
								height: 10px;
							}

							// When parent item is open
							&.-is-open {

								 .icon-arrow {
									 transform: rotate(90deg);
									 transform-origin: center center;
								 }

								 &:hover {
									 opacity: 1;
								 }
							}
						}

						&.-is-active {

							> .nav-link {

								&:hover {
									opacity: 1;
								}

								> .text {
									border-bottom-color: color(secondary);
								}
							}
						}

						// Optical padding for link item(s) that have an arrow
						&.-has-children {

							> .nav-link {
								padding-right: 1.3em;
							}
						}
					}
				}

				.button{
					text-transform: uppercase;
					margin-top: .3em;
				}
			} // > .nav-primary

			> .nav-utility {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-end;
				width: 100%;

				> .nav-link {
					align-items: center;
					background: color(white);
					color: color(primary);
					display: flex;
					font: {
						weight: 400;
						size: 0.75em;
					}
					height: 100%;
					padding: 0 2.25em;

					&:hover {
						color: darken( color(primary), 6 );
					}

					&:active {
						color: darken( color(primary), 8 );
					}
				}
			} // > .nav-utility

		} // .navs-container
	}
}

// .nav-main {

// 	&.-widescreen {

// 		@include clearfix;
// 		align-items: center;
// 		display: flex;
// 		flex-direction: row;
// 		flex-wrap: nowrap;
// 		margin: 0 auto;
// 		justify-content: space-between;
// 		height: $main-nav-height-widescreen;
// 		max-width: $nav-max-width;

// 		> .nav-primary {
// 			display: flex;
// 			flex: 1;
// 			height: 100%;

// 			// 'Parent' items that should be inline-blocked
// 			> .nav-block {
// 				display: flex;
// 				height: 100%;
// 			}

// 			// Container for Brand
// 			> .nav-brand {
// 				align-items: center;
// 				display: flex;
// 				background-color: color(white);
// 				margin-right: 1em;
// 				justify-content: center;
// 				width: 10.25em;

// 				// Logo item (probably an <a>)
// 				> .logo {
// 					display: block;

// 					&:hover {
// 						opacity: 0.85;
// 					}

// 					&:active {
// 						transform: scale(0.98);
// 					}
// 				}
// 			}

// 			// The parent container for links (probably an <ul>)
// 			> .nav-items {
// 				@include clearfix;
// 				align-items: center;
// 				display: flex;

// 				// Item container (probably a <li>)
// 				> .nav-item {
// 					@include inline-block;

// 					// Item link element - should be block level
// 					> .nav-link {
// 						color: color(white);
// 						display: block;
// 						font-weight: $header-font-weight;
// 						padding: .65em 1.6em;

// 						&:hover {
// 							opacity: 0.7;
// 						}

// 						> .text {
// 							border: {
// 								top: 2px solid transparent;
// 								bottom: 2px solid transparent;
// 							}
// 							display: inline-block;
// 							padding: 0.35em 0;
// 						}

// 						> .icon-arrow {
// 							display: inline-block;
// 							fill: color(gray, light);
// 							margin-left: .2em;
// 							transition: transform 0.5s $ease-in-out-sine;
// 							width: 5px;
// 							height: 10px;
// 						}

// 						// When parent item is open
// 						&.-is-open {

// 							 .icon-arrow {
// 								 transform: rotate(90deg);
// 								 transform-origin: center center;
// 							 }

// 							 &:hover {
// 								 opacity: 1;
// 							 }
// 						}
// 					}

// 					&.-is-active {

// 						> .nav-link {

// 							&:hover {
// 								opacity: 1;
// 							}

// 							> .text {
// 								border-bottom-color: color(secondary);
// 							}
// 						}
// 					}

// 					// Optical padding for link item(s) that have an arrow
// 					&.-has-children {

// 						> .nav-link {
// 							padding-right: 1.3em;
// 						}
// 					}
// 				}
// 			}

// 		}

// 		> .nav-utility {
// 			display: flex;
// 			flex-direction: row;
// 			flex-wrap: nowrap;
// 			height: 100%;

// 			> .nav-link {
// 				align-items: center;
// 				background: color(secondary);
// 				color: color(white);
// 				display: flex;
// 				font: {
// 					weight: 600;
// 				}
// 				height: 100%;
// 				padding: 0 2.25em;

// 				&:hover {
// 					background-color: darken( color(secondary), 6 );
// 				}

// 				&:active {
// 					background-color: darken( color(secondary), 8 );
// 				}
// 			}
// 		}
// 	}
// }

// Language Switcher
.language-switcher {
	// ACC-58 contact us link
	.in-nav-link{
		color: color(primary);
		display: block;
		font: {
			size: 0.75em;
			weight: 400;
		}
		padding: 0 1.25em;
		transition: opacity 0.2s ease-in-out;
		span{
			border-bottom: 1px solid rgba(255,255,255,0.6);
		}
		
		&:hover {
			opacity: 0.8;
		}
		&.-is-active{
			color: color(secondary);
			span{
				border-bottom: 1px solid color(secondary);
			}
			
			.icon-arrow {
				fill: color(secondary);
			}
		}
		.icon-arrow {
			display: inline-block;
			fill: color(primary, light);
			margin-left: .2em;
			transition: transform 0.5s $ease-in-out-sine;
			width: 3px;
			height: 8px;
		}
	}

	// Button to trigger drop-down
	> .trigger {
		color: color(primary);
		display: block;
		font: {
			size: 0.75em;
			weight: 400;
		}
		transition: opacity 0.2s ease-in-out;

		> .icon {
			border-left: 1px solid color(primary);
			display: inline-block;
			fill: color(primary);
			height: 1em;
			margin-left: .35em;
			vertical-align: middle;
			width: 3em;
			padding-left: 1rem;
		}

		> .icon-arrow {
			display: inline-block;
			fill: color(primary, light);
			margin-left: .2em;
			transition: transform 0.5s $ease-in-out-sine;
			width: 3px;
			height: 8px;
			transform: rotate(90deg);
		}

		// When parent item is open
		&.-is-open {

			 .icon-arrow {
				 transform: rotate(180deg);
				 transform-origin: center center;
			 }

			 &:hover {
				 opacity: 1;
			 }
		}

		&:hover {
			opacity: 0.8;
		}
	}
}

// Second level navigations
.nav-secondary {
	$secondary-border: 1px solid color(gray, lighter);
	background-color: color(gray, lightest);
	overflow: hidden;
	position: relative;
	width: 100%;
	will-change: transform;

	&::after {
		border-bottom: $secondary-border;
		content: '';
		height: 1px;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	// Parent nav group
	> .nav-group {
		margin: 0 auto;
		max-width: $nav-max-width;
		transform: translate3d(0, 0, 0);
		will-change: transform, visibility;

		&.-is-closed {
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.4s ease-out, visibility 0.5s ease-out;
			visibility: hidden;
		}

		&.-is-open {
			max-height: 20em;
			transition: max-height 0.4s ease-in, visibility 0.5s ease-in;
			visibility: visible;
		}

		> .nav-items {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			> .nav-item {
				align-items: center;
				border-right: $secondary-border;
				display: flex;
				height: 7em;
				text-align: center;
				justify-content: center;
				width: 16%;

				&:first-child {
					border-left: $secondary-border;
				}

				> .link {
					@include p-xsm;
					color: color(gray, dark);
					display: block;
					height: 100%;
					padding: 1em;
					width: 100%;

					&:hover {
						background: color(gray, lighter);
					}

					> .logo {
						align-items: center;
						display: flex;
						height: 7em;
						justify-content: center;
						width: 100%;

						img {
							max-width: 10em;
						}
					}
				}
			}
		}
	}
}

// Auto-hiding/sticky navigation styles.
.js-auto-hide-header {
	position: fixed;
	@include z-index(priority1);
	top: 0;
	left: 0;
	//height: $main-nav-height-widescreen; // Height set dynamically in nav-sticky.js
	width: 100%;
	/* Force Hardware Acceleration */
	transform: translateZ(0);
	will-change: transform;
	transition: transform .5s;

	&.is-hidden {
		transform: translateY(-100%);
	}
}

.js-secondary-nav {
	position: relative;
	@include z-index(priority2);
	clear: both;
	width: 100%;
	/* Force Hardware Acceleration */
	transform: translateZ(0);
	will-change: transform;
	transition: transform .5s;

	&.fixed {
		position: fixed;
		top: $main-nav-height-widescreen;
	}

	&.slide-up {
		transform: translateY(-60px);
	}
}
// ACC-58 request a demo breakpoint fix
@media screen and (min-width: 1024px) and (max-width: 1118px) {
	.nav-main.-widescreen{
		font-size: 14px;
	}
}