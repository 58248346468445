//   $$$$$$\            $$\                          $$\     $$\
//  $$  __$$\           \__|                         $$ |    \__|
//  $$ /  $$ |$$$$$$$\  $$\ $$$$$$\$$$$\   $$$$$$\ $$$$$$\   $$\  $$$$$$\  $$$$$$$\   $$$$$$$\
//  $$$$$$$$ |$$  __$$\ $$ |$$  _$$  _$$\  \____$$\\_$$  _|  $$ |$$  __$$\ $$  __$$\ $$  _____|
//  $$  __$$ |$$ |  $$ |$$ |$$ / $$ / $$ | $$$$$$$ | $$ |    $$ |$$ /  $$ |$$ |  $$ |\$$$$$$\
//  $$ |  $$ |$$ |  $$ |$$ |$$ | $$ | $$ |$$  __$$ | $$ |$$\ $$ |$$ |  $$ |$$ |  $$ | \____$$\
//  $$ |  $$ |$$ |  $$ |$$ |$$ | $$ | $$ |\$$$$$$$ | \$$$$  |$$ |\$$$$$$  |$$ |  $$ |$$$$$$$  |
//  \__|  \__|\__|  \__|\__|\__| \__| \__| \_______|  \____/ \__| \______/ \__|  \__|\_______/
//
//
//

// Fade In
@keyframes( fadeIn ) {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

// Fade Out
@keyframes( fadeOut ) {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

// Fade In and Slide Down
@keyframes( fadeInDown ) {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

// Fade Out and Slide Up
@keyframes( fadeOutUp ) {
	from {
		opacity: 1;
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 0;
		transform: none;
	}
}
