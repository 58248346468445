.layout-news-index {
	margin: 0 auto;
	max-width: 74.375em;

	@include media($xlarge-only) {
		max-width: 58.75em;
	}

	@include media($large-only) {
		max-width: 44.5em;
	}

	.articles-grid {

		@include media($large-only) {
			padding: 3em 3em;
		}

		@include media($to-large) {
			padding: 2em $gutter;
		}

		> .article-preview {

			@include media($to-xlarge) {
				padding: 1.5em 0;
			}
		}

		&.-inline {

			@include media($xlarge-up) {
				display: flex;
				padding: 1.5em;
			}

			> .article-preview {

				@include media($xlarge-up) {
					padding: 2.25em 1.5em;
					width: 33.3%;
				}
			}
		}

		&.-stacked {
			@include media($xxlarge-up) {
				padding: 3em 6em;
			}

			@include media($xlarge-only) {
				padding: 2em 4.5em;
			}

			> .article-preview {

				@include media($xlarge-up) {
					padding: 1.5em 0;
				}
			}
		}
	}
}
