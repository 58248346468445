.layout-grid-card-color-simple {
	$margin: 1em;
	display: flex;
	margin: 0 auto;
	flex-flow: row wrap;

	@include media($xlarge-up) {
		justify-content: flex-start;
	}

	@include media($to-xlarge) {
		justify-content: center;
	}

	> .card-color-simple {
		margin: $margin;

		@include media($xlarge-up) {

			&:nth-child(3n-2) {
				margin-left: 0;
			}

			&:nth-child(3n-0) {
				margin-right: 0;
			}
		}

		@include media($large-only) {

			&:nth-child(odd) {
				margin-left: 0;
			}

			&:nth-child(even) {
				margin-right: 0;
			}
		}

		@include media($medium-only) {
			margin: .5em;
		}

		@include media($small-only) {
			margin: .5em 0;
		}
	}
}
