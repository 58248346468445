.copy-3col-inline {
	align-items: center;
	border-bottom: 1px solid color(gray, lighter);
	display: flex;
	justify-content: flex-start;
	width: 100%;

	@include media($large-up) {
		height: 9.5em;
		flex-flow: row nowrap;
	}

	@include media($to-large) {
		flex-flow: row wrap;
		padding: 2.5em 0;
	}

	.col {

		h3 {
			@include h-content-h3;
		}

		p {
			@include p;
		}

		@include media($large-up) {

			&:first-child {
				padding-right: 2em;
				width: 35%;
			}

			&:nth-child(2) {
				padding-left: 1.5em;
				width: 40%;
			}

			&:nth-child(3) {
				width: 25%;
				padding-left: 1em;
				text-align: right;
			}
		}

		@include media($to-large) {
			width: 100%;

			&:first-child,
			&:nth-child(2) {
				padding-bottom: .75em;
			}
		}
	}
}
