// Main Site Header
.mfp-bg,
.mfp-wrap {
    top: 125px !important;
}
.header-main {
	@include clearfix;
	z-index: 8000;

	// Widescreen Header: XLarge and Up
	&.-widescreen {

		@include media($to-xlarge) {
			display: none;
		}
	}

	// Mobile Header: Small to XLarge
	&.-mobile {
		@include z-index(priority2);
		background: color(white);
		display: flex;
		flex-flow: row nowrap;
		height: $header-height-mobile;
		justify-content: flex-start;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		.nav-brand{
			margin-left: 34px;
		}

		.button{
			position: absolute;
			right: 100px;
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 600;

			@include media($small-only) {
				right: 75px;
				font-size: 0.625em;
			}
		}

		@include media($xlarge-up) {
			display: none;
		}
	}
}
