//  $$$$$$$$\        $$\       $$\
//  \__$$  __|       $$ |      $$ |
//     $$ | $$$$$$\  $$$$$$$\  $$ | $$$$$$\   $$$$$$$\
//     $$ | \____$$\ $$  __$$\ $$ |$$  __$$\ $$  _____|
//     $$ | $$$$$$$ |$$ |  $$ |$$ |$$$$$$$$ |\$$$$$$\
//     $$ |$$  __$$ |$$ |  $$ |$$ |$$   ____| \____$$\
//     $$ |\$$$$$$$ |$$$$$$$  |$$ |\$$$$$$$\ $$$$$$$  |
//     \__| \_______|\_______/ \__| \_______|\_______/
//
//
//

table {
	border-collapse: collapse;
	width: 100%;
}
th,
td {
	box-sizing: border-box;
	font-weight: $body-font-weight;
	text-align:left;
}
