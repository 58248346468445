/* ==========================================================================
    CTA - Cookie
   ========================================================================== */
.cta-cookie {
    background: linear-gradient(180deg, lighten(color(secondary),5%) 0%, color(secondary) 100%);
    bottom: 0;
    color: color(white, base);
    left: 0;
    position: fixed;
    right: 0;
    transform: translateY(100%);
    transition: all .3s ease;

    &.-show {
        transform: translateY(0%);
    }

    .-content {
        max-width: 40em;

        p {
            line-height: 1.2em;
            text-align: center;

            @include media($large-up) {
                text-align: left;
            }
        }
    }

    .button {
        margin-top: 1em;

        @include media($large-up) {
            margin-left: 1em;
            margin-top: 0;
        }
    }
}

.cta-cookie .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;

    @include media($large-up) {
        flex-direction: row;
    }
}
