.cta-full-visual {
	background-color: color(gray, lighter);
	background-size: cover;
	color: color(white);

	.container.-outer {
		align-items: center;
		display: flex;
		flex-flow: row wrap;
		height: 26.875em;
	}

	.content {

		@include media($xxlarge-up) {
			max-width: 40em;
		}

		@include media($xlarge-only) {
			max-width: 30em;
		}

		@include media($large-only) {
			max-width: 28.25em;
		}

		@include media($medium-only) {
			max-width: 20em;
		}

		@include media($small-only) {
			max-width: 17em;
		}
	}

	.title {
		@include  h-content-h1;
		margin-bottom: .85em;
	}

	.text {
		padding-bottom: 2em;

		@include media($to-large) {
			display: none;
		}
	}

	p {
		@include p;
	}
}
