.carousel-visual-gradient {
	margin: 1.5em auto;

	@include media($xxlarge-up) {
		max-width: 84.75em;
	}

	@include media($xlarge-only) {
		max-width: 69.5em;
	}

	@include media($xlarge-up) {
		padding: 0 $gutter/2;
	}

	@include media($large-only) {
		max-width: 48em;
	}

	.slide {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		width: 100%;

		@include media($xxlarge-up) {
			height: 27.5em;
		}

		@include media($xlarge-only) {
			height: 21em;
		}

		@include media($large-only) {
			height: 16em;
		}

		@include media($to-large) {
			height: 20em;
		}

		.visual {
			background: color(gray, lightest);
			height: 100%;
			overflow: hidden;

			@include media($xxlarge-up) {
				width: 31%;
			}

			@include media($xlarge-only) {
				width: 25%;
			}

			@include media($large-only) {
				margin-right: .5em;
				width: 33%;
			}

			@include media($to-large) {
				display: none;
			}

			&.-visual-2 {

				@include media($to-xlarge) {
					display: none;
				}
			}

			picture {
				max-height: 100%;
				max-width: 100%;

				> img {
					max-height: 120%;
					max-width: 120%;
				}
			}
		}

		.content {
			align-items: center;
			display: flex;
			flex-flow: row wrap;
			height: 100%;
			justify-content: center;
			text-align: center;
			width: 100%;

			@include media($xxlarge-up) {
				width: 34%;
			}

			@include media($xlarge-only) {
				width: 46%;
			}

			@include media($xlarge-up) {
			}

			@include media($large-only) {
				margin-left: 1em;
				width: 66%;
			}

			@include media($large-up) {
				padding: 2em 4em;
			}

			@include media($to-large) {
				padding: 2em 2em 4em 2em;
			}

			.subhead {
				display: block;
				font: {
					size: 0.9em;
					weight: $header-font-weight-heavy;
				}
				letter-spacing: 0.07em;
				padding-bottom: 1em;
				text-transform: uppercase;
			}

			.title {
				@include h-content-h1;
				padding-bottom: .5em;
			}

			p {
				@include p-sm;
			}
		}
	}

	.flickity-prev-next-button	{
		top: 50%;

		@include media($to-large) {
			display: none;
		}

		&.previous {
			left: 1em;

			@include media($large-only) {
				left: .25em;
			}
		}

		&.next {
			right: 1.5em;

			@include media($large-only) {
				right: .85em;
			}
		}

		svg {
			height: 65%;
			width: 65%;
		}

		.arrow {
			fill: color(white);
		}
	}

	.flickity-page-dots {
		bottom: 1em;

		@include media($large-up) {
			display: none;
		}

		.dot {
			border-color: color(white);

			&.is-selected {
				background-color: color(white);
			}
		}
	}
}
