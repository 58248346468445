//  $$$$$$$\             $$$$$$\                     $$\   $$\
//  $$  __$$\           $$  __$$\                    $$ |  $$ |
//  $$ |  $$ | $$$$$$\  $$ /  \__|$$$$$$\  $$\   $$\ $$ |$$$$$$\    $$$$$$$\
//  $$ |  $$ |$$  __$$\ $$$$\     \____$$\ $$ |  $$ |$$ |\_$$  _|  $$  _____|
//  $$ |  $$ |$$$$$$$$ |$$  _|    $$$$$$$ |$$ |  $$ |$$ |  $$ |    \$$$$$$\
//  $$ |  $$ |$$   ____|$$ |     $$  __$$ |$$ |  $$ |$$ |  $$ |$$\  \____$$\
//  $$$$$$$  |\$$$$$$$\ $$ |     \$$$$$$$ |\$$$$$$  |$$ |  \$$$$  |$$$$$$$  |
//  \_______/  \_______|\__|      \_______| \______/ \__|   \____/ \_______/
//
//
//

// ---------------------------------------------------------------------------------
// Debug Mode
// ---------------------------------------------------------------------------------

$debug-mode: false !default;

// ---------------------------------------------------------------------------------
// BG Image Path
// ---------------------------------------------------------------------------------

$bg-img-path: '/assets/img/';
//$bg-img-path: '/img/';


// ---------------------------------------------------------------------------------
// Web Fonts
// ---------------------------------------------------------------------------------

$fonts-path: '/assets/fonts/';

// ---------------------------------------------------------------------------------
// Colors
// ---------------------------------------------------------------------------------

$color-black: #000000;
$color-white: #ffffff;

// http://erskinedesign.com/blog/friendlier-colour-names-sass-maps/
$colors: (
	white: (
		base: #FFFFFF,
		bone: #FBFAF5,
	),
	black: (
		base: #000000,
	),
	gray: (
		lightest: #F8F8F8,
		lighter : #EEEEEE,
		light   : #c3c3c3,
		midLight: #A2A2A2,
		mid     : #979797,
		base    : #4A4A4A,
		dark    : #2F2F2F,
        darker  : #272727,
	),
	primary: (
		light: #4A67EA,
		base: #2B388F,
	),
	secondary: (
		base: #94C83D,
	),
	sky: (
		lightest: #FAFDFE,
		lighter : #E8F6FB,
		light   : #D4EAFF,
		mid     : #B1E8FF,
		base    : #72BFF5,
		dark    : #14A5DE,
	),
    blue: (
        base: #2C3791,
        lighter: #4A67EA,
        new:  #0C175E,
    ),
    purple: (
        base: #500F83,
    ),
	highlighter: (
		base: #F2DB0A,
	),
	pumpkin: (
		base: #F48020,
	),
	passion: (
		base: #ED4C41,
	),
	whoops: (
		base: #F36F6F,
	),
);

@function color($color, $tone: 'base') {
	@return map-get(map-get($colors, $color), $tone);
}

$default-body-color      : color(gray, darker);
$default-link-color      : color(gray);
$default-link-color-hover: color(gray);

// ---------------------------------------------------------------------------------
// Breakpoints
// ---------------------------------------------------------------------------------

$landscape  : new-breakpoint(orientation landscape);
$portrait   : new-breakpoint(orientation portrait);

$small-up   : new-breakpoint(min-width 0px);
$small-only : new-breakpoint(max-width 479px);

$medium-up  : new-breakpoint(min-width 480px);
$medium-only: new-breakpoint(min-width 480px max-width 767px);

// for grid edge cases:
$midscreen-only: new-breakpoint(min-width 640px max-width 767px);

$to-large   : new-breakpoint(max-width 767px);
$large-up   : new-breakpoint(min-width 768px);
$large-only : new-breakpoint(min-width 768px max-width 1023px);

$to-xlarge  : new-breakpoint(max-width 1023px);
$xlarge-up  : new-breakpoint(min-width 1024px);
$xlarge-only: new-breakpoint(min-width 1024px max-width 1279px);

$to-xxlarge : new-breakpoint(max-width 1279px);
$xxlarge-up : new-breakpoint(min-width 1280px);

// ---------------------------------------------------------------------------------
// Sizing
// ---------------------------------------------------------------------------------

$base-font-size:    16;
$base-line-height : 1.5;
$line-height-ratio: $base-line-height / $base-font-size;

// ---------------------------------------------------------------------------------
// Type
// ---------------------------------------------------------------------------------

$base-font-family        : "Whitney SSm A", "Whitney SSm B", "Helvetica Neue", Arial, Helvetica, sans-serif;
$base-font-family        : "Whitney SSm A", "Whitney SSm B", "Helvetica Neue", Arial, Helvetica, sans-serif;
$body-font-weight        : 300;
$header-font-weight      : 400;
$header-font-weight-heavy: 600;

// ---------------------------------------------------------------------------------
// Module Icons
// ---------------------------------------------------------------------------------

$module-icon-sm: 2.5em;
$module-icon-lg: 4.375em;

// ---------------------------------------------------------------------------------
// Z-Index
// ---------------------------------------------------------------------------------
$z-index: (
	priority1 : 9000,
	priority2 : 8000,
	priority3 : 7000,
	priority4 : 6000,
	priority5 : 5000,
	priority6 : 4000,
	priority7 : 3000,
	priority8 : 2000,
	priority9 : 1000,
);

@function z($key) {
	@if map-has-key($z-index, $key) {
		@return map-get($z-index, $key);
	}
	@warn "Unknown `#{$key}` in $z-index.";
	@return null;
}

// ---------------------------------------------------------------------------------
// Global Variables for things
// ---------------------------------------------------------------------------------

// Nav Sizing
// $main-nav-height-widescreen: 2.375em;
$main-nav-height-widescreen: 0;
$nav-max-width: 74.375em;
$header-height-mobile: 4em;
$mobile-nav-width: 14.5em;

$input-primary-height: 2.5em;

// ---------------------------------------------------------------------------------
// PX to REM
// ---------------------------------------------------------------------------------

@function rem($target) {
  @return ($target / 16) * 1rem;
}
