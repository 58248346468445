.carousel-testimonial-headshot {
	@include clearfix;
	background: color(white);
	margin: 0 auto;

	@include media($xlarge-up) {
		max-width: 69.5em;
	}

	@include media($large-only) {
		max-width: 39.875em;
	}

	@include media($to-large) {
		max-width: 28em;
	}

	.slide {
		align-items: flex-start;
		background-color: color(white);
		width: 100%;

		@include media($large-up) {
			display: flex;
			flex-flow: row nowrap;
			padding: 4em 4em 5em 4em;
		}

		@include media($to-large) {
			padding: 2em 2em 4em 2em;
			text-align: center;
		}

		.headshot {

			@include media($xlarge-up) {
				width: 12.5em;
			}

			@include media($to-xlarge) {
				width: 10em;
			}

			@include media($large-up) {
				margin-right: 2em;
			}

			@include media($to-large) {
				margin: 0 auto 1em auto;
			}

			img {
				border-radius: 50%;
			}
		}

		.content {

			@include media($xlarge-up) {
				width: calc(100% - 14.5em);
			}

			@include media($large-only) {
				width: calc(100% - 12em);
			}
		}
	}

	.flickity-page-dots {
		bottom: 1em;

		.dot {
			border-color: color(gray, mid);

			&.is-selected {
				background-color: color(gray, mid);
			}
		}
	}
}
