// Social Links
.social-links {
	@include clearfix;

	> .item {
		display: inline-block;

		> .link {
			align-items: center;
			background-color: color(primary);
			border-radius: 50%;
			display: flex;
			height: 1.5em;
			justify-content: center;
			margin-right: .5em;
			width: 1.5em;

			&:hover {
				transform: scale(1.1);
			}

			> .icon {
				fill: color(white);
				height: .75em;
				width: .75em;
			}
		}
	}
}
