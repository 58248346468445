//  $$$$$$\
//  \_$$  _|
//    $$ |  $$$$$$\$$$$\   $$$$$$\   $$$$$$\   $$$$$$\   $$$$$$$\
//    $$ |  $$  _$$  _$$\  \____$$\ $$  __$$\ $$  __$$\ $$  _____|
//    $$ |  $$ / $$ / $$ | $$$$$$$ |$$ /  $$ |$$$$$$$$ |\$$$$$$\
//    $$ |  $$ | $$ | $$ |$$  __$$ |$$ |  $$ |$$   ____| \____$$\
//  $$$$$$\ $$ | $$ | $$ |\$$$$$$$ |\$$$$$$$ |\$$$$$$$\ $$$$$$$  |
//  \______|\__| \__| \__| \_______| \____$$ | \_______|\_______/
//                                  $$\   $$ |
//                                  \$$$$$$  |
//                                   \______/

// Fluid Images
img {
	display: inline-block;
	height: auto;
	margin: 0;
	padding: 0;
	max-height: 100%;
	max-width: 100%;
}

object {
	svg {
		max-with: 100% !important;
	}
}
