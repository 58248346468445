.accordion-content {
	border-bottom: 1px solid color(gray, light);

	.checkbox-toggle:checked ~ .content {
		height: auto;
		opacity: 1;
		visibility: visible;
	}

	.checkbox-toggle:checked ~ .title {

		> .text {
			color: color(sky, dark);
		}

		> .icon {
			transform: rotate(90deg);
		}
	}

	> .title {
		align-items: center;
		cursor: pointer;
		display: flex;
		height: 5.25em;
		justify-content: space-between;
		position: relative;

		&.-inline-link {
			color: color(primary);

			&:hover {
				opacity: 0.7;
			}
		}

		> .text {
			@include h-content-h3;
			color: color(primary);

			@include media($to-large) {
				font-size: 1.125em;
			}
		}

		> .icon {
			transition: transform 300ms $ease-in-out-quad;
		}
	}

	> .content {
		height: 0;
		opacity: 0;
		overflow: hidden;
		transition: all 300ms $ease-in-out-quad;
		visibility: hidden;
		will-change: opacity, visibility, height;

		> .wrap {
			padding: 0 0 2em 0;
		}

		p {
			@include p;
		}

		ul {
			@include ul;
		}

		a {
			color: color(primary);
		}
	}
}
