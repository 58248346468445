.cta-simple-headline-link {
	align-items: center;
	background: linear-gradient(to right, color(primary, light) 0%, color(primary) 100%);
	display: flex;
	height: 23em;
	text-align: center;

	> .container {
		margin: 0 auto;
		max-width: 58.75em;
		padding: 0 $gutter;
	}

	.title {
		color: color(white);
		@include h-mod-block-title-xl;
	}
}
