.icon {
	transition: fill 300ms $ease-in-out-sine, opacity 300ms $ease-in-out-sine, trasform 300ms $ease-in-out-sine;
}

// Show/Hide +/- icon
// Should live inside of element that has [data-nav="showhide"]
[data-nav="showhide"] {

	> .icon-plus {
		display: block;
		height: .8em;
		width: .8em;
		will-change: transform;

		.bar {
			background: color(gray, mid);
			border-radius: 1px;
			display: block;
			height: 2px;
			position: absolute;
			transition: opacity 400ms $ease-in-out-sine, transform 400ms $ease-in-out-sine;
			width: .8em;
		}
	}

	&.-is-closed {

		> .icon-plus {

			.bar {

				&.-one {
					transform: rotate(90deg);
				}
			}
		}
	}

	&.-is-open {

		> .icon-plus {

			.bar {

				&.-one {
					opacity: 0;
					transform: rotate(90deg);
				}

				&.-two {
					transform: rotate(180deg);
				}
			}
		}
	}
}

// Play Button
.icon-play-button {
	align-items: center;
	background-color: rgba( color(primary), 0.5 );
	border-radius: 4px;
	display: flex;
	height: 3.125em;
	justify-content: center;
	transition: background-color 300ms $ease-in-out-sine;
	width: 7.5em;

	&:hover {
		background-color: rgba( color(primary), 0.9 );
	}

	> .icon {
		fill: color(white);
		height: 1.375em;
		position: relative;
		right: -4px; // optimal spacing
		width: 1.375em;
	}
}
