.language-switcher-mobile {
	background: color(gray, lightest);
	height: calc(100% - 3em);
	overflow-y: scroll;
	justify-content: center;
	padding-top: 5em;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	will-change: transform;
	@include z-index(priority2);
	-webkit-overflow-scrolling: touch;

	&.-is-hidden {
		visibility: hidden;
		transform: translateY(120%);
		transition: visibility 400ms $ease-out-sine, transform 400ms $ease-out-sine;
	}

	&.-is-visible {
		visibility: visible;
		transform: none;
		transition: visibility 500ms $ease-in-sine, transform 500ms $ease-in-sine;
	}

	> .close {
		align-items: center;
		display: flex;
		height: 3.125em;
		position: absolute;
		justify-content: center;
		top: 3.75em;
		right: 0;
		width: 3.125em;

		> .icon-close {
			fill: color(gray);
			height: 1em;
			width: 1em;
		}
	}

	> .intro {
		background: url(#{$bg-img-path}world-map.svg) center 1em no-repeat;
		background-size: 104px auto;
		padding: 5.125em 1em 1em 1em;
		text-align: center;

		p {
			@include p-sm(1.3);
		}
	}

	> .listing {
		padding: 1em 0;
		width: 100%;

		> .items {

			.item {
				border-bottom: 1px solid color(gray, lighter);

				> .link {
					@include clearfix;
					display: block;
					font: {
						size: 0.875em;
					}
					padding: 1em .5em;

					> .greeting,
					> .language {
						display: block;
						vertical-align: middle;
						width: 48%;
					}

					> .greeting {
						float: left;
						text-align: center;
					}

					> .language {
						font-weight: $header-font-weight;
						float: right;
					}
				}

				&.-is-active {

					> .link {
						background-color: color(secondary);
					}
				}
			}
		}
	}
}

// Styling for widescreen
.language-switcher-widescreen {
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	> .intro {
		align-items: center;
		background: url(#{$bg-img-path}world-map.svg) 1em center no-repeat;
		background-size: 210px auto;
		display: flex;
		padding: 1em 3em 1em 15em;
		min-height: 10.125em;
		width: 50%;

		p {
			@include p-md;
		}
	}

	> .listing {
		padding: 2em 1em 2em 0;
		width: 50%;

		> .items {
			columns: 3;
			vertical-align: top;

			.item {
				break-inside: avoid;

				> .link {
					@include clearfix;
					align-items: center;
					display: flex;
					font: {
						size: 0.875em;
					}
					height: 3.5em;

					> .greeting,
					> .language {
						display: block;
						font-weight: $header-font-weight;
						padding: .5em;
						transition: background-color 300ms ease-in-out;
						vertical-align: middle;
						width: 48%;
					}

					> .greeting {
						background-color: color(gray, mid);
						color: color(white);
						float: left;
						text-align: center;
					}

					> .language {
						float: right;
					}

					&:hover {

						> .greeting {
							background-color: color(gray);
						}
					}
				}

				&.-is-active {

					> .link > .greeting {
						background-color: color(secondary);
					}
				}
			}
		}
	}
}

.language-switcher{
	position: relative;

	&.open{
		.icon-arrow{
			-webkit-transform: rotate(-90deg) !important;
		    -moz-transform: rotate(-90deg) !important;
		    -o-transform: rotate(-90deg) !important;
		    -ms-transform: rotate(-90deg) !important;
		    transform: rotate(-90deg) !important;
		}
		ul.items{
			display: block;
			z-index: 9;
		}
	}

	ul{
		&.items{
			display: none;
			background-color: color(white);
			-webkit-box-shadow: 6px 6px 3px 1px rgba(0,0,0,0.1);
			-moz-box-shadow:    6px 6px 3px 1px rgba(0,0,0,0.1);
			box-shadow:         6px 6px 3px 1px rgba(0,0,0,0.1);
			position: absolute;
			left: 1em;
			top: 1em;
			li{
				a{
					display: block;
					padding: 1em 2em;
				    color: color(primary);
				    font-size: 0.75em;
				    font-weight: 400;
				    &:hover,
				    &:active,
				    &:focus{
				    	background-color: color(gray, lighter)
				    }
				}
			}
		}
	}
}
