.header-title-search {
	display: flex;
	margin: 0 auto;

	@include media($large-up) {
		align-items: center;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding: {
			top: 5em;
			bottom: 2em;
		}
	}

	@include media($to-large) {
		flex-flow: column nowrap;
		padding: 1.5em $gutter;
		text-align: center;
	}

	.title {
		@include h-content-h2;
		min-width: 10em;

		@include media($large-up) {
			order: 1;
		}

		@include media($to-large) {
			padding-top: 1em;
			width: 100%;
		}

		&.-with-filter {

			> .title-text {

				@include media($to-large) {
					display: block;

					&:after {
						content: ':';
						display: inline-block;
					}
				}
			}

			> .filter-text {

				@include media($large-up) {

					&:before {
						border-left: 1px solid color(secondary);
						content: '';
						display: inline-block;
						height: 1em;
						margin: 0 .5em;
						vertical-align: middle;
						width: 1px;
					}
				}
			}
		}
	}

	.form-search {

		@include media($large-up) {
			order: 2;
			text-align: right;
		}

		@include media($to-large) {
			width: 100%;
		}

		input[type="search"] {

			@include media($large-up) {
				width: 12.5em;

				&:focus {
					width: 20em;
				}
			}
		}
	}
}
