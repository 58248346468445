.logos-intro {
	align-items: center;
	display: flex;
	flex-flow: row wrap;
	padding: {
		left: $gutter;
		right: $gutter;
	}
	justify-content: center;

	.title {
		@include h-content-h3;
		padding: 1em;
	}

	.logos {
		align-items: center;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;

		.logo {
			margin: 1em 2em;
			max-width: 7.8125em;
		}
	}
}
