.layout-grid-bio-cards {
	align-items: flex-start;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;

	@include media($xlarge-up) {
		margin: 0 auto;
		max-width: 60em;
	}

	@include media($large-only) {
		margin: 0 auto;
		max-width: 48em;
	}

	@include media($to-large) {
		margin: 0 auto;
		max-width: 24em;
	}

	> .card-bio {

		@include media($medium-up) {
			width: 12em;
		}

		@include media($small-only) {
			max-width: 12em;
			min-width: 9em;
		}
	}

	.bio-holder {
		@include p-sm;
		width: 100%;

		&.-is-closed {
			height: 0;
			opacity: 0;
			overflow: hidden;
			transition: opacity 300ms $ease-in-out-quad, visibility 300ms $ease-in-out-quad;
			visibility: hidden;
		}

		&.-is-open {
			height: inherit;
			opacity: 1;
			overflow: visible;
			transition: opacity 300ms $ease-in-out-quad, visibility 300ms $ease-in-out-quad;
			visibility: visible;
		}

		p {
			@include p;
		}

		.content {
			margin: 0em 0 2em 0;
			padding: 1em;
		}

		&[data-bio="holder-xl"] {
			//background: red;
		}

		&[data-bio="holder-l"] {
			//background: blue;
		}

		&[data-bio="holder-s"] {
			//background: orange;
		}
	}
}
