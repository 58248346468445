.photo-grid-content-4 {
	margin: 0 auto;

	@include media($xlarge-up) {
		height: 28.75em;
		max-width: 58.75em;
	}

	@include media($large-only) {
		max-width: 37em;
	}

	@include media($to-large) {
		max-width: 26.25em;
	}

	> .blocks {
		position: relative;
		@include z-index(priority2);

		@include media($xlarge-up) {
			height: 28.75em;
			width: 58.75em;
		}

		@include media($large-only) {
			width: 37em;
		}

		@include media($to-large) {
			max-width: 26.25em;
		}

		> .block {
			background: color(gray, lighter);

			@include media($xlarge-up) {
				position: absolute;
			}

			&.-b01 {

				@include media($xlarge-up) {
					height: 28.75em;
					width: 28.75em;
					top: 0;
					left: 0;
				}

				@include media($large-only) {
					height: 28.75em;
					margin-bottom: 1.5em;
					width: 100%;
				}

				@include media($to-large) {
					margin-bottom: 1.5em;
					max-height: 26.25em;
					width: 100%;
				}
			}

			&.-b02 {

				@include media($xlarge-up) {
					height: 13.75em;
					width: 13.75em;
					right: 15em;
				}

				@include media($large-only) {
					display: inline-block;
					height: 13.75em;
					margin-bottom: 1.5em;
					width: 17.5em;
				}

				@include media($to-large) {
					display: inline-block;
					margin-bottom: 1.5em;
					max-height: 12.5em;
					width: calc(50% - 0.75em);
				}
			}

			&.-b03 {

				@include media($xlarge-up) {
					height: 13.75em;
					width: 13.75em;
					top: 0;
					right: 0;
				}

				@include media($large-only) {
					display: inline-block;
					float: right;
					height: 13.75em;
					margin-bottom: 1.5em;
					width: 17.5em;
				}

				@include media($to-large) {
					display: inline-block;
					float: right;
					margin-bottom: 1.5em;
					max-height: 12.5em;
					width: calc(50% - 0.75em);
				}
			}

			&.-b04 {

				@include media($xlarge-up) {
					bottom: 0;
					right: 0;
					height: 13.75em;
					width: 28.75em;
				}

				@include media($large-only) {
					display: block;
					height: 13.75em;
					width: 100%;
				}

				@include media($to-large) {
					display: block;
					max-height: 12.5em;
					width: 100%;
				}
			}

			&.-video-block {

				> .overlay {
					align-items: center;
					display: flex;
					height: 100%;
					justify-content: center;
					overflow: hidden;
					position: absolute;
					transition: background 300ms $ease-in-out-quad;
					top: 0;
					left: 0;
					width: 100%;
					@include z-index(priority2);

					@include media($xlarge-up) {
						height: 28.75em;
					}

					@include media($large-only) {
						height: 28.75em;
					}

					@include media($to-large) {
						max-height: 26.25em;
					}

					&:before {
						background: linear-gradient(45deg, color(black) -15%, rgba(color(black), 0.0) 115%);
						content: '';
						display: block;
						height: 100%;
						opacity: 0.6;
						transition: opacity 300ms $ease-in-out-quad;
						width: 100%;
						position: absolute;
						left: 0;
						top: 0;
						z-index: -1;
					}

					&:hover {

						&:before {
							opacity: 0.1;
						}

						.icon-play-button {
							background-color: rgba( color(primary), 0.9 );
						}
					}

					.header {
						position: absolute;
						bottom: 2em;
						right: 2em;

						@include media($to-large) {
							bottom: 1.5em;
							right: 1em;
						}

						> .title {
							@include h-xxl($body-font-weight, 1.1, 0);
							color: color(white);
							text-align: right;
						}
					}
				}

				> .base {
					@include z-index(priority3);
				}
			}

			// Hide these blocks from small to large
			&.-block-small-hide {

				@include media($to-large) {
					display: none;
				}
			}
		}
	}
}
