.nav-solutions-subnav {
	$border: 1px solid color(gray, lighter);
	background-color: color(gray, lightest);
	border-top: $border;
	border-bottom: $border;
	position: relative;

	&::after {

		@include media($to-xlarge) {
			background: linear-gradient(-90deg, color(gray, lightest) 0%, rgba( color(white), 0 ) 100%);
			content: '';
			display: block;
			height: 100%;
			width: 3em;
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	> .nav-group {

		@include media($xlarge-up) {
			height: 5em;
			margin: 0 auto;
			max-width: $nav-max-width;
		}

		@include media($to-xlarge) {
			height: 4.625em;
			overflow-x: scroll;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			-webkit-overflow-scrolling: touch;
		}

		> .nav-items {
			align-items: center;
			display: flex;
			height: 100%;
			flex-flow: row nowrap;
			justify-content: flex-start;

			@include media($xlarge-up) {
				height: 100%;
			}

			@include media($to-xlarge) {
				width: 100%;
			}

			> .nav-item {
				border-right: $border;
				position: relative;

				@include media($xlarge-up) {
					height: 100%;
				}

				@include media($to-xlarge) {
					&:last-child {
						border-right: 0;
						padding-right: 3.5em;
					}
				}

				&.-parent {

					@include media($to-xlarge) {
						display: none;
					}

					> .link {

						@include media($xlarge-up) {
							width: 10.25em;
						}
					}

					.logo {
						width: 4.5em;
						
						img {
							height: inherit;
							max-width: 100%;
						}
					}
				}

				&.-is-active {

					&::after {

						@include media($xlarge-up) {
							background-color: color(secondary);
							content: '';
							display: block;
							height: 6px;
							width: 100%;
							position: absolute;
							left: 0;
							bottom: -1px;
						}
					}

					> .link {
						color: color(primary);

						&:hover {
							color: color(primary);
						}

						@include media($xlarge-up) {
							background: linear-gradient(180deg, color(gray, lightest) 25%, rgba(color(gray, light), 0.4) 100%);
						}
					}
				}

				> .link {
					align-items: center;
					color: color(gray, mid);
					display: flex;
					justify-content: center;
					text-align: center;

					@include media($xlarge-up) {
						flex-flow: column nowrap;
						height: 100%;
						width: 9.75em;
					}

					@include media($to-xlarge) {
						padding: .65em 1.25em;
					}

					&:hover {
						color: color(gray, dark);
					}

					> .icon {
						display: block;
						fill: currentColor;
						height: 1.875em;
						width: 1.875em;

						@include media($xlarge-up) {
							margin-bottom: .5em;
						}

						g {
							fill: currentColor !important;
						}
					}

					> .text {
						font-size: 0.825em;
						font-weight: $header-font-weight;
					}
				}
			}
		}
	}
}

.solutions-subnav{
	background-color: color(primary);
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;

	@include media($to-large) {
		flex-direction: column;
		align-items: center;
	}

	li{
		padding: 1em 0;
		@include media($to-large) {
			padding: 0;
		}

		&.active{
			background-color: color(white);
			border-bottom: 1px solid color(gray, mid);
			border-top: 1px solid color(gray, mid);
			a{
				color: color(gray, darker);
				border-right: none;
				border-bottom: none;
			}
		}

		a{
			display: inline-block;
			color: color(white);
			padding: 1em 3em;
			border-right: 1px solid color(primary, light);
			border-bottom: none;

			@include media($to-large) {
				border-right: none;
				border-bottom: 1px solid color(primary, light);
			}

		}
		&:last-child{
			a{
				border-right: none;
				@include media($to-large) {
					border-bottom: none;
				}
			}
		}
	}
}

.solution-item{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	background-color: color(gray, lightest);
	margin: 31px 0;

	@include media($to-large) {
		flex-direction: column-reverse;
	}

	&:nth-child(2n){
		@include media($large-up) {
			flex-direction: row-reverse;
			.item-image{
				margin-left: 0;
				margin-right: 40px;
			}
			.item-main{
				padding-left: 0;
				padding-right: 40px;
			}
		}
		
	}
	.item-main{
		width: 50%;
		padding: 40px;
		@include media($to-large) {
			width: 100%;
		}
		& > p{
			margin-bottom: 36px;
		}
		.tab-tags{
			margin-top: 36px;
			display: flex;
			flex-direction: row;
			@include media($to-xlarge) {
				flex-direction: column;
			}
			
			span{
				width: 15%;
				@include media($to-xlarge) {
					width: 100%;
				}
			}
			ul{
				width: 80%;
				@include media($to-xlarge) {
					width: 100%;
					li{
						a{
							font-size: 1rem;
						}
					}
				}

				li{
					display: inline-block;
				}
			}
		}
	}
	.item-image{
		width: 50%;
		margin-left: 40px;
		@include media($to-large) {
			width: 100%;
			height: 200px;
			margin-left: 0;
		}
		span{
			display: block;
			background: top center / cover no-repeat;
			width: 100%;
			height: 100%;
		}
	}
}
