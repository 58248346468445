.carousel-home-clients {
    max-width: 86em;
    margin: 0 auto;
    padding: 0 rem(26);

    @include media($xlarge-up) {
        padding: 0 rem(62);
    }
}

.carousel-home-clients__inner {
    padding: rem(30) 0 rem(68);
    min-height: 160px;
    @include media($xlarge-up) {
        padding: rem(30) 0;
    }
}

.carousel-home-clients__heading {
    background: color(primary);
    color: $color-white;
    text-align: center;
    height: rem(102);

    .title {
        line-height: 3.4;
        letter-spacing: -.01em;
        font-size: 1.9rem;

        @include media($xlarge-up) {
            font-size: rem(32);
            line-height: 2.2;
        }
    }

    @include media($xlarge-up) {
        height: rem(68);
    }
}

.carousel-home-clients__slides {
    padding: 0 rem(60) 0 rem(20);

    .flickity-prev-next-button {
        &.previous {
            display: none;
        }

        &.next {
            right: 10px;
            height: 66px;
            top: 50%;
            @include flickity-next;

            @include media($xlarge-up) {
                right: 0;
            }
        }
    }

    @include media($xlarge-up) {
        .flickity-viewport {
            min-height: rem(177);
        }
    }

    @include flickity-enabled;
}

.carousel-home-clients__slide {
    width: 33.3333%;
    padding: 0 rem(10);

    img{
        max-width: 65%;
        @include media($to-large) {
            max-width: 100%;
        }
    }

    @include media($xlarge-up) {
        width: 16.6667%;
    }
}

.flickity-slider{
    display: flex;
    align-items: center;
}
