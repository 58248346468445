/**
 * Layout for 404 page.
 */
.layout-404 {
	text-align: center;
	> .container {
		> .spacer {
			@include pad(10em 0);
			> header {
				> .headline1 {
					font: {
						size: 1.8em;
					}
				}
				> .headline2 {
					color: color(secondary);
					font: {
						size: 12em;
						weight: 500;
					}
					letter-spacing: -0.01em;
					@include media($to-large) {
						font: {
							size: 8em;
						}
					}
				}
				margin-bottom: 2em;
			}
			> .copy-block {
				margin: 0 auto;
				text-align: left;
				width: 50%;
				@include media($to-large) {
					text-align: center;
					width: 75%;
				}
				> .headline3 {
					margin-bottom: 1em;
				}
				> .list {
					margin: 1em 0 0 0;
					padding: 0;
					> li {
						list-style-type: none;
						margin: 0;
						padding: 0;
						> a {
							color: color(primary);
							text-decoration: none;
							transition: 0.2s all $ease-in-out-quad;
							&:hover {
								color: lighten(color(primary), 33%);
							}
						}
					}
					@include media($large-up) {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						> li {
							flex: 1 0 50%;
						}
					}
				}
			}
		}
	}
}