//  $$$$$$$$\
//  $$  _____|
//  $$ |    $$$$$$\   $$$$$$\  $$$$$$\$$$$\   $$$$$$$\
//  $$$$$\ $$  __$$\ $$  __$$\ $$  _$$  _$$\ $$  _____|
//  $$  __|$$ /  $$ |$$ |  \__|$$ / $$ / $$ |\$$$$$$\
//  $$ |   $$ |  $$ |$$ |      $$ | $$ | $$ | \____$$\
//  $$ |   \$$$$$$  |$$ |      $$ | $$ | $$ |$$$$$$$  |
//  \__|    \______/ \__|      \__| \__| \__|\_______/
//
//
//

$input-border: 1px solid color(gray, light);

// Nope, iOS. Nope.
input,
select,
textarea {
	appearance: none;
	border-radius: 0;
	width: 100%;
	-webkit-apperance: none;

	&::placeholder {
		color: color(gray);
	}
}

form {
	width: 100%;
}

#{$all-text-inputs},
textarea {
	width: 100%;

	@include media($to-large) {
		font-size: 16px; // oh, iOS
	}
}

label {
	display: block;
}

// Class used to hide all checkboxes when using the checkbox/label show/hide "hack"
.checkbox-toggle {
	display: none;
	height: 0;
	overflow: hidden;
	width: 0;
}

// Inputs
.input {
	transition: all 300ms ease-in-out;

	// Default Input
	&.-primary {
		border: $input-border;
		border-radius: 4px;
		height: $input-primary-height;
		line-height: $input-primary-height;
		padding: 0 .65em;

		&:focus {
			border-color: color(gray, mid);
		}

		&.error:focus {
			border-color: color(whoops);
		}
	}

	// Secondary -> Rounded
	&.-secondary {
		border: $input-border;
		border-radius: 40px;
		height: $input-primary-height;
		line-height: $input-primary-height;
		padding: 0 1.5em;

		@include media($large-up) {
			font-size: 0.875em;
		}
	}

	&.error {
		border-color: color(whoops);
	}
}

// Textarea
.textarea {
	transition: all 300ms ease-in-out;

	// Default Input
	&.-primary {
		border: $input-border;
		border-radius: 4px;
		line-height: 1.5;
		min-height: 7.5em;
		padding: .65em;

		&:focus {
			border-color: color(gray, mid);
		}

		&.error:focus {
			border-color: color(whoops);
		}
	}

	&.error {
		border-color: color(whoops);
	}
}

.select {
	background: none;
	position: relative;

	&.-primary {
		background: url(#{$bg-img-path}icon-select-arrow.svg) right center no-repeat;
		border: $input-border;
		border-radius: 4px;
		height: 2.5em;
		padding: .65em;

		&:focus {
			border-color: color(gray, mid);
		}
	}

	&.error {
		border-color: color(whoops);
	}
}

// Labels
.label {

	&.-primary {
		display: block;
		font: {
			size: 0.75em;
		}
		line-height: 1.4;
		margin-bottom: .5em;
	}

	&.-input {
		cursor: pointer;
		height: 2.5em;
		font: {
			size: 0.75em;
		}
	}

	&.-input-inline {
		display: inline-block;
		padding: 1em 0;

		&:not(:last-child) {
			margin-right: $gutter;
		}
	}

	&.-input-stacked {
		display: block;
		height: 3.5em;
		padding-top: 1em;
	}

	&.-required {
		&:after {
			content: '*';
		}
	}
}

// Error style from jQuery Validator
label.error {
	color: color(whoops);
	display: block;
	font: {
		size: 0.75em;
		style: italic;
		weight: $header-font-weight;
	}
	padding: 1em 0 0 0;
}

.form-input-group {
	padding-bottom: 1em;
	position: relative;

	&.-two-col {
		@include clearfix;

		label {
			display: block;
			float: left;
			margin-right: 0 !important;
			padding-right: 1em;
			width: 50%;
		}
	}

	label.error {
		font-size: 1em;
		position: absolute;
		bottom: -.5em;
		left: 0;
	}
}

.radio {
	$size: 20;
	display: inline-block;
	cursor: pointer;

	> input {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;

		&:checked ~ .radio-value {
			position: relative;

			&:before {
				@include position( absolute, 3px null null 3px );
				background-color: color(primary);
				border-radius: 50%;
				content: '';
				display: block;
				height: ($size - 8)+px;
				width: ($size - 8)+px;
				z-index: 2;
			}
		}
	}

	> .radio-value {
		background: {
			color:  color(white);
			position: center;
			size: 95% auto;
		}
		border: $input-border;
		border-radius: 50%;
		display: inline-block;
		height: $size+px;
		margin-right: 0.25em;
		vertical-align: -0.5em;
		width: $size+px;
	}
}

.checkbox {
	$size: 20;

	> input {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;

		&:checked ~ .checkbox-value {
			background: url(#{$bg-img-path}icon-input-check.svg) center center no-repeat;
			position: relative;
		}
	}

	> .checkbox-value {
		background: {
			color: color(white);
			position: center;
			size: 95% auto;
		}
		border: $input-border;
		border-radius: 2px;
		display: inline-block;
		height: $size+px;
		margin-right: 0.25em;
		vertical-align: -0.5em;
		width: $size+px;
	}
}

// Form Layout
.form-row {

	@include media($large-up) {
		@include clearfix;
		padding-bottom: 2em;
	}

	@include media($to-large) {
		padding-bottom: 1.5em;
	}

	&:last-of-type {
		padding-bottom: 0;
	}

	> .form-col {
		position: relative;

		@include media($large-up) {
			display: block;
			float: left;
			width: 48%;

			&:first-child {
				margin-right: 2%;
			}

			&:last-child:not(:first-child) {
				margin-left: 2%;
			}
		}

		@include media($to-large) {

			&:first-child {
				padding-bottom: 1.5em;
			}
		}

		&.-single-right {

			@include media($large-up) {
				display: block;
				float: right;
				width: 48%;

				&:first-child {
					margin-left: 2%;
					margin-right: 0;
				}

			}

			@include media($to-large) {

				&:first-child {
					padding-bottom: 1.5em;
				}
			}
		}
	}

	// Full Width
	> .full {
		position: relative;

		&.-right {
			text-align: right;

			@include media($to-large) {
				text-align: center;
			}
		}
	}
}

// Used to place submit button "inline" on top of input
.input-button-inline {
	position: relative;

	> .input {
		padding-right: 3em;
		position: relative;
	}

	> .button {
		position: absolute;
		top: 0;
		right: 0;
	}
}
