/**
 * Change the cursor in all browsers (opinionated).
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

/**
 * Restore the default cursor to disabled elements unset by the previous rule.
 */

[disabled] {
  cursor: default;
}
