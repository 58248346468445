.layout-bg {

	// Humble Gradient, smaller
	&.-gradient-humble {
		background: linear-gradient(180deg, color(white) 35%, color(sky, lighter) 85%);
	}

	&.-gradient-full {
		background: linear-gradient(180deg, color(white) 0%, color(sky, lighter) 65%);
	}

	&.-border-top {
		border-top: 1px solid color(gray, lighter);
	}

	&.-solid-light {
		background-color: color(gray, lightest);

		@include media($xlarge-up) {
			padding: 3em 3em 1em 3em;
		}

		@include media($large-only) {
			padding: 2em 2em .75em 2em;
		}

		@include media($to-large) {
			padding: 1em 1em .5em 1em;
		}
	}
}
