.layout-news-internal {
	@include clearfix;
	margin: 0 auto;
	max-width: 74.375em;

	@include media($xlarge-only) {
		max-width: 58.75em;
	}

	@include media($large-only) {
		max-width: 44.5em;
	}

	// If no header appears above
	&.-solo {
		margin-top: 3em;
	}

	.column {

		&.-main {

			@include media($xlarge-only) {
				float: left;
				width: 44.25em;
			}

			@include media($xxlarge-up) {
				float: left;
				width: 59.375em;
				//width: 43.75em;
			}
		}

		&.-sidebar {

			@include media($xlarge-up) {
				float: right;
				width: 12.5em;
			}

			@include media($large-only) {
				@include clearfix;
				padding: 2em 0;

				.news-search,
				.news-links {
					float: left;
					width: 50%;
				}

				.news-search {
					padding-right: 1em;
				}

				.news-links {
					padding-left: 1em;
				}
			}

			@include media($to-large) {
				padding: 2em $gutter;
			}
		}
	}

	.articles-grid {

		@include media($xlarge-up) {
			padding: 4em;
		}

		@include media($large-only) {
			padding: 4em;
		}

		@include media($to-large) {
			padding: 3em 1em;
		}

		> .article-preview {

			@include media($to-xlarge) {
				padding: 1.5em 0;
			}
		}

		> .article-preview.-simple,
		> .article-preview.-inline {
			position: relative;

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}

			@include media($large-up) {
				padding: 4em 0 4.25em 0;
			}

			@include media($to-large) {
				padding: 2em 0;
			}

			&:not(:last-of-type) {

				&::after {

					@include media($large-up) {
						background: url(#{$bg-img-path}divider-logomark.svg) center center no-repeat;
						content: '';
						display: block;
						position: absolute;
						bottom: 0;
						left: 50%;
						margin-left: -4.5em;
						height: 9px;
						width: 9em;
					}
				}
			}
		}

		/**
		 * MR - 1/26/2017
		 * Delete once changes to internal News layout (added image like index) is approved.
		 */
		//&.-stacked {
		//	@include media($xxlarge-up) {
		//		padding: 3em 6em;
		//	}
        //
		//	@include media($xlarge-only) {
		//		padding: 2em 4.5em;
		//	}
        //
		//	> .article-preview {
        //
		//		@include media($xlarge-up) {
		//			padding: 1.5em 0;
		//		}
		//	}
		//}
	}

	// Single Article
	.article-full {

		@include media($xlarge-up) {
			padding: 4.875em;
		}

		@include media($large-only) {
			padding: 3.5em;
		}

		@include media($to-large) {
			padding: 3.5em $gutter;
		}
	}

	// Sidebar Blocks

	.news-search {
		padding-bottom: 3em;
	}

	.news-links {
		@include clearfix;

		.link-list {

			@include media($xlarge-up) {

					&:not(:last-child) {
					margin-bottom: 2em;
				}
			}

			@include media($to-xlarge) {
				display: block;
				float: left;
				width: 50%;

				&:nth-child(odd) {
					padding-right: 1em;
				}

				&:nth-child(even) {
					padding-left: 1em;
				}
			}
		}
	}

	.news-social {
		display: block;
		clear: both;
		width: 100%;

		@include media($large-up) {
			padding-top: 1em;
		}

		@include media($large-only) {
			padding-left: calc(50% + 1em);
		}

		@include media($to-large) {
			margin-top: 1em;
		}
	}
}
