// Classes to add vertical margins between sections
.spacer {

	// If two spacers immediately follow one another, zero out the padding on the top of the second one to avoid double height
	+ .spacer {
		padding-top: 0 !important;
	}

	&.-first {
		padding-top: 0 !important;
	}

	// Need a way to zero out bottom padding if needed
	&.-last {
		padding-bottom: 0 !important;
	}

	// Large Spacer
	&.-spacer-l {

		@include media($xxlarge-up) {
			padding: 6.875em 0;
		}

		@include media($xlarge-only) {
			padding: 6.25em 0;
		}

		@include media($to-xlarge) {
			padding: 5em 0;
		}

		@include media($small-only) {
			padding: 3.75em 0;
		}
	}

	// Medium Spacer
	&.-spacer-m {

		@include media($xxlarge-up) {
			padding: 6.25em 0;
		}

		@include media($xlarge-only) {
			padding: 5em 0;
		}

		@include media($to-xlarge) {
			padding: 3.75em 0;
		}

		@include media($small-only) {
			padding: 2.5em 0;
		}
	}

	// Medium Small
	&.-spacer-s {

		@include media($xxlarge-up) {
			padding: 4em 0;
		}

		@include media($xlarge-only) {
			padding: 3.75em 0;
		}

		@include media($to-xlarge) {
			padding: 2.5em 0;
		}

		@include media($small-only) {
			padding: 1.25em 0;
		}
	}

}

// Class to add padding to bottom of grouped content -- these are good to use when you want to carry a background color/gradient down below the last section element of a group and cannot use a margin w/ the spacer classes above
.group-spacer {

	&.-group-spacer-l {

		@include media($xxlarge-up) {
			padding-bottom: 6.875em;
		}

		@include media($xlarge-only) {
			padding-bottom: 6.25em;
		}

		@include media($to-xlarge) {
			padding-bottom: 5em;
		}

		@include media($small-only) {
			padding-bottom: 3.75em;
		}
	}

	&.-group-spacer-m {

		@include media($xxlarge-up) {
			padding-bottom: 6.25em;
		}

		@include media($xlarge-only) {
			padding-bottom: 5em;
		}

		@include media($to-xlarge) {
			padding-bottom: 3.75em;
		}

		@include media($small-only) {
			padding-bottom: 2.5em;
		}
	}

	// Medium Small
	&.-group-spacer-s {

		@include media($xxlarge-up) {
			padding-bottom: 4em;
		}

		@include media($xlarge-only) {
			padding-bottom: 3.75em;
		}

		@include media($to-xlarge) {
			padding-bottom: 2.5em;
		}

		@include media($small-only) {
			padding-bottom: 1.25em;
		}
	}

	// Medium Small
	&.-group-spacer-xs {
		padding-bottom: 1em;
	}
}
