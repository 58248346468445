// Testimonial Carousels
.carousel-testimonial {
	@include clearfix;
	background: color(white);
	padding-bottom: 2em;
	position: relative;
	width: 100%;

	@include media($large-only) {
		max-width: 39.875em;
	}

	.slide {
		background-color: color(white);
		width: 100%;
	}

	.flickity-page-dots {
		bottom: 1.5em;

		.dot {
			border-color: color(gray, dark);

			&.is-selected {
				background-color: color(gray, dark);
			}
		}
	}
}
