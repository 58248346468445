.-module-10c {
	display: block;

	@include media($xlarge-up) {
		margin: {
			top: 1.25em;
			bottom: 2.5em;
		}
	}

	@include media($large-only) {
		margin: .75em 0 1.5em 0;
	}

	@include media($to-large) {
		border-bottom: 1px solid color(gray, lighter);
	}

}
