.layout-grid-icon-cards {
	align-items: center;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;

	@include media($medium-only) {
		margin: 0 auto;
		max-width: 30em;
	}

	> .card-icon {
		margin: .5em;
	}
}
