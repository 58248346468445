/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}
