.layout-grid-cards {
	$margin: 0.875em;
	display: flex;
	flex-flow: row wrap;
	margin: $margin 0;
	width: 100%;

	> .card {
		margin: $margin;

		// 3-up, margin resets
		@include media($xlarge-up) {

			&:nth-child(3n+1) {
				margin-left: 0;
			}

			&:nth-child(3n-3) {
				margin-right: 0;
			}
		}

		@include media($xxlarge-up) {
			width: 23.6em;
		}

		@include media($xlarge-only) {
			width: 19.65em;
		}

		@include media($large-only) {
			width: 20.6875em;

			&:nth-child(odd) {
				margin-left: 0;
			}

			&:nth-child(even) {
				margin-right: 0;
			}
		}

		@include media($medium-only) {
			width: calc(50% - #{$margin});

			&:nth-child(odd) {
				margin-left: 0;
			}

			&:nth-child(even) {
				margin-right: 0;
			}
		}

		@include media($small-only) {
			margin: $margin 0;
			width: 100%;
		}
	}
}

// redesign, 4-up instead of 3-up on bigger screens
.redesign-layout-grid {
    padding: rem(32) 0 rem(42);

    @include media($large-up) {
        padding: rem(50) 0 rem(100);
    }
}

.redesign-layout-grid-heading {
    text-align: center;

    .title {
        font-size: 1.9rem;
        margin-bottom: rem(8);
        letter-spacing: -.01em;

        @include media($large-up) {
            font-size: rem(32);
            margin-bottom: 0;
            letter-spacing: 0;
        }
    }
}

.redesign-layout-grid-cards {
    $margin: 0.875em;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 86em;
    padding: 0 rem(16);

    > .redesign-card {
        @include media($xxlarge-up) {
            width: rem(274);
        }

        @include media($xlarge-up) {
             width: 25%;
        }

        @include media($large-only) {
            width: 33.3333%;
        }

        @include media($midscreen-only) {
            width: 33.3333% !important;
        }

        @include media($medium-only) {
            width: 50%;
        }

        @include media($small-only) {
            margin: $margin 0;
            width: 100%;
        }
    }

    @include media($large-up) {
        padding: 0 rem(44);
    }
}
