.layout-grid-contact-cards-nav {
	margin-top: 4.125em;

	@include media($xxlarge-up) {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
	}

	.header {

		@include media($xxlarge-up) {
			align-items: center;
			display: flex;
			justify-content: center;
			padding-right: $gutter;
			width: 14.875em;
		}

		@include media($to-xxlarge) {
			text-align: center;
			padding-bottom: 2em;
			width: 100%;
		}

		.title {
			@include p-l(1.2);
		}
	}

	.cards {

		@include media($xxlarge-up) {
			width: 100%;
		}

		@include media($xlarge-up) {
			display: flex;
		}

		@include media($to-xxlarge) {
			width: 100%;
		}

		> .card-icon-link-small {

			@include media($xxlarge-up) {
				width: 14em;
			}

			@include media($xlarge-only) {
				width: 14.5em;
			}

			@include media($xlarge-up) {
				margin: .5em;
			}

			@include media($to-xlarge) {
				margin: .5em 0;
			}
		}
	}

	.header + .cards {
		@include media($xxlarge-up) {
			width: calc(100% - 14.875em);
		}

		@include media($xlarge-up) {
			flex-flow: row wrap;
		}

		> .card-icon-link-small {
			@include media($xlarge-up) {
				&:nth-child(4n-3) {
					margin-left: 0;
				}

				&:nth-child(4n) {
					margin-right: 0;
				}
			}
		}
	}
}
