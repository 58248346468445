.navigation-contact-products {

	.items {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		width: 100%;

		@include media($xlarge-up) {
			padding-left: 2em;
		}

		@include media($small-only) {
			flex-flow: row wrap;
		}


		> .item {
			width: 22%;

			@include media($small-only) {
				width: 36%;
			}

			&.-is-active {

				> .link {
					opacity: 1;
				}
			}

			> .link {
				display: block;
				opacity: 0.5;
				width: 100%;

				&:hover {
					opacity: 0.9;
				}

				> img {
					max-width: 100%;
					min-width: 100%;
				}
			}
		}
	}
}
