.header-contact-instructions {

	.title {
		@include p-l;
		margin-bottom: 1em;
	}

	.note {
		@include p-xsm;
		color: color(gray);
	}
}

.header-contact-instructions-logo {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	width: 100%;

	@include media($xlarge-up) {
		padding-bottom: 6em;
	}

	@include media($to-xlarge) {
		padding-bottom: 2em;
	}

	.title {
		@include p-l;

		@include media($xlarge-up) {
			padding-right: 1em;
			width: 14em;
		}

		@include media($to-xlarge) {
			display: none;
		}
	}

	.navigation-contact-products {

		@include media($xlarge-up) {
			width: calc(100% - 15em);
		}

		@include media($to-xlarge) {
			width: 100%;
		}
	}
}
