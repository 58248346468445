// Reset <button> element
button {
	background: none;
	border: none;
}

.button {
	display: inline-block;
	border: 0;
	height: 2.5em;
	line-height: 2.5em;
	padding: 0 2em;
	text-align: center;
	text-decoration: none;
	transition: all 300ms ease-in-out;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}

	@include media($medium-up) {
		min-width: 16em;
	}

	&:active {
		transform: translateY(1px);
	}

	// Primary Button
	&.-primary {
		background-color: color(primary);
		border-radius: 3px;
		color: color(white);

		&:hover {
			background-color: lighten(color(primary), 5);
		}

		&:active {
			background-color: darken(color(primary), 5);
		}
	}

	// Secondary Button
	&.-secondary {
		background-color: transparent;
		border-radius: 3px;
		color: color(gray, base);
		border: 1px solid color(primary);
		padding: 1.25em;
		font-size: 0.65625em;
		line-height: 0.875em;
		text-transform: uppercase;
		min-width: unset;
		height: unset;
		font-weight: 600;

		&:hover {
			background-color: color(primary);
			color: color(white);
		}

		&:active {
			background-color: color(primary);
			color: color(white);
		}
	}

	&.-stark {
		background-color: color(white);
		border: 1px solid color(gray, light);
		border-radius: 3px;
		color: color(gray);
		min-width: 9.25em;

		&:hover {
			opacity: 0.7;
		}
	}

	&.-icon {

		.icon {
			display: inline-block;
			vertical-align: middle;

			svg {
				fill: currentColor;
				height: 0.85em;
				width: 1em;
			}
		}
	}

	// Rounded Button
	&.-rounded {
		border: 1px solid color(gray, light);
		border-radius: 100px;

		&:hover {
			opacity: 0.7;
		}
	}

	// Used to place buttons "inside" of input elements
	&.-inline-input {
		align-items: center;
		display: flex;
		height: $input-primary-height;
		justify-content: flex-end;
		min-width: inherit;
		padding: 0 1em 0 0;
		width: 3em;

		&:hover {

			.icon {
				fill: color(gray);
			}
		}

		.icon {
			fill: color(gray, mid);
			height: 1em;
			width: 1em;
		}
	}

    // large, used redesign

    &.-large {
        font-size: rem(18);
        min-height: rem(80);
        line-height: rem(80);
        width: 100%;
        letter-spacing: -.025em;

        @include media($large-up) {
            font-size: rem(14);
            min-height: rem(40);
            line-height: rem(40);
            max-width: rem(256);
        }
    }
}

// Arrow-only button, used in footer email form
.button-arrow {
	$size: 2.75em;
	display: block;
	height: $size;
	transition: transform 200ms ease-in-out;
	width: $size;

	&:hover {
		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.9);
	}

	> .icon {
		fill: color(primary);
		height: .75em;
		width: .5em;
	}
}
