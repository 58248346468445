.layout-contact-content-v3 {
	position: relative;

	.header-contact-instructions {

		@include media($to-xlarge) {
			text-align: center;
		}
	}

	.blocks {
		@include clearfix;
		padding: 3em 0;
	}

	.block {

		@include media($xlarge-up) {
			display: block;
			float: left;
			margin: 0 1em;
			vertical-align: top;
			width: calc(33.3% - 2em);

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		@include media($to-xlarge) {
			&:not(:last-child) {
				padding-bottom: 2em;
			}
		}

		.title {
			@include p-sm;
			font-weight: $header-font-weight;
			margin-bottom: 1em;
		}

		p {
			@include p-sm(1.5);
		}

		a {
			text-decoration: underline;
		}
	}

	.wrap-up {
		@include clearfix;
		padding-top: 2em;
		width: 100%;

		.title {
			@include p-md;

			@include media($xlarge-up) {
				float: left;
				width: 50%;
			}

			@include media($to-xlarge) {
				padding-bottom: 1em;
			}
		}

		.content {

			@include media($xlarge-up) {
				float: right;
				padding-left: 1em;
				width: 50%;
			}

			p {
				@include p-sm;
			}

			a {
				text-decoration: underline;
			}
		}
	}
}
