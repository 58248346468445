.table-highlight-column {
	$highlight-bg: color(gray, lighter);
	width: 100%;

	// Set Highlight BG
	.th-col2,
	.tb-col2 {

		@include media($large-up) {
			background-color: $highlight-bg;
		}
	}

	// Default TH styles
	th {

		@include media($xlarge-up) {
			@include p-md;
		}
	}

	// Default TD styles
	td {

		@include media($to-xlarge) {
			@include p-sm;
		}

		// Set Widths
		@include media($xlarge-up) {

			&:nth-child(1n) {
				width: 40%;
			}

			// 2nd column
			&:nth-child(2n) {
				width: 20%;
			}
		}
	}

	// Extra padding for first and last rows
	thead tr:last-child th {

		@include media($large-up) {
			padding-top: 1.5em;
		}
	}

	tbody tr:last-child td {

		@include media($large-up) {
			padding-bottom: 2em;
		}
	}

	thead tr {

		// Hide 'header' row for large and up screens
		&:nth-child(odd) {

			@include media($large-up) {
				display: none;
			}
		}

		&:nth-child(even) {

			@include media($to-large) {
				display: none;
			}
		}

		th {

			@include media($large-up) {
				padding: .65em 2em 1.25em 2em;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				&:nth-child(2n),
				&:nth-child(3n) {
					text-align: right;
				}

				&:nth-child(2n) {
					padding-right: 1em;
				}
			}
		}
	}

	// TBody
	tbody tr {

		// Hide 'header' row for large and up screens
		&:nth-child(odd) {

			@include media($large-up) {
				display: none;
			}

			@include media($to-large) {
				column-span: all;

				td {
					font: {
						weight: $header-font-weight-heavy;
					}
					padding: 2em 0 .65em 0;
				}
			}
		}

		&:nth-child(even) {

			td {
				@include media($to-large) {

					&:first-child {
						display: none;
					}
				}
			}
		}

		td {
			vertical-align: bottom;

			@include media($large-up) {
				padding: .65em 2em;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				.title {
					display: none;
				}

				&:nth-child(2n),
				&:nth-child(3n) {
					text-align: right;
				}
			}

			@include media($to-large) {

				.title {
					display: block;
					padding-bottom: .25em;
				}

				&:nth-child(2n) {
					padding-right: 1em;
				}

				&:nth-child(3n) {
					padding-left: 1em;
				}
			}
		}
	}
}
