.layout-contact-content-v2 {

	.header-contact-instructions {

		@include media($to-xlarge) {
			text-align: center;
		}
	}

	.action {

		@include media($to-xlarge) {
			text-align: center;
		}
	}
}
