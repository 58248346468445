.-module-09b {

	@include media($xlarge-up) {
		//padding: 1em;
	}

	> .module-content {
		background-repeat: no-repeat;
		background-color: color(gray, mid);
		color: color(white);
		height: 100%;
		position: relative;

		@include media($xlarge-up) {
			background-position: center top;
			background-size: cover;
			height: 35.375em;
			margin: 0 auto;
		}

		> .inner {
			align-items: center;
			display: flex;
			height: 100%;
			justify-content: space-between;
			margin: 0 auto;
			max-width: 86em;

			@include media($xxlarge-up) {
				justify-content: flex-end;
			}
		}

		.module-visual {

			@include media($xxlarge-up) {
				background-size: cover;
				width: 47%;
				position: absolute;
				top: 0;
				left: 0;
			}

			@include media($xlarge-only) {
				width: 24.375em;
			}

			@include media($xlarge-up) {
				background-position: right center;
				background-size: cover;
				height: 100%;
			}

			@include media($to-xlarge) {
				display: none;
			}
		}

		.module-item {

			@include media($xxlarge-up) {
				width: calc(100% - 40.625em);
			}

			@include media($xlarge-only) {
				width: calc(100% - 24.375em);
			}

			@include media($large-only) {
				padding: 6em $gutter*3;
			}

			@include media($medium-only) {
				padding: 5em $gutter*2;
			}

			@include media($small-only) {
				padding: 4em $gutter;
			}

			.content {
				margin: 0 auto;
				padding-bottom: $gutter;

				@include media($xxlarge-up) {
					max-width: 42em;
				}

				@include media($xlarge-up) {
					padding: 0 $gutter*2;
				}

				@include media($xlarge-only) {
					max-width: 42em;
				}
			}
		}
	}

	.subtitle {
		@include h-mod-subitle-strong;
		color: color(gray, lighter);
	}

	.title {
		@include h-mod-block-title-xl(300, 1.2, 0.5em);
		display: block;

		@include media($small-only) {
			font-size: 2.5em;
		}
	}

	p {
		@include p-md(1.5);
	}
}
