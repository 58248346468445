.copy-block {

	// Link
	@include copy-link;

	// Paragraphs
	p {
		@include p(1.5, 1.5em);
	}

	a {
		color: color(secondary);
		font-weight: $header-font-weight;
		text-decoration: underline;
	}

	h3 {
		@include h-content-h3;
		margin-bottom: 1.25em;
	}

	ul {
		@include ul;
	}

	ol {
		@include ol;
	}
}
