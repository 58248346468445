.-module-04d {

	.module-content {
		background: linear-gradient(to bottom, color(gray, lighter) 0%, color(gray, midLight) 70%); // This will need to be a custom item in the CMS
		margin: 1em auto;
	}

	.module-intro {
		width: 100%;
	}

	.icon {
		@include icon();
	}

	@include media($xlarge-up) {
		margin: 1em;

		.module-content {
			max-width: 86em;
			overflow: hidden;
		}

		// Wrapping Container
		.module-presentation {

		}

		.module-items-outer {
			background-color: color(gray, lightest);
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		// Module Items Lists
		.module-items {

			// Hide captions here
			p {
				display: none;
			}
		}

		.module-item {
			margin: 1em 0;
		}

		// Need to style these custom for this module
		.link {
			@include clearfix;

			> .icon,
			> .content {
				display: inline-block;
				vertical-align: middle;
			}

			> .content {
				margin-left: 1.5em;
				max-width: calc(100% - 7em);

				> .title {
					margin-bottom: 0;
				}
			}
		}

		.module-visual {
			display: flex;
			flex-direction: column;

			> .graphic {
				display: flex;
				flex-grow: 10;
				flex-basis: 80%;
				position: relative;
				width: 100%;

				> picture.position {
					height: 100%;
					width: 100%;

					> img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateY(-50%) translateX(-50%);
					}
				}
			}

			> .caption {
				align-items: center;
				align-self: flex-end;
				background: rgba( color(white), 0.5 );
				display: flex;
				height: 10em;
				padding: 2em 3em;
				text-align: center;
				transition: height 0.2s ease-in-out;
				width: 100%;
			}
		}

	}

	@include media($xxlarge-up) {

		.module-visual,
		.module-items-outer {

		}

		.module-items-outer {
			padding: 5em 4em;
			width: 50%;
		}

		.module-presentation {
			display: flex;

		}

		.module-visual {
			width: 50%;
		}

		.module-items {

		}
	}

	// XLarge-only
	@include media($xlarge-only) {

		.module-content {

		}

		.module-visual,
		.module-items-outer {

		}

		.module-items-outer {
			padding: 4em 3em;
			width: 50%;
		}

		.module-presentation {
			display: flex;
		}

		.module-visual {
			width: 50%;

			> .graphic {
				min-height: 28em;
			}
		}

		.module-items {

		}

	}

	// Small - Large
	@include media($to-xlarge) {
		$visual-margin: 14em;

		.module-presentation {
			display: flex;
			flex-direction: column;
		}

		.module-visual {
			margin: 14em 0 7em 0; // Offset for module title
			order: 1;

			> .graphic {
				height: 100%;
				position: relative;
				width: 100%;

				> picture.position {
					height: 100%;
					width: 100%;

					> img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateY(-50%) translateX(-50%);
					}
				}
			}
		}

		.module-items-outer {
			order: 2;
			background: rgba( color(white), 0.5 );
			padding: 2em 3em;
			width: 100%;
		}

		.module-intro {
			position: absolute;
			text-align: center;
			top: 0;
			left: 0;
			padding: 4em $gutter 0 $gutter;
			width: 100%;
		}


		.caption {
			display: none;
		}
	}

	// Large Only
	@include media($large-only) {

		.module-visual {
			height: 30em;
		}

		.module-items-outer {
			padding: 5em 0;
		}

		.module-items {
			margin: 0 auto;
			max-width: 42em;
			padding: 0 5em;
		}

		// Need to style these custom for this module
		.link {
			@include clearfix;

			> .icon,
			> .content {
				display: inline-block;
				vertical-align: middle;
			}

			> .content {
				margin-left: 1.5em;
				max-width: calc(100% - 7em);
			}
		}
	}

	@include media($to-large) {

		.module-items {
			text-align: center;
		}

		// Need to style these custom for this module
		.link {
			@include clearfix;

			> .icon {
				display: inline-block;
				margin-bottom: 1.5em;
				vertical-align: middle;
			}
		}
	}

	// Medium Only
	@include media($medium-only) {

		.module-visual {
			height: 18em;
		}

		.module-items-outer {
			padding: 4em 0;
		}

		.module-items {
			margin: 0 auto;
			max-width: 28em;
			padding: 0 3em;
		}
	}

	// Small Only
	@include media($small-only) {

		.module-items-outer {
			padding: 2em 0;
		}

		.module-items {
			padding: 0 3em;
		}

		.module-visual {
			height: 16em;
		}
	}
}
