$item-border: 1px solid color(gray, light);

.-module-05b {
	margin: 1.875em auto;
	max-width: 86em;

	.module-upper {

		@include media($xlarge-up) {
			display: flex;
			height: 28.125em;
		}

		> .block {

			@include media($xlarge-up) {
				align-items: center;
				display: flex;
				height: 100%;
				justify-content: center;
			}

			&.-visual {
				//background-color: color(gray, light);

				@include media($xxlarge-up) {
					width: 41.5em;
				}

				@include media($xlarge-up) {
					order: 1;
					//margin-right: 1.875em;
				}

				@include media($xlarge-only) {
					width: 33em;
				}

				@include media($to-xlarge) {
					align-items: center;
					display: flex;
					justify-content: center;
				}

				@include media($large-only) {
					max-height: 43.75em;
				}

				@include media($medium-only) {
					max-height: 27.25em;
				}

				@include media($small-only) {
					max-height: 18.25em;
				}

				> .position img {
					display: block;
				}
			}

			&.-content {

				@include media($xxlarge-up) {
					width: calc(100% - 41.5em);
				}

				@include media($xlarge-up) {
					background-color: color(gray, lightest);
					order: 2;
				}

				@include media($xlarge-only) {
					width: calc(100% - 33em);
				}
			}
		}
	}

	.module-intro {

		@include media($xxlarge-up) {
			margin: 0 auto;
			max-width: 39em;
			padding: 0 $gutter;
		}

		@include media($to-xlarge) {
			text-align: center;
		}

		@include media($xlarge-only) {
			padding: 0 $gutter*2;
		}

		@include media($large-only) {
			margin: 0 auto;
			max-width: 40em;
			padding: 6em $gutter;
		}

		@include media($to-large) {
			padding: 4em $gutter;
		}
	}
}
