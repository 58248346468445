.layout-grid-contact-product {
	display: flex;
	flex-flow: row wrap;
	padding: 1em 0 3em 0;

	@include media($large-only) {
		margin: 0 auto;
		width: 32em;
	}

	.link {
		align-items: center;
		display: flex;
		justify-content: center;

		&:hover {
			opacity: 0.7;
		}

		@include media($xxlarge-up) {
			height: 9.375em;
			margin: 1em 2em;
			width: 13.5em;

			&:nth-child(4n-3) {
				margin-left: 0;
			}

			&:nth-child(4n-0) {
				margin-right: 0;
			}
		}

		@include media($xlarge-only) {
			height: 9.375em;
			margin: 1em 2em;
			width: 16em;

			&:nth-child(3n-2) {
				margin-left: 0;
			}

			&:nth-child(3n-0) {
				margin-right: 0;
			}
		}

		@include media($large-only) {
			height: 9.375em;
			margin: 1em 2em;
			width: 14em;

			&:nth-child(odd) {
				margin-left: 0;
			}

			&:nth-child(even) {
				margin-right: 0;
			}
		}

		@include media($to-large) {
			height: 6em;
			margin: 2em 0;
			width: 100%;
		}

		> img {
			max-height: 100%;
			max-width: 100%;
		}
	}
}
