.hp-hero {
    color: color(white);
    text-align: center;
    padding: rem(54) 0;
    background: linear-gradient(to top right, color(purple), color(blue), color(purple));

    @include media($large-up) {
        padding: rem(68) 0;
    }
}

.hp-hero__inner {
    .title {
        font-size: 2.3rem;
        margin-bottom: rem(28);
        line-height: 1.175;
        letter-spacing: -.025em;

        @include media($large-up) {
            padding: 0 rem(60);
        }
    }

    .subtitle {
        font-size: 1.15rem;
        line-height: 1.4;
        letter-spacing: -.025em;
        padding: 0 rem(20);

        @include media($large-up) {
            font-size: rem(20);
            padding: 0 rem(100);
            letter-spacing: -.02em;
        }
    }

    @include media($large-up) {
        padding: 0 rem(100);
    }
}


