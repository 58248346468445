.layout-outer {

	@include media($xlarge-up) {
		padding-top: $main-nav-height-widescreen; // Set padding to at least height of main nav to account for fixed header. `nav-sticky.js` will dynamically set height if secondary navigation exists
	}

	@include media($to-xlarge) {
		max-height: 100vh; // Set this here before we hide overflow-y when menu is open.
		max-width: 100%;
		overflow-x: hidden;
		padding-top: $header-height-mobile;
		position: relative;
		-webkit-overflow-scrolling: touch;
	}

	&.-pad-lg {

		@include media($xlarge-up) {
			padding-top: 9.5em;
		}
	}

	&.-pad-md {

		@include media($xlarge-up) {
			padding-top: 9.0625em;
		}
	}
}

.layout-site {

	@include media($xlarge-up) {
		will-change: transform;
	}

	@include media($to-xlarge) {
		position: relative;
		transition: transform 400ms $ease-out-sine;
		will-change: transform;

		&:before {
			background-color: rgba( 0,0,0,0.5 );
			content: '';
			display: block;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			transition: opacity 300ms $ease-out-sine, visibility 300ms $ease-out-sine;
			visibility: hidden;
			width: 100%;
		}
	}
}

.align {

	&.-align-center {
		text-align: center;
	}
}

.stories-items{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 40px 0 20px 0;
	.story{
		width: 48%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		background-color: #f8f8f8;
		margin-bottom: 30px;

		@include media($to-xlarge) {
			width: 100%;
			flex-direction: column;
		}

		.logo-block{
			background-position: center center;
  			background-size: cover;
  			background-repeat: no-repeat;
  			display: flex;
  			flex-direction: row;
  			align-items: center;
  			justify-content: center;
  			svg{
  				width: 60%;
  				fill: color(white);
		        stroke: color(white);
		        opacity: .5;
		        & > *{
		            fill: color(white);
		            stroke: color(white);
		            & > *{
		                fill: color(white);
		                stroke: color(white);
		                *{
		                    stroke: color(white);
		                }
		            }
		        }
  			}
		}

		.text-block{
			padding: 30px;
			p{
				margin: 14px 0;
			}
		}

		& > div{
			width: 50%;
			@include media($to-xlarge) {
				width: 100%;
			}
		}
	}
}

.stories{
	& > a.button.-primary{
		margin: 0 auto 50px auto;
	    display: block;
	    max-width: 227px;
	}
	.loader{
		display: none;
	}
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #f8f8f8;
  background: -moz-linear-gradient(left, #f8f8f8 10%, rgba(218,251,1, 0) 42%);
  background: -webkit-linear-gradient(left, #f8f8f8 10%, rgba(218,251,1, 0) 42%);
  background: -o-linear-gradient(left, #f8f8f8 10%, rgba(218,251,1, 0) 42%);
  background: -ms-linear-gradient(left, #f8f8f8 10%, rgba(218,251,1, 0) 42%);
  background: linear-gradient(to right, #f8f8f8 10%, rgba(218,251,1, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #f8f8f8;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Success Stories detail page
.banner-full{
	min-height: 305px;
	color: color(white);
	display: flex;
    flex-direction: row;
    align-items: flex-end;

	.container.-outer{
		display: flex;
	    flex-direction: row;
	    flex-wrap: nowrap;
	    justify-content: space-between;
	    align-items: flex-end;
	    padding-bottom: 30px;
	    @include media($to-large) {
			flex-direction: column;
			padding-top: 30px;
		}

	    .text{
	    	width: 60%;
	    	@include media($to-large) {
				width: 100%;
			}
	    }

	    svg{
	    	width: 15%;
	    	@include media($to-large) {
				width: 30%;
			}

			fill: color(white);
	        stroke: color(white);
	        opacity: .5;
	        & > *{
	            fill: color(white);
	            stroke: color(white);
	            & > *{
	                fill: color(white);
	                stroke: color(white);
	                *{
	                    stroke: color(white);
	                }
	            }
	        }
	    }
	}
}

.story-content{
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    @include media($to-large) {
		flex-direction: column;
	}

	.content-block{
		width: 65%;
		padding-right: 30px;
		@include media($to-large) {
			width: 100%;
			padding-right: 0;
		}

		h3{
			margin-top: 30px;
			margin-bottom: 20px;
			&:first-child{
				margin-top: 0;
			}
		}
		p{
			margin-bottom: 15px;
		}
		& > img{
			margin: 30px 0;
		}
	}

	.sidebar-block{
		width: 35%;
		text-align: center;

		@include media($to-large) {
			width: 100%;
		}

		.sidebar-container{
			padding: 42px 27px;
			display: flex;
		    flex-direction: row;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    align-items: center;

		    & > h3{
		    	margin-bottom: 20px;
		    	@include media($to-xlarge) {
					text-align: center;
				}

		    }

		    a{
		    	color: color(primary);
		    	font-size: 1rem;
		    	text-decoration: underline;
		    	margin-bottom: 20px;
		    	@include media($to-xlarge) {
		    		display: block;
					width: 100%;
				}
		    }

		    & > img{
		    	width: 20%;
		    }
		    & > p{
		    	width: 80%;
		    	margin: 30px 0;
		    	padding-left: 28px;
		    	text-align: left;
		    }

		    .logo-box{
		    	text-align: center;
		    	border-top: 1px solid color(gray, light);
		    	margin-top: 40px;
		    	img{
		    		width: 40%;
		    		margin-top: 20px;
		    	}
		    }
		}
		.download{
			margin-top: 57px;
		}
	}
}

.col-4-intro{
	padding: 56px 0;
	.container.-outer{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		text-align: center;

		& > h2,
		& > p{
			width: 100%;
			margin-bottom: 20px;
			color: color(gray, darker);
		}
		
		.col{
			margin-top: 20px;
			width: 25%;
			text-align: left;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-end;
			padding-right: rem(15);

			&:last-child{
				padding-right: 0;
			}

			@include media($large-only) {
				width: 50%;
			}

			@include media($to-large) {
				width: 100%;
				align-items: flex-start;
				flex-direction: column;
			}

			& > h1,
			& > img{
				font-size: 4rem;
				font-weight: 600;
				line-height: 3.375rem;
			}
			p{
				margin-top: rem(20);
				color: color(gray, darker);
			}
		}
	}


}

.stories-items{
	& > h2{
		width: 100%;
		text-align: center;
		margin-bottom: 40px;
		margin-top: 50px;
	}
}

.-module-05b{
	&.fullwidth{
		margin: 0;
		max-width: 100%;
	}
	.module-intro{
		& > .logo{
			max-width: 100px;
			margin-bottom: 20px;
		}
		& > .small-caps,
		& > p{
			margin-bottom: 20px;
		}
		.title{
			margin-bottom: 1rem
		}
	}
}
