.card-icon {
	align-items: center;
	border: 1px solid color(sky, dark);
	border-radius: 8px;
	display: flex;
	justify-content: center;
	position: relative;

	@include media($large-up) {
		flex-flow: row wrap;
		height: 12.5em;
		width: 13.5em;
		text-align: center;
	}

	@include media($to-large) {
		flex-flow: row wrap;
		height: 6.25em;
		width: 100%;
	}

	.icon {
		margin: 0 auto;

		@include media($large-up) {
			height: 2.5em;
			width: 2.5em;
			margin-bottom: .75em;
		}

		@include media($to-large) {
			height: 2em;
			width: 2em;
			position: absolute;
			left: 1em;
		}

		svg > *{
			fill: color(sky, dark);
		}
	}

	> .title {
		@include h-content-h3;
	}

	.content {
		margin: .65em 0 .65em 0;

		@include media($to-large) {
			display: none;
		}

		p {
			@include p-xsm(1.5);
		}
	}

	.link {
		color: color(sky, dark);
		display: block;
		font-weight: $header-font-weight;
		margin-top: .35em;
		text-decoration: underline;
	}
}
