.find-solution{
	padding: rem(85) 0;

	.container{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		@include media($large-up) {
	        flex-direction: row;
	    }

		h2{
			width: 100%;
		}

		.part{
			width: 100%;
			margin-top: rem(30);

			@include media($large-up) {
		        width: 50%;
		    }

		    h3,
		    h4{
		    	margin-bottom: rem(20);
		    }
		    .button{
		    	margin-bottom: 20px;
		    }
		    .-primary{
		    	margin-right: rem(30);
		    }

		    .markets-list{
		    	display: flex;
				flex-direction: column;
				flex-wrap: nowrap;

				@include media($large-up) {
			        flex-direction: row;
			    }

			    ul{
			    	width: 50%;
			    }
		    }
		}
	}
}