// Article blockquotes
.copy-block > blockquote {
	@include blockquote;
}

// Testimonial blockquotes
.quote-testimonial {

	p {
		@include p-l();

		@include media($to-xlarge) {
			@include p-md(1.5);
		}
	}

	&.-secondary {

		p {
			@include p-md(1.5);
		}
	}

	&.-quotes {

		p:first-child {

			&:before {
				content: '\201C';
			}
		}

		p:last-child:after {
			content: '\201D';
		}
	}
}
