.card-icon-link {
	border: 1px solid color(gray, light);
	border-radius: 8px;
	display: block;
	width: 100%;

	@include media($xlarge-up) {
		height: 17.15em;
		//max-width: 20em;
	}

	@include media($large-only) {
		height: 7.5em;
	}

	@include media($to-large) {
		height: 6.25em;
	}

	&.-small {

		@include media($xlarge-up) {
			height: 10.3125em;
			max-width: 12em;
		}

		@include media($large-only) {
			height: 8.875em;
			max-width: 10.375em;
		}
	}

	&:hover {
		border-color: color(gray, mid);

		.icon svg {
			transform: scale(1.1);
		}
	}

	> .content {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		padding: 1em 2em;
		width: 100%;

		@include media($xlarge-up) {
			flex-flow: column nowrap;
			text-align: center;
		}

		@include media($to-xlarge) {
			flex-flow: row nowrap;
		}

		.upper,
		.lower {
			display: flex;
		}

		.upper {

			@include media($xlarge-up) {
				align-items: center;
				height: 55%;
			}

			@include media($large-only) {
				width: 5em;
			}

			@include media($to-large) {
				width: 4em;
			}

		}

		.lower {
			flex-flow: row nowrap;
			max-width: 100%;

			@include media($xlarge-up) {
				align-items: flex-start;
				height: 45%;
			}

			@include media($large-only) {
				width: calc(100% - 5em);
			}

			@include media($to-large) {
				width: calc(100% - 4em);
			}
		}

		.icon {

			@include media($large-up) {
				height: 3.75em;
				width: 3.75em;
			}

			svg {
				& > *{
					fill: color(sky, dark) !important;
				}
				transition: transform 300ms $ease-in-out-quad;

				@include media($large-up) {
					height: 3.75em;
					width: 3.75em;
				}
			}
		}
		p {
			@include p-md;

			@include media($to-large) {
				@include p-sm;
			}
		}
	}
}
