// Full-width visual header
.header-visual {
	text-align: center;

	> .visual {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		@include media($xxlarge-up) {
			height: 31.25em;
		}

		@include media($xlarge-only) {
			height: 22.5em;
		}

		@include media($large-only) {
			height: 22.5em;
		}

		@include media($to-large) {
			height: 15.375em;
		}
	}

	@include media($xxlarge-up) {

	}
}
