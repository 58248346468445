.-module-01b {
	$bg-img: 'bg-module-01b';

	@include bg-default;
	margin: 0 auto;
	max-width: 86em;

	// --------------------------------------
	// Moudle 01a Layouts & Styling
	// --------------------------------------
	//

	.icon {
		@include icon();
	}

	// XXLarge-up
	@include media($xxlarge-up) {

		.module-content {
			margin: 0 auto;
			max-width: 80em;
		}

		// Wrapping Container
		.module-presentation {
			@include clearfix;
			min-height: 40em;
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			@include position( absolute, null null 0px 50% );
			background: url(#{$bg-img-path}#{$bg-img}-xxl.png) center bottom no-repeat;
			height: 41.875em;
			margin-left: (-24/2)+em;
			width: 24em;
			overflow: hidden;

			// 2x
			@include image-2x(url(#{$bg-img-path}#{$bg-img}-xxl@2x.png) center bottom no-repeat, 384px, 670px)

			// Position the graphic(s)
			> .graphic {
				top: 92px;
				left: 123px;
				width: 122px;
				height: 130px;
			}
		}

		.module-items-outer {
			columns: 2;
			column-gap: 28em;
			vertical-align: top;
		}

		// Module Items Lists
		.module-items {
			 max-width: 26em;
			 padding: 0 $gutter/2;
		}

		// Module Item
		.module-item {
			break-inside: avoid;
			margin-bottom: 0;
			min-height: 10em;
		}
	}

	// XLarge-only
	@include media($xlarge-only) {

		.module-content {
			max-width: 68em;
		}

		// Wrapping Container
		.module-presentation {
			min-height: 60em;
			padding: 0 0 1em 32em;
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			@include position( absolute, null null 0 0 );
			background: url(#{$bg-img-path}#{$bg-img}-xl.png) left bottom no-repeat;
			height: 51.375em;
			width: 29.125em;

			// 2x
			@include image-2x(url(#{$bg-img-path}#{$bg-img}-xl@2x.png) left bottom no-repeat, 466px, 822px)

			// Position the graphic(s)
			> .graphic {
				top: 115px;
				left: 179px;
				width: 131px;
				height: 139px;
			}
		}

		// Module Items Lists
		.module-items {
			//max-width: 36em;
			padding-right: $gutter;
		}

		// Module Item
		.moudle-item {
			margin-bottom: 1em; // Tighter margin
		}
	}

	// Small - Large
	@include media($to-xlarge) {

		.module-content {
			display: flex;
	  		flex-direction: column;
		}

		.module-intro {
			//order: 2;
		}

		.module-presentation {
		//	order: 1;
		}

		.module-items-outer {
			background-color: color(gray, lighter);
			padding: 2em 0;
		}
	}

	// Large Only
	@include media($large-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}#{$bg-img}-l.png) center bottom no-repeat;
			height: 44.5em;

			// 2x
			@include image-2x(url(#{$bg-img-path}#{$bg-img}-l@2x.png) center bottom no-repeat, 442px, 712px)

			// Position the graphic(s)
			> .graphic {
				top: 132px;
				left: 50%;
				margin-left: -73px;
				height: 142px;
				width: 134px;
			}
		}
	}

	// Medium Only
	@include media($medium-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}#{$bg-img}-m.png) center bottom no-repeat;
			height: 42.875em;

			// 2x
			@include image-2x(url(#{$bg-img-path}#{$bg-img}-m@2x.png) center bottom no-repeat, 406px, 686px)

			// Position the graphic(s)
			> .graphic {
				top: 108px;
				left: 50%;
				margin-left: -72px;
				height: 136px;
				width: 128px;
			}
		}
	}

	// Small Only
	@include media($small-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}#{$bg-img}-s.png) center bottom no-repeat;
			height: 28.625em;

			// 2x
			@include image-2x(url(#{$bg-img-path}#{$bg-img}-s@2x.png) center bottom no-repeat, 272px, 458px)

			// Position the graphic(s)
			> .graphic {
				top: 72px;
				left: 50%;
				margin-left: -48px;
				overflow: hidden;
				height: 92px;
				width: 86px;
			}
		}
	}
}
