//  $$\      $$\ $$\           $$\
//  $$$\    $$$ |\__|          \__|
//  $$$$\  $$$$ |$$\ $$\   $$\ $$\ $$$$$$$\   $$$$$$$\
//  $$\$$\$$ $$ |$$ |\$$\ $$  |$$ |$$  __$$\ $$  _____|
//  $$ \$$$  $$ |$$ | \$$$$  / $$ |$$ |  $$ |\$$$$$$\
//  $$ |\$  /$$ |$$ | $$  $$<  $$ |$$ |  $$ | \____$$\
//  $$ | \_/ $$ |$$ |$$  /\$$\ $$ |$$ |  $$ |$$$$$$$  |
//  \__|     \__|\__|\__/  \__|\__|\__|  \__|\_______/
//
//
//

// ------------------------------------------------------------
// Omega Reset Mixin for Neat
// http://joshfry.me/notes/omega-reset-for-bourbon-neat/
// ------------------------------------------------------------
@mixin omega-reset($nth) {
	&:nth-child(#{$nth}) { margin-right: flex-gutter(); }
	&:nth-child(#{$nth}+1) { clear: none }
}

// ---------------------------------------------------------------------------------
// Image Replacement
// ---------------------------------------------------------------------------------

@mixin img-replacement($width, $height, $display: block) {
	display: $display;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	width: $width;
	height: $height;
}

// ---------------------------------------------------------------------------------
// Caps
// ---------------------------------------------------------------------------------

@mixin caps($letter-spacing: 0.05em) {
	text-transform: uppercase;
	letter-spacing: $letter-spacing;
}

// ---------------------------------------------------------------------------------
// 3d Transform Mixin
// ---------------------------------------------------------------------------------
@mixin hardware-acceleration {
	backface-visibility: hidden;
	perspective: 1000;
	transform: translate3d(0,0,0);
}

// ---------------------------------------------------------------------------------
// Are you a touch-enabled device? Are you? ANSWER ME!
// Note: Modernizr dependent
// ---------------------------------------------------------------------------------

@mixin is-touch {.touch & { @content }; }

// ---------------------------------------------------------------------------------
// Image 2x
// ---------------------------------------------------------------------------------

@mixin image-2x($background, $width: auto, $height: auto) {
	@media only screen and
	(-webkit-min-device-pixel-ratio: 1.3),
	(min-device-pixel-ratio: 1.3) {
		background: $background;
		background-size: $width $height;
	}
}

// ---------------------------------------------------------------------------------
// Display: Inline Block
// ---------------------------------------------------------------------------------

@mixin inline-block($valign: middle) {
	display: inline-block;
	vertical-align: $valign;
}

// ---------------------------------------------------------------------------------
// Z-index
// note: Uses `z` function in `deafults.scss`
// ---------------------------------------------------------------------------------

@mixin z-index($key) {
	z-index: z($key);
}

@mixin icon($size: $module-icon-lg) {
	height: $size;
	//overflow: hidden;
	width: $size;
}

// ---------------------------------------------------------------------------------
// Flickity styles
// ---------------------------------------------------------------------------------

@mixin flickity-next {
    svg {
        display: none;
    }

    background-image: url(#{$bg-img-path}arrow-next-blue.svg);
    background-repeat: no-repeat;
    background-position: center;
}

@mixin flickity-enabled {
    opacity: 0;

    &.flickity-enabled {
        opacity: 1;
    }
}

