.photo-grid-content-3 {
	margin: 0 auto;

	@include media($xxlarge-up) {
		height: 63.5em;
		max-width: 84.75em;
	}

	@include media($xlarge-only) {
		max-width: 58.75em;
	}

	@include media($xlarge-up) {
		padding: 0 $gutter/2;
	}

	@include media($large-only) {
		max-width: 48em;
	}

	@include media($to-large) {
		//max-width: 26.25em;
	}

	> .blocks {
		position: relative;
		width: 100%;

		> .block {
			background: color(gray, lightest);

			img {
				display: block;
			}

			&.-b01 {

				@include media($xlarge-up) {
					max-height: 63.5em;
					width: 49%;
				}

				@include media($large-only) {
					max-height: 63.5em;
					width: 48.25%;
				}

				@include media($to-large) {
					display: none;
				}
			}

			&.-b02 {

				@include media($large-up) {
					position: absolute;
					top: 0;
					right: 0;
				}

				@include media($xlarge-up) {
					max-height: 35.25em;
					width: 49%;
				}

				@include media($large-only) {
					max-height: 35.25em;
					width: 48.25%;
				}

				@include media($to-large) {
					width: 100%;
				}
			}

			&.-b03 {

				@include media($large-up) {
					position: absolute;
					bottom: 0;
					right: 0;
				}

				@include media($xlarge-up) {
					max-height: 26.625em;
					width: 49%;
				}

				@include media($large-only) {
					max-height: 26.625em;
					width: 48.25%;
				}

				@include media($to-large) {
					width: 100%;
				}
			}
		}
	}
}
