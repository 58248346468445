.card {

	&.-title-visual {
		background-color: color(gray, lightest);
		display: block;
		min-width: 10em;
		overflow: hidden;
		position: relative;

		@include media($xxlarge-up) {
			height: 14.5em;
		}

		@include media($xlarge-only) {
			height: 11.5625em;
		}

		@include media($large-only) {
			height: 12.75em;
		}

		@include media($to-large) {
			height: 14.5em;
		}

		.link {
			align-items: center;
			display: flex;
			height: 100%;
			justify-content: center;
			width: 100%;

			&:hover {

				.title {
					color: color(white);
					font-weight: $header-font-weight;
				}

				.visual {
					transform: scale(1.05);

					&:before {
						background-color: rgba( color(primary), 0.7 );
					}
				}
			}
		}

		.title {
			@include h-xxl($body-font-weight);
			color: color(black);
			position: relative;
			margin: 0;
			text-align: center;
			transition: color 300ms $ease-in-out-quad, font-weight 300ms $ease-in-out-quad;
			width: 100%;
			@include z-index(priority6);
		}

		.visual {
			background-position: center center;
			background-size: cover;
			height: 100%;
			position: absolute;
			top: 0;
			transition: transform 300ms $ease-in-out-quad;
			left: 0;
			width: 100%;
			will-change: transform;
			@include z-index(priority8);

			&:before {
				background-color: rgba( color(white), 0.6 );
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				transition: background-color 300ms $ease-in-out-quad;
				width: 100%;
				@include z-index(priority7);
			}
		}
	}
}

.redesign-card {
    padding: rem(12);

    & .title-visual {
        background-color: color(gray, lightest);
        display: block;
        overflow: hidden;
        position: relative;
        height: rem(114);

        @include media($xlarge-up) {
            height: rem(168);
        }

        @include media($small-only) {
            height: rem(168);
        }

        .link {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;

            &:hover {

                .title {
                    color: color(white);
                    font-weight: $header-font-weight-heavy;
                }

                .visual {
                    transform: scale(1.05);

                    &:before {
                        background-color: rgba( color(primary), 0.7 );
                    }
                }
            }
        }

        .title {
            font-size: rem(24);
            line-height: 1.4;
            color: color(black);
            position: relative;
            margin: 0 rem(12);
            text-align: center;
            transition: color 300ms $ease-in-out-quad, font-weight 300ms $ease-in-out-quad;
            width: 100%;
            @include z-index(priority6);

            @include media($large-up) {
                margin: 0;
            }
        }

        .visual {
            height: 100%;
            position: absolute;
            top: 0;
            transition: transform 300ms $ease-in-out-quad;
            left: 0;
            width: 100%;
            will-change: transform;
            display: flex;
            @include z-index(priority8);

            img {
                object-fit: cover;
            }

            &:before {
                background-color: rgba( color(white), 0.6 );
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                transition: background-color 300ms $ease-in-out-quad;
                width: 100%;
                @include z-index(priority7);
            }
        }
    }

    &.-second-last {
        @include media($xlarge-up) {
            margin-left: 25%;
        }
    }

    &.-last {
        @include media($large-only) {
            margin-left: 33.3333%;
        }

        @include media($midscreen-only) {
            margin-left: 33.3333%;
        }
    }

    @include media($xlarge-up) {
        padding: rem(16);
    }
}
