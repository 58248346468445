.carousel-home-masthead {

	// Offset for flikity dots for sm to lg	
	@include media($large-only) {
		padding-bottom: 5em;
	}

	@include media($to-large) {
		padding-bottom: 3em;
	}

	> .slides {
		@include clearfix;
		height: 100%;
	}

	.slide {
		background-repeat: no-repeat;
		width: 100%;

		@include media($xlarge-up) {
			background-position: center center;
		}

		@include media($large-up) {
			height: 53.75em; //860px
		}

		@include media($to-xlarge) {
			background-position: center bottom;
		}

		@include media($to-large) {
			height: 32.5em; // 520px
		}

		.wrap {
			display: flex;
			flex-flow: row nowrap;
			margin: 0 auto;
			height: 100%;

			@include media($xxlarge-up) {
				max-width: 74.375em + ($gutter*2);
				padding: 0 $gutter;
			}

			@include media($xlarge-up) {
				align-items: center;
				justify-content: flex-start;
			}

			@include media($xlarge-only) {
				width: 100%;
			}

			@include media($to-xlarge) {
				align-items: flex-start;
				justify-content: center;
				width: 100%;
			}
		}

		.content {

			@include media($xxlarge-up) {
				width: 38em;
			}

			@include media($xlarge-only) {
				margin-left: 6.125em;
				width: 30em;
			}

			@include media($to-xlarge) {
				text-align: center;
			}

			@include media($large-only) {
				margin: 0 auto;
				max-width: 48em;
				padding: 3.75em $gutter 0 $gutter;
			}

			@include media($to-large) {
				padding: 2em $gutter 0 $gutter;
			}

			.logo {
				margin-bottom: 2em;
				max-height: 5em;
				max-width: 20em;

				@include media($large-only) {
					margin: 0 auto 2em auto;
				}

				@include media($to-large) {
					height: 3.5em;
					margin: 0 auto;
				}
			}

			.title {
				@include h-content-h1(1.4);
				margin-bottom: .75em;

				@include media($to-large) {
					font-size: 1.5em;
				}
			}

			.text {
				margin-bottom: 2em;

				@include media($to-large) {
					display: none;
				}

				p {
					@include p-md(1.5);
				}
			}
		}
	}

	.flickity-prev-next-button {
		top: 50%;

		@include media($to-xxlarge) {
			display: none;
		}

		svg {
			height: 1.5em;
			width: 2em;
		}

		.arrow {
			height: 1.5em;
			width: 2em;
			fill: color(gray);
		}

		&.next {
			right: 1em;
		}
	}

	.flickity-page-dots {

		@include media($xlarge-up) {
			bottom: 4em;
		}

		@include media($to-xlarge) {
			$border: 1px solid color(gray, lighter);
			align-items: center;
			background-color: color(gray, lightest);
			border: {
				top: $border;
				bottom: $border;
			}
			display: flex;
			justify-content: center;
		}

		@include media($large-only) {
			bottom: -5em;
			height: 5em;
		}

		@include media($to-large) {
			bottom: -3em;
			height: 3em;
		}

		.dot {
			border-color: color(primary);
			height: 0.875em;
			width: 0.875em;

			@include media($to-large) {
				height: .65em;
				width: .65em;
			}

			&.is-selected {
				background-color: color(primary);
			}
		}
	}
}
