//  $$$$$$$$\                                                                   $$\
//  \__$$  __|                                                                  $$ |
//     $$ |$$\   $$\  $$$$$$\   $$$$$$\   $$$$$$\   $$$$$$\  $$$$$$\   $$$$$$\  $$$$$$$\  $$\   $$\
//     $$ |$$ |  $$ |$$  __$$\ $$  __$$\ $$  __$$\ $$  __$$\ \____$$\ $$  __$$\ $$  __$$\ $$ |  $$ |
//     $$ |$$ |  $$ |$$ /  $$ |$$ /  $$ |$$ /  $$ |$$ |  \__|$$$$$$$ |$$ /  $$ |$$ |  $$ |$$ |  $$ |
//     $$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |$$ |     $$  __$$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |
//     $$ |\$$$$$$$ |$$$$$$$  |\$$$$$$  |\$$$$$$$ |$$ |     \$$$$$$$ |$$$$$$$  |$$ |  $$ |\$$$$$$$ |
//     \__| \____$$ |$$  ____/  \______/  \____$$ |\__|      \_______|$$  ____/ \__|  \__| \____$$ |
//         $$\   $$ |$$ |                $$\   $$ |                   $$ |                $$\   $$ |
//         \$$$$$$  |$$ |                \$$$$$$  |                   $$ |                \$$$$$$  |
//          \______/ \__|                 \______/                    \__|                 \______/

// Headings Reset
// -------------------------
h1,
h2,
h3,
h4,
h5,
h6
{
	font: {
		size: 1em;
		weight: $header-font-weight;
	}
	margin: 0;
	padding: 0;
}

// Body Text Reset
// -------------------------
p,
ul,
ol,
blockquote,
figure,
pre,
code,
cite
{
	font: {
		size: 1em;
		style: normal;
		weight: $body-font-weight;
	}
	margin: 0;
	padding: 0;
}

ol,
ul,
dl {
	padding: 0
}

ul,
ol {
	list-style-position: inside;
}

ul {
	list-style-type: none;
}

dl {
	padding: 0;

	dd {
		margin: 0;
	}
}

address {
	font-style: normal;
}

body{
	-moz-hyphens: none;
	-ms-hyphens: none;
	-webkit-hyphens: none;
	hyphens: none;
}

.text-center{
	text-align: center;
}

// Paragraphs
@mixin p($line-height: 1.5, $margin-bottom: 1em) {
	line-height: $line-height;

	&:not(:last-child) {
		margin-bottom: $margin-bottom;
	}
}

// Unordered List
@mixin ul {
	@include p;
	margin-left: 1em;

	li {
		list-style: disc outside;
		margin: .5em 0;
	}
}

// Ordered List
@mixin ol {
	@include p;
	margin-left: 1.5em;

	li {
		list-style: decimal outside;
		margin: .5em 0;
	}
}

// Blockquotes
@mixin blockquote {
	@include p;
	border-left: 1px solid rgba(0,0,0,.1);
	color: rgba(0, 0, 0, 0.6);
	font-style: italic;
	margin-bottom: 1.5rem;
	padding-left: 1.5rem;
}

@mixin h-xxl($weight: $header-font-weight, $line-height: 1.1, $margin-bottom: .5em) {
	font: {
		size: 1.75em;
		weight: $weight;
	}
	line-height: $line-height;
	margin-bottom: $margin-bottom;
}

// --------------------------------------------------------------------------------------
//
// Typography Mixins
//
// --------------------------------------------------------------------------------------

// -------------------------------------------
// Module Headers
// -------------------------------------------

// Module Title
@mixin h-mod-h1 {
	font: {
		size: 2.25em;
	}
	line-height: 1.1;
	margin-bottom: 1em;

	@include media($small-only) {
		font: {
			size: 2em;
		}
	}
}

// Module Subtitle
@mixin h-mod-subtitle {
	@include caps(0.15em);
	display: inline-block;
	font: {
		size: 0.875em;
	}
	margin-bottom: 2.375em;
}

// Module Subtitle - Strong
@mixin h-mod-subitle-strong {
	display: inline-block;
	font: {
		size: 1.5em;
		weight: 700;
	}
	letter-spacing: 0;
	margin-bottom: .5em;
	text-transform: none;
}

// Module Block Title
@mixin h-mod-block-title($weight: $body-font-weight) {
	font: {
		size: 1.25em;
		weight: $weight;
	}
	line-height: 1.3;
	margin-bottom: .85em;
}

// Moduel Block Title XL
@mixin h-mod-block-title-xl($weight: $body-font-weight, $line-height: 1.3, $margin-bottom: .85em) {
	font: {
		size: 2.25em;
		weight: $weight;
	}
	line-height: $line-height;
	margin-bottom: $margin-bottom;
}

// Moduel Block Title XL
@mixin h-mod-block-title-xxl($weight: $body-font-weight, $line-height: 1.3, $margin-bottom: .85em) {
	font: {
		size: 3em;
		weight: $weight;
	}
	line-height: $line-height;
	margin-bottom: $margin-bottom;
}

// -------------------------------------------
// Body Copy Sizes
// -------------------------------------------

@mixin p-xxl($line-height: 1.5) {
	font: {
		size: 1.75em;
	}
	line-height: $line-height;
}

@mixin p-xl($line-height: 1.5) {
	font: {
		size: 1.5em;
	}
	line-height: $line-height;
}

@mixin p-l($line-height: 1.5) {
	font: {
		size: 1.25em;
	}
	line-height: $line-height;
}

@mixin p-md($line-height: 1.35) {
	font: {
		size: 1.125em;
	}
	line-height: $line-height;
}

@mixin p-sm($line-height: 1.5) {
	font: {
		size: 0.875em;
	}
	line-height: $line-height;
}

@mixin p-xsm($line-height: 1.3) {
	font: {
		size: 0.75em;
	}
	line-height: $line-height;
}

@mixin p-xxsm($line-height: 1.3) {
	font: {
		size: 0.65em;
	}
	line-height: $line-height;
}

// -------------------------------------------
// Content Headers
// -------------------------------------------

@mixin h-content-h1($line-height: 1.2) {
	font: {
		size: 2.125em;
	}
	line-height: $line-height;
}

@mixin h-content-h2($line-height: 1.2) {
	font: {
		size: 1.75em;
	}
	line-height: $line-height;
}

@mixin h-content-h3($line-height: 1.3) {
	font: {
		size: 1.375em;
	}
	line-height: $line-height;
}

// Updated styles redesign
h1{
	font-size: 3.125rem;
	line-height: 3.375rem;
}
main{
	& > h1{
		margin-bottom: 1.875rem;
	}
}
h2{
	font-size: 2.25rem;
	line-height: 2.625rem;
	@include media($small-only) {
		font: {
			size: 1.9rem;
		}
	}
	&.title{
		@include media($small-only) {
			font: {
				size: 1.9rem;
			}
		}
	}
}
h3{
	font-size: 1.65rem;
	line-height: 2.25rem;
}
h4{
	font-size: 1.375rem;
	line-height: 1.625rem;
}
h5{
	font-size: 0.65625rem;
	line-height: 0.875rem;
	text-transform: uppercase;
}

p{
	font-size: 1rem;
	line-height: 1.5rem;
}
.small-caps{
	font-size: 0.75rem;
	line-height: 1.5rem;
	text-transform: uppercase;
	font-weight: $header-font-weight-heavy;
	@include media($small-only) {
		font: {
			size: 1rem;
		}
	}
}
.tab-caps{
	font-size: 0.875rem;
	line-height: 1.5rem;
	text-transform: uppercase;
	font-weight: $header-font-weight-heavy;
	@include media($small-only) {
		font: {
			size: 2rem;
		}
		line-height: 1.8rem;
	}
}
.small-p{
	font-size: 0.875rem;
	line-height: 1.3125rem;
	color: color(primary);
	text-decoration: underline;
	@include media($small-only) {
		font: {
			size: 1.250rem;
		}
		line-height: 1.75rem;
	}
}

blockquote {
  display: block;
  border-width: 0 0 0 2px;
  border-style: solid;
  padding: 1.5em 0 0.5em;
  margin: 1.5em 0;
  position: relative;
  font-size: 1.75rem;
  line-height: 2.25rem;
  padding-left: 40px;
}

blockquote:before {
  content: '\201C';
  position: absolute;
  top: 0;
  left: 55px;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 2rem;
  height: 2rem;
  font: 4rem/1.3 Georgia serif;
  color: inherit;
  text-align: center;
  font-weight: bold;
}
blockquote:after {
  content: "\2013 \2003" attr(cite);
  display: block;
  text-align: left;
  font-size: 1.375rem;
  line-height: 1.8125rem;
  color: inherit;
  margin-top: 40px;
}
