.-module-02a {
	$bg-img-name: bg-module-02a;
	@include bg-default;
	margin: 0 auto;
	max-width: 86em;

	// --------------------------------------
	// Moudle 02a Layouts & Styling
	// --------------------------------------

	.icon {
		@include icon();
	}

	// XXLarge-up
	@include media($xxlarge-up) {

		.module-content {
			max-width: 66em;
		}

		// Wrapping Container
		.module-presentation {
			@include clearfix;
			min-height: 54em;
			padding-left: 36em;
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			@include position( absolute, null null 0px 0px );
			background: url(#{$bg-img-path}#{$bg-img-name}-xxl.png) left bottom no-repeat;
			height: 54em;
			width: 33em;

			@include image-2x(url(#{$bg-img-path}#{$bg-img-name}-xxl@2x.png) left bottom no-repeat, 520px, 852px)

			// Position the graphic(s)
			> .graphic {
				top: 82px;
				left: 99px;
				width: 272px;
				height: 465px;
			}
		}

		.module-items-outer {

		}

		// Module Items Lists
		.module-items {
			max-width: 28em;
		}

	}

	// XLarge-only
	@include media($xlarge-only) {

		// Wrapping Container
		.module-presentation {
			min-height: 53em;
			padding: 0 0 1em 34em;
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			@include position( absolute, null null 0 2em );
			background: url(#{$bg-img-path}#{$bg-img-name}-xl.png) left bottom no-repeat;
			height: 53em;
			width: 31em;

			@include image-2x(url(#{$bg-img-path}#{$bg-img-name}-xl@2x.png) left bottom no-repeat, 482px, 844px)

			// Position the graphic(s)
			> .graphic {
				top: 67px;
				left: 91px;
				width: 251px;
				height: 432px;
			}
		}

		// Module Items Lists
		.module-items {
			max-width: 36em;
			padding-right: $gutter;
		}
	}

	// Small - Large
	@include media($to-xlarge) {

		.module-items-outer {
			background-color: color(gray, lighter);
			padding: 2em 0;
		}
	}

	// Large Only
	@include media($large-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}bg-module-01a-l.png) center bottom no-repeat;
			height: 37em;

			@include image-2x(url(#{$bg-img-path}bg-module-01a-l@2x.png) center bottom no-repeat, 411px, 580px)

			// Position the graphic(s)
			> .graphic {
				top: 72px;
				left: 50%;
				margin-left: -126px;
				overflow: hidden;
				height: 368px;
				width: 216px;
			}
		}
	}

	// Medium Only
	@include media($medium-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}bg-module-01a-m.png) center bottom no-repeat;
			height: 38em;

			// 2x
			@include image-2x(url(#{$bg-img-path}bg-module-01a-m@2x.png) center bottom no-repeat, 402px, 600px)

			// Position the graphic(s)
			> .graphic {
				top: 65px;
				left: 50%;
				margin-left: -121px;
				overflow: hidden;
				height: 354px;
				width: 208px;
			}
		}
	}

	// Small Only
	@include media($small-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}bg-module-01a-s.png) center bottom no-repeat;
			height: 28em;

			// 2x
			@include image-2x(url(#{$bg-img-path}bg-module-01a-s@2x.png) center bottom no-repeat, 304px, 433px)

			// Position the graphic(s)
			> .graphic {
				top: 60px;
				left: 50%;
				margin-left: -92px;
				overflow: hidden;
				height: 263px;
				width: 155px;
			}
		}
	}
}
