//   $$$$$$\            $$\       $$\        $$$$$$\                                           $$\       $$\
//  $$  __$$\           \__|      $$ |      $$  __$$\                                          \__|      $$ |
//  $$ /  \__| $$$$$$\  $$\  $$$$$$$ |      $$ /  $$ |$$\    $$\  $$$$$$\   $$$$$$\   $$$$$$\  $$\  $$$$$$$ | $$$$$$\   $$$$$$$\
//  $$ |$$$$\ $$  __$$\ $$ |$$  __$$ |      $$ |  $$ |\$$\  $$  |$$  __$$\ $$  __$$\ $$  __$$\ $$ |$$  __$$ |$$  __$$\ $$  _____|
//  $$ |\_$$ |$$ |  \__|$$ |$$ /  $$ |      $$ |  $$ | \$$\$$  / $$$$$$$$ |$$ |  \__|$$ |  \__|$$ |$$ /  $$ |$$$$$$$$ |\$$$$$$\
//  $$ |  $$ |$$ |      $$ |$$ |  $$ |      $$ |  $$ |  \$$$  /  $$   ____|$$ |      $$ |      $$ |$$ |  $$ |$$   ____| \____$$\
//  \$$$$$$  |$$ |      $$ |\$$$$$$$ |       $$$$$$  |   \$  /   \$$$$$$$\ $$ |      $$ |      $$ |\$$$$$$$ |\$$$$$$$\ $$$$$$$  |
//   \______/ \__|      \__| \_______|       \______/     \_/     \_______|\__|      \__|      \__| \_______| \_______|\_______/
//
//
//

$visual-grid: false !default; // Display the base grid
$column: 4.2358em !default;
$gutter: 1.618em !default;
$max-width: 1260px !default; // Max-width of the outer container
