.cta-news-ticker {
	$border: 1px solid color(gray, lighter);
	border: {
		top: $border;
		bottom: $border;
	}

	&.-dark {
		background: linear-gradient(180deg, color(primary, light) 0%, color(primary) 100%);
		border: none;
		border-radius: 0;
		color: color(white);

		.action p {
			font-weight: $header-font-weight;
		}
	}

	@include media($xlarge-up) {
		align-items: center;
		border-radius: 5px;
		display: flex;
		height: 5.625em;
		justify-content: center;
	}

	@include media($to-xlarge) {
		text-align: center;
		padding: 2em $gutter;
	}

	.content {

		@include media($xlarge-up) {
			padding: 0 $gutter*2;
		}

		@include media($to-xlarge) {
			padding: 1em $gutter;
		}
	}

	.action {

	}

	a {
		color: color(secondary);
		font: {
			weight: $header-font-weight;
		}
		text-decoration: underline;

		&:hover {
			color: color(secondary);
			opacity: 0.7;
		}
	}

	p {
		@include p;
	}

	.header {
		text-align: center;

		@include media($to-large) {
			padding-bottom: 1em;
		}
	}

	.icon {
		display: block;
		margin: 0 auto .35em auto;
		fill: color(secondary);
		height: 1.75em;
		width: 2.375em;
	}

	.title {
		font: {
			weight: $header-font-weight;
		}
	}
}
