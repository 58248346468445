.header-contact {
	margin-bottom: 2.5em;

	@include media($xxlarge-up) {
		margin-bottom: 3.75em;
	}

	.subhead {
		display: block;
		margin-bottom: .75em;
	}

	.title {
		@include h-content-h1();
		margin-bottom: .5em;
	}

	p {
		@include p;
	}

	strong {
		font-weight: $header-font-weight-heavy;
	}
}
