// tabs nav

.carousel-home-tabs__nav {
    background: color(primary);
    padding-left: rem(10);

    @include media($xlarge-up) {
        padding-left: rem(56);
    }
}

.carousel-home-tabs__nav-inner {
    max-width: 86em;
    margin: 0 auto;
}

.carousel-home-tabs__nav-links {
    @include flickity-enabled;
}

.carousel-home-tabs__nav-link {
    color: $color-white;
    height: rem(84);
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        font-size: rem(14);
        font-weight: $header-font-weight-heavy;
        white-space: nowrap;
        padding: 0 rem(18);
        position: relative;
        @include caps(.01em);
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        height: rem(30);
        width: 1px;
        background: color(blue, lighter);
    }

    &:first-child {
        &:before {
            display: none;
        }
    }

    &.is-nav-selected {
        background: $color-white;
        color: color(gray, darker);
        z-index: 1;

        &:before {
            display: none;
        }
    }

    @include media($xlarge-up) {
        height: rem(62);

        span {
            padding: 0 rem(56);
        }
    }
}

.carousel-home-tabs__inner {
    max-width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    padding-top: rem(30);

    @include media($xlarge-up) {
        flex-direction: row;
        padding-top: 0;
    }
}

// text slides

.carousel-home-tabs__text-slides {
    width: 100%;
    padding: 0 rem(26);

    @include media($xlarge-up) {
        width: 39.5%;
        padding: 0;

        .flickity-viewport {
            height: 100% !important;
        }
    }

    @include flickity-enabled;
}

.carousel-home-tabs__text-slide {
    width: 100%;
    padding-bottom: rem(50);

    .title {
        font-size: 1.9rem;
        line-height: 1.3;
        margin-bottom: rem(14);
        letter-spacing: -.015em;

        @include media($large-up) {
            font-size: rem(32);
            margin-bottom: rem(28);
            letter-spacing: -.01em;
        }
    }

    .subtitle {
        font-size: rem(18);
        line-height: 1.6;
        margin-bottom: rem(46);
        letter-spacing: -.01em;

        @include media($large-up) {
            font-size: rem(14);
            margin-bottom: rem(32);
            letter-spacing: 0;
        }
    }

    @include media($xlarge-up) {
        padding: 0 10%;
    }
}

.carousel-home-tabs__cta {
    margin-top: rem(20);
    margin-bottom: rem(44);

    @include media($large-up) {
    }
}

.carousel-home-tabs__content {
    display: flex;
    padding-top: rem(6);
}

.carousel-home-tabs__content-heading {
    width: rem(140);

    p {
        font-weight: $header-font-weight-heavy;
        @include caps(.01em);

        @include media($large-up) {
            font-size: rem(12);
        }
    }
}

.carousel-home-tabs__content-inner {
    flex-grow: 1;
    padding: 0 rem(60) 0 rem(30);

    a {
        color: color(primary);

        @include media($large-up) {
            font-size: rem(12);
        }
    }

    @include media($large-up) {
        padding: 0;
    }
}

// image slides

.carousel-home-tabs__image-slides {
    width: 100%;
    padding-left: rem(26);
    margin-bottom: rem(30);

    .flickity-prev-next-button {
        &.previous {
            display: none;
        }

        &.next {
            right: 10px;
            top: 50%;
            height: 98px;
            width: 98px;
            background: $color-white;
            border-radius: 100%;
            box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);

            @include flickity-next;

            @include media($xlarge-up) {
                right: -20px;
                top: 48%;
            }
        }

        &:active {
            opacity: 1;
        }
    }

    @include media($xlarge-up) {
        width: 60.5%;
        padding-left: 0;
        margin-bottom: 0;
        align-self: flex-start;
    }

    @include flickity-enabled;
}

.carousel-home-tabs__image-slide {
    width: 76%;
    height: rem(296);
    display: flex;
    flex-direction: column;
    opacity: .6;

    &.is-selected{
        opacity: 1;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @include media($xlarge-up) {
        width: 90%;
        height: rem(790);
    }
}
