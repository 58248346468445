.layout-news-block {

	@include media($large-up) {
		margin: 2em 0;

		&:first-of-type {
			margin-top: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&.-solid {
		background-color: color(white);
		border-radius: 5px;
	}
}
