.article-full {

	@include media($to-large) {
		margin: 0 auto;
		max-width: 39.125em;
	}

	.title {
		@include h-content-h2;
		padding-bottom: .1em;
	}

	.visual {
		align-items: center;
		background: color(gray, lightest);
		display: flex;
		padding: 1em;
		justify-content: center;

		@include media($large-up) {
			height: 11.625em;
			margin: 4em 0;
		}

		@include media($to-large) {
			height: 6.75em;
			margin: 2.75em 0;
		}
	}

	.content {
		position: relative;

		@include media($large-up) {
			padding-top: 4em;
		}

		@include media($to-large) {
			padding-top: 2.75em;
		}

		&::before {
			background: url(#{$bg-img-path}divider-logomark.svg) center center no-repeat;
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -4.5em;
			height: 9px;
			width: 9em;
		}
	}
}
