.-module-01a {
	@include bg-default;
	margin: 0 auto;
	max-width: 86em;

	// --------------------------------------
	// Moudle 01a Layouts & Styling
	// --------------------------------------
	//

	.icon {
		@include icon();
	}

	// XXLarge-up
	@include media($xxlarge-up) {

		.module-content {
			margin: 0 auto;
			max-width: 80em;
		}

		// Wrapping Container
		.module-presentation {
			@include clearfix;
			min-height: 40em;
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			@include position( absolute, null null 0px 50% );
			background: url(#{$bg-img-path}bg-module-01a-xxl.png) center bottom no-repeat;
			height: 40em;
			margin-left: (-27/2)+em;
			width: 27em;
			overflow: hidden;

			// 2x
			@include image-2x(url(#{$bg-img-path}bg-module-01a-xxl@2x.png) center bottom no-repeat, 395px, 621px)

			// Position the graphic(s)
			> .graphic {
				top: 76px;
				left: 103px;
				width: 201px;
				height: 342px;
			}
		}

		.module-items-outer {
			columns: 2;
			column-gap: 28em;
			vertical-align: top;
		}

		// Module Items Lists
		.module-items {
			 max-width: 26em;
			 padding: 0 $gutter/2;
		}

		// Module Item
		.module-item {
			break-inside: avoid;
			margin-bottom: 0;
			min-height: 10em;
		}
	}

	// XLarge-only
	@include media($xlarge-only) {

		.module-content {
			max-width: 68em;
		}

		// Wrapping Container
		.module-presentation {
			min-height: 60em;
			padding: 0 0 1em 32em;
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			@include position( absolute, null null 0 0 );
			background: url(#{$bg-img-path}bg-module-01a-xl.png) center bottom no-repeat;
			height: 60em;
			width: 30em;

			// 2x
			@include image-2x(url(#{$bg-img-path}bg-module-01a-xl@2x.png) center bottom no-repeat, 476px, 952px)

			// Position the graphic(s)
			> .graphic {
				top: 70px;
				left: 75px;
				width: 201px;
				height: 343px;
			}
		}

		// Module Items Lists
		.module-items {
			//max-width: 36em;
			padding-right: $gutter;
		}

		// Module Item
		.moudle-item {
			margin-bottom: 1em; // Tighter margin
		}
	}

	// Small - Large
	@include media($to-xlarge) {

		.module-content {
			display: flex;
	  		flex-direction: column;
		}

		.module-intro {
			//order: 2;
		}

		.module-presentation {
			//order: 1;
		}

		.module-items-outer {
			background-color: color(gray, lighter);
			padding: 2em 0;
		}
	}

	// Large Only
	@include media($large-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}bg-module-01a-l.png) center bottom no-repeat;
			height: 37em;

			// 2x
			@include image-2x(url(#{$bg-img-path}bg-module-01a-l@2x.png) center bottom no-repeat, 411px, 580px)

			// Position the graphic(s)
			> .graphic {
				top: 72px;
				left: 50%;
				margin-left: -126px;
				height: 368px;
				width: 216px;
			}
		}
	}

	// Medium Only
	@include media($medium-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}bg-module-01a-m.png) center bottom no-repeat;
			height: 38em;

			// 2x
			@include image-2x(url(#{$bg-img-path}bg-module-01a-m@2x.png) center bottom no-repeat, 402px, 600px)

			// Position the graphic(s)
			> .graphic {
				top: 65px;
				left: 50%;
				margin-left: -121px;
				height: 354px;
				width: 208px;
			}
		}
	}

	// Small Only
	@include media($small-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			background: url(#{$bg-img-path}bg-module-01a-s.png) center bottom no-repeat;
			height: 28em;

			// 2x
			@include image-2x(url(#{$bg-img-path}bg-module-01a-s@2x.png) center bottom no-repeat, 304px, 433px)

			// Position the graphic(s)
			> .graphic {
				top: 60px;
				left: 50%;
				margin-left: -92px;
				overflow: hidden;
				height: 263px;
				width: 154px;
			}
		}
	}
}
