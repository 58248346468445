.card-bio {
	margin: 1em 0;
	max-width: 20em;
	text-align: center;

	.link-toggle {
		display: block;
		mix-blend-mode: multiply;
		padding: 1em;
		transform: background 300ms $ease-in-out-quad, opacity 300ms $ease-in-out-quad;

		&.-is-open {
			background: linear-gradient(to top, rgba(color(white), 0) 0%, color(gray, lighter) 100%);
			//box-shadow: inset 0 1px 1px rgba( color(gray, dark), 0.1 );

			.title {
				color: color(sky, dark);
				font-weight: $header-font-weight;
			}
		}

		&:hover {

			.title {
				color: color(sky, dark);
			}
		}

		&:active {
			opacity: 0.8;
		}
	}

	.photo {
		display: block;
		height: 7em;
		margin: 0 auto;
		width: 7em;

		> img {
			border-radius: 50%;
		}
	}

	.header {
		margin: 2em 0 1em 0;
		width: 100%;

		> .title {
			transition: color 300ms $ease-in-out-quad;
		}
	}

	.info {
		@include p-xsm(1.5);
		width: 100%;

		> .job-title {
			display: block;
		}
	}

	.full-bio {
		display: none;
	}
}

.card-bio-full {
	@include p-sm;
	text-align: left;

	&.-is-hidden {
		display: none;
	}

	&.-is-visible {
		width: 100%;
	}

	p {
		@include p;
	}
}
