.layout-grid-contact-cards-index {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;

	@include media($xxlarge-up) {
		margin: 0 auto;
		width: 64em;
	}

	@include media($xlarge-only) {
		margin: 0 auto;
		width: 58em;
	}

	@include media($large-only) {
		margin: 0 auto;
		width: 38em;
	}

	@include media($to-large) {
		margin: 0 auto;
	}

	> .card-icon-link {
		margin: {
			top: 1em;
			bottom: 1em;
		}

		@include media($to-large) {
			margin: {
				top: .5em;
				bottom: .5em;
			}
		}

		@include media($xxlarge-up) {
			width: 20em;
		}

		@include media($xlarge-only) {
			width: 18em;
		}

		@include media($xlarge-up) {

			&:nth-child(3n-1) {
				margin: {
					left: 2em;
					right: 2em;
				}
			}
		}

		> .content {

			> .lower {

				@include media($to-large) {
					margin-left: 2em;
				}

			}

		}
	}
}
