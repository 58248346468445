.-module-08a {

	@include media($xlarge-up) {
		//padding: 1em;
	}

	> .module-content {
		background-position: center top;
		background-size: cover;
		position: relative;

		@include media($xxlarge-up) {
			padding-left: 10em;
		}

		@include media($xlarge-up) {
			height: 25em;
			margin: 0 auto;
			max-width: 85.25em;
		}

		@include media($xlarge-only) {
			padding-left: 6.25em;
		}

		@include media($large-only) {
			height: 25em;
			padding-left: 3.75em;
		}

		@include media($to-large) {
			height: 25em;
			padding: 0 2em;
		}

		// &:before {
		// 	background: linear-gradient(to right, color(gray, dark) -10%, rgba( color(white), 0 ) 110%);
		// 	content: '';
		// 	display: block;
		// 	height: 100%;
		// 	left: 0;
		// 	position: absolute;
		// 	top: 0;
		// 	width: 100%;
		// 	z-index: 1;
		// }

		> .module-item {
			align-items: center;
			display: flex;
			height: 100%;
			position: relative;
			width: 100%;
			z-index: 2;
		}
	}

	.title {
		@include h-mod-block-title-xxl(400, 1.2, 0);
		color: color(white);
		display: block;
		max-width: 9em;

		@include media($small-only) {
			font-size: 2.5em;
		}
	}
}
