.card-color-simple {
	align-items: center;
	border-radius: 4px;
	display: flex;
	flex-flow: row wrap;
	height: 11.5em;
	justify-content: center;
	max-width: 17.5em;
	padding: 0 1em;
	text-align: center;

	@include media($to-large) {
		height: 9.375em;
	}

	.title {
		@include p-xxl;
		margin-bottom: .35em;
	}

	p {
		@include p-sm;
	}
}
