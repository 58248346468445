.-module-10b {
	display: flex;
	flex-flow: row wrap;

	> .block {
		background-color: color(white);
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		@include media($xlarge-up) {
			margin: {
				top: 1.25em;
				bottom: 1.25em;
			}
			padding: 4em 2em;
			width: calc(50% - 1.25em);

			&:nth-child(odd) {
				margin-right: 1.25em;
			}

			&:nth-child(even) {
				margin-left: 1.25em;
			}
		}

		@include media($large-only) {
			margin: .75em 0;
			padding: 3.5em 1.5em;
		}

		@include media($to-large) {
			border-bottom: 1px solid color(gray, lighter);
		}

		@include media($medium-only) {
			padding: 4em 2em;
		}

		@include media($small-only) {
			padding: 4em 1.5em;
		}

		> .brand {

			@include media($xlarge-up) {
				align-self: center;
				order: 3;
				max-width: 8em;
			}

			@include media($large-only) {
				align-items: flex-start;
				display: flex;
				margin-right: 5%;
				justify-content: center;
				padding-top: 2em;
				width: 30%;
			}

			@include media($to-large) {
				flex-flow: column nowrap;
				order: 3;
				padding-top: 1em;
				width: 100%;
			}

			img {
				max-height: 8.25em;
			}
		}

		> .quote-testimonial {

			@include media($xlarge-up) {
				order: 1;
				width: 100%;
			}

			@include media($large-only) {
				width: 65%;
			}

			@include media($to-large) {
				order: 1;
				width: 100%;
			}
		}

		> .footer-blockquote {

			@include media($xlarge-up) {
				order: 2;
			}

			@include media($large-only) {
				margin: 0 0 0 35%;
				padding-top: 2em;
			}

			@include media($to-large) {
				order: 2;
			}

			.citation {
				display: flex;
				flex-flow: column nowrap;

				@include media($xlarge-up) {
					max-width: 14em;
					padding-right: 1em;
				}
			}

			.title {
				display: block;
				padding-top: .5em;
			}
		}
	}
}
