.-module-03b {

	.icon {
		@include icon();

		@include media($to-large) {
			display: none;
			visibility: hidden;
		}
	}

	.module-visual {
		align-items: center;
		display: flex;
		justify-content: center;

		> .graphic {
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;

			> picture {
				display: block;
				margin: 0 auto;
				text-align: center;
				height: 100%;
				width: 100%;
			}
		}
	}

	// XXLarge-up
	@include media($xlarge-up) {
		padding-bottom: 4em;

		.module-content {
			max-width: 56em;
		}

		// Wrapping Container
		.module-presentation {
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			height: 33em;
			width: 100%;
		}

		.module-items {
			margin: 0 auto;
			max-width: 50em;
		}

		.module-item {
			padding: 2em 5.25em 0 5.25em;
			width: 100%;
		}

	}

	// Small - Large
	@include media($to-xlarge) {

		.module-items-outer {
			@include clearfix;
			background-color: color(gray, lighter);
			padding-top: 2em;
		}

		.module-visual {
			margin-bottom: 2em;
			width: 100%;
		}
	}

	// Large Only
	@include media($large-only) {

		// Visual/Image Area
		.module-visual {
			height: 32em;
		}
	}

	// Medium Only
	@include media($medium-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			height: 22em;
		}
	}

	// Small Only
	@include media($small-only) {
		padding: 3em 0 0 0;

		// Visual/Image Area
		.module-visual {
			height: 16em;
		}
	}
}
