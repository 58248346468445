.carousel-card-title-visual {
	margin: 0 auto;

	@include media($xxlarge-up) {
		height: 14.5em;
		max-width: 74.375em;
	}

	@include media($xlarge-only) {
		height: 11.5625em;
		max-width: 59.25em;
	}

	@include media($to-xxlarge) {
		height: 10.25em;
	}

	.cards {
		height: 100%;
	}

	.card.-title-visual {
		flex-flow: row nowrap;

		@include media($xxlarge-up) {
			margin: 0 .9em;
			width: 23.6em;
		}

		@include media($to-xxlarge) {
			height: 10.25em;
		}

		@include media($xlarge-only) {
			margin: 0 1em;
			width: 18.5em;
		}

		@include media($to-xlarge) {
			margin: 0 .5em;
			width: 16.875em;
		}
	}

	.flickity-prev-next-button {
		top: 50%;

		@include media($to-xlarge) {
			display: none;
		}

		svg {
			height: 1.5em;
			width: 2em;
		}

		.arrow {
			height: 1.5em;
			width: 2em;
			fill: color(gray);
		}

		&.previous {
			left: -4em;

			@include media($xlarge-only) {
				left: -3em;
			}
		}

		&.next {
			right: -3em;

			@include media($xlarge-only) {
				right: -2em;
			}
		}
	}
}
