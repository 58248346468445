.-module-04a {
	$bg-img-name: bg-module-04a;

	.module-content {
		background-color: color(gray, lightest);
		margin: 1em auto;
	}

	.icon {
		@include icon();
	}

	@include media($xlarge-up) {

		.module-content {
			max-width: 86em;
			overflow: hidden;
		}

		// Wrapping Container
		.module-presentation {
			display: flex;
			min-height: 50em; // Need a minimum height so we can have some footprint for images
		}

		.module-visual,
		.module-items-outer {
			min-height: 50em;
			width: 50%;
		}


		.module-items-outer {
			align-items: center;
			display: flex; // Vertically align content
			flex: 1;
			flex-direction: row;
			max-width: 36em;
		}

		// Module Items Lists
		.module-items {
		}

		picture.position {
			text-align: center;
			width: 100%;
		}

	}

	@include media($xxlarge-up) {

		.module-items-outer {
			padding-left: $gutter*2;
		}
	}

	// XLarge-only
	@include media($xlarge-only) {

		.module-items-outer {
			padding-left: $gutter*2;
		}

	}

	// Small - Large
	@include media($to-xlarge) {

		.module-content {
			background: linear-gradient(to bottom, color(gray, lightest) 0%, color(gray, lighter) 50%); // This will need to be a custom item in the CMS
		}

		.module-content-inner {
			position: relative;
			z-index: 3;
		}

		.module-items-outer {
			display: block; // Vertically align content
		}

		picture.position {
			height: 100%;
			position: relative;
			width: 100%;

			> img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
		}
	}

	// Large Only
	@include media($large-only) {

		// Visual/Image Area
		.module-visual,
		.graphic {
			height: 36em;
			overflow: hidden;
		}

		.module-items-outer {
			padding: 5em 0;
		}
	}

	// Medium Only
	@include media($medium-only) {

		// Visual/Image Area
		.module-visual,
		.graphic {
			height: 22em;
			overflow: hidden;
		}

		.module-items-outer {
			padding: 3em 0;
		}
	}

	// Small Only
	@include media($small-only) {

		// Visual/Image Area
		.module-visual,
		.graphic {
			height: 14em;
			overflow: hidden;
		}

		.module-items-outer {
			padding: 2em 0;
		}
	}
}
