$item-border: 1px solid color(gray, lighter);

.-module-06a {
	background: linear-gradient( color(gray, lightest) 0%, color(white) 100% );
	margin: 0 auto;
	max-width: 86em;

	&.-full {
		max-width: 100%;
	}

	&.-nobg {
		background: none;
	}

	.module-upper {

	}

	.icon {
		@include icon($module-icon-sm);
	}

	.module-intro {
		$intro-border: 2px solid color(gray, lighter);
		text-align: center;
		position: relative;

		&:after {
			border-bottom: $intro-border;
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			bottom: 0;
		}

		@include media($xxlarge-up) {
			margin: 2em auto 6em auto;
			max-width: 42em;

			&:after {
				margin-left: -18.75em;
				width: 37.5em;
			}
		}

		@include media($xlarge-up) {
			padding-top: 6em;
		}

		@include media($xlarge-only) {
			margin: 2em auto 6em auto;
			padding: 4em $gutter*2 5em $gutter*2;

			&:after {
				margin-left: -18.75em;
				width: 37.5em;
			}
		}

		@include media($large-only) {
			margin: 2em auto 6em auto;
			max-width: 40em;
			padding: 6em $gutter 5em $gutter;

			&:after {
				margin-left: -13.1875em;
				width: 26.375em;
			}
		}

		@include media($medium-only) {
			padding: 3em $gutter*2 5em $gutter*2;
			margin: 1em 0;

			&:after {
				margin-left: -9.375em;
				width: 18.75em;
			}
		}

		@include media($small-only) {
			padding: 2em $gutter 5em $gutter;
			margin: 1em 0;

			&:after {
				margin-left: -8.625em;
				width: 17.25em;
			}
		}
	}

	.module-items {
		@include clearfix;
		margin: 0 auto;

		@include media($xxlarge-up) {
			max-width: 70em;
		}


		@include media($large-up) {
			align-items: stretch;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			overflow: hidden; // Hide the fake horizontal lines
		}

		@include media($xlarge-only) {
			max-width: 62em;
		}

		@include media($large-only) {
			max-width: 46.375em;
			padding: 0 $gutter;
		}

		@include media($to-large) {
			max-width: 28em;
			padding: 0 $gutter;
		}
	}

	.module-item {
		position: relative;

		@include media($xlarge-up) {
			$margin: 3;
			margin: 4em 0;
			width: 33.3%;

			// No margin top for first 3 items
			&:nth-child(-n+3) {
				margin-top: 0;
			}

			// Every 1st item for a group of 3
			&:nth-child(3n+1) {
				padding-right: $margin+em;
			}

			// Every 2nd item for a group of 3
			&:nth-child(3n+2) {
				padding: 0 ($margin/2)+em;
				width: 33.3%;
			}

			// Every 3rd item for a group of 3
			&:nth-child(3n+3) {
				padding-left: $margin+em;
			}

			// Fake Horizontal Lines
			// Thiiiiiiiis is how we doooooo it
			// Every 1st item in groups of 3, create a psuedo element and make that a border
			&:nth-child(3n+1) {

				&:after {
					position: absolute;
					left: 0;
					top: -4em;
					border-top: $item-border;
					content: '';
					display: block;
					height: 0;
					width: 500%;
				}

				&:first-child {

					&:after {
						border-top: 0;
					}
				}
			}
		}

		@include media($large-only) {

			$margin: 2;
			margin: 4em 0;
			width: 50%;

			// No margin top for first 2 items
			&:nth-child(-n+2) {
				margin-top: 0;
			}

			// Every 1st item for a group of 3
			&:nth-child(2n+1) {
				padding-right: $margin+em;
			}

			// Every 3rd item for a group of 3
			&:nth-child(2n+2) {
				padding-left: $margin+em;
			}

			// Fake Horizontal Lines
			// Thiiiiiiiis is how we doooooo it
			// Every 1st item in groups of 3, create a psuedo element and make that a border
			&:nth-child(2n+1) {

				&:after {
					position: absolute;
					left: 0;
					top: -4em;
					border-top: $item-border;
					content: '';
					display: block;
					height: 0;
					width: 500%;
				}

				&:first-child {

					&:after {
						border-top: 0;
					}
				}
			}
		}

		@include media($medium-only) {
			padding: 2em 1em;
		}

		@include media($small-only) {
			padding: 1.5em 0;
		}

		// Set a margin for the icon
		.icon {
			display: block;
			margin-bottom: 1.5em;
		}
	}
}
