.copy-section-intro {
	position: relative;
	text-align: center;

	&.-left {
		text-align: left;
	}

	.title {
		@include h-content-h1
		margin-bottom: 1em;
	}

	.intro-block {
		@include copy-link();
		padding-bottom: 2em;

		p {
			@include p-xxl(1.4);
		}
	}

	.text {
		@include copy-link();

		p {
			@include p;

			&.large {
				@include p-l;
			}
		}
	}

	.tuck-aside {

		@include media($large-up) {
			max-width: 30em;
			position: absolute;
			top: -3em;
			right: 0;
		}

		@include media($to-large) {
			margin-bottom: 2em;
		}
	}
}
