.card-icon-link-small {
	border: 1px solid color(gray, light);
	border-radius: 8px;
	display: block;
	width: 100%;

	@include media($xlarge-up) {
		height: 10.3125em;
	}

	@include media($to-xlarge) {
		height: 6.25em;
	}

	&:hover {
		border-color: color(gray, mid);

		.icon svg {
			transform: scale(1.1);
		}
	}

	> .content {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		padding: .5em 1em;
		width: 100%;

		@include media($xlarge-up) {
			flex-flow: column nowrap;
			text-align: center;
		}

		@include media($to-xlarge) {
			flex-flow: row nowrap;
		}

		.upper,
		.lower {
			display: flex;
		}

		.upper {

			@include media($xlarge-up) {
				align-items: center;
				height: 50%;
			}

			@include media($to-xlarge) {
				width: 4em;
			}

		}

		.lower {
			flex-flow: row nowrap;
			max-width: 100%;

			@include media($xlarge-up) {
				align-items: flex-start;
				height: 50%;
			}

			@include media($to-xlarge) {
				width: calc(100% - 4em);
			}
		}

		.icon {
			height: 2.5em;
			width: 2.5em;

			svg {
				fill: color(sky, dark);
				height: 2.5em;
				width: 2.5em;
				transition: transform 300ms $ease-in-out-quad;
			}
		}

		p {
			@include p-sm(1.3);
		}
	}
}
