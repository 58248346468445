//  $$\      $$\           $$\
//  $$$\    $$$ |          \__|
//  $$$$\  $$$$ | $$$$$$\  $$\ $$$$$$$\
//  $$\$$\$$ $$ | \____$$\ $$ |$$  __$$\
//  $$ \$$$  $$ | $$$$$$$ |$$ |$$ |  $$ |
//  $$ |\$  /$$ |$$  __$$ |$$ |$$ |  $$ |
//  $$ | \_/ $$ |\$$$$$$$ |$$ |$$ |  $$ |
//  \__|     \__| \_______|\__|\__|  \__|
//
//
//

html {
	overflow-y: scroll;
	min-height: 100%;
}
// hide optanon whitespace ACC-48
@media only screen and (max-width: 47em){
	#optanon, 
	#optanon *, 
	#optanon div, 
	#optanon span, 
	#optanon ul, 
	#optanon li, 
	#optanon a, 
	#optanon p, 
	.optanon-alert-box-wrapper {
	    height: auto !important;
	}
}
