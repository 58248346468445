.-module-04c {

	.module-content {
		background-color: color(gray, lightest);
		margin: 1em auto;
	}

	.module-intro {
		width: 100%;
	}

	.icon {
		@include icon();
	}

	@include media($xlarge-up) {

		.module-content {
			max-width: 86em;
			overflow: hidden;
		}

		// Wrapping Container
		.module-presentation {

		}

		.module-items-outer {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		// Module Items Lists
		.module-items {
			padding-bottom: 2em; // Equal padding that intro sets at top
		}

		picture.position {
			width: 100%;
		}

	}

	@include media($xxlarge-up) {

		.module-visual,
		.module-items-outer {
			min-height: 52em;
		}

		.module-items-outer {
			margin: 0 auto;
			max-width: 34em;
			padding: 0 $gutter;
		}

		.module-presentation {
			display: flex;
			min-height: 52em; // Need a minimum height so we can have some footprint for images
		}

		.module-visual {
			width: 44em;
		}

		.module-items {

		}
	}

	// XLarge-only
	@include media($xlarge-only) {

		.module-visual,
		.module-items-outer {
			min-height: 58em;
		}

		.module-items-outer {
			margin: 0 auto;
			max-width: 32em;
			padding: 0 $gutter;
		}

		.module-presentation {
			display: flex;
			min-height: 58em; // Need a minimum height so we can have some footprint for images
		}

		.module-visual {
			width: 30em;
		}

		.module-items {

		}

	}

	// Small - Large
	@include media($to-xlarge) {

		.module-visual {
			display: block;
			width: 100%;
		}

		picture.position {
			position: relative !important;
		}

		img {
			min-width: 100%;
		}
	}

	// Large Only
	@include media($large-only) {

		.module-items-outer {
			margin: 0 auto;
			max-width: 38em;
			padding: 2em $gutter 5em $gutter;
		}
	}

	// Medium Only
	@include media($medium-only) {

		.module-items-outer {
			margin: 0 auto;
			max-width: 32em;
			padding: 2em $gutter 5em $gutter;
		}
	}

	// Small Only
	@include media($small-only) {

		.module-items-outer {
			margin: 0 auto;
			padding: 1em $gutter 3em $gutter;
		}
	}
}
