/* ==========================================================================
    CTA TOP
  ========================================================================== */
$_cta-colors: ( 
  blue: ( base: color( primary ), light: color( primary, light ), button: color( secondary ) ),
  cyan: ( base: #0194c7, light: lighten( #0194c7, 10% ), button: #5d0c8c ),
  green: ( base: #8bbd38, light: color( secondary ), button: #0194c7 ),
  purple: ( base: #5d0c8c, light: lighten( #5d0c8c, 10% ), button: color( secondary ) ),
);
.cta-top {
  padding-bottom: $gutter*2;
  padding-bottom: $gutter*0.75;
  padding-top: $gutter*0.75;
  position: relative;
}

// Colour Styles
// ==========================================================================
@each $_name, $_cta-color in $_cta-colors {
  .cta-top.-flat-#{$_name}-base {
    background-color: map-get($_cta-color, base);

    .button {
      background-color: map-get($_cta-color, button);
    }
  }
  
  .cta-top.-flat-#{$_name}-light {
    background-color: map-get($_cta-color, light);

    .button {
      background-color: map-get($_cta-color, button);
    }
  }
  
  .cta-top.-gradient-#{$_name} {
    background: linear-gradient(to bottom, map-get($_cta-color, light) 0%, map-get($_cta-color, base) 100%);

    .button {
      background-color: map-get($_cta-color, button);
    }
  }
}

.cta-top .-inner {
  margin: 0 auto;
  max-width: 86em;
  text-align: center;
}

.cta-top .container.-outer {
  max-width: 62em;
}

.cta-top .-heading {
  @include h-mod-h1;
  color: color( white );
  margin-bottom: .4em;
}

.cta-top .-subheading {
  color: color( white );
  font: {
    size: 1.6em;
  }
  margin: 0.3em 0 0.1em 0;
}

.cta-top .-summary {
  color: color( white );
  line-height: 1.4em;
}

// Button
// ==========================================================================
.cta-top .button {
  color: color( white );
  border-radius: 3px;
  margin-top: $gutter;
}

.cta-link {
	position: relative;
}

.cta-close {
	cursor: pointer;
	position: absolute;
	right: 1rem;
	top: 1rem;
	z-index: 1;
	&:before {
		content: '\2715';
		font-size: 1.4rem;
	}
}
