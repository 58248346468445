.table-general {
	$bg: color(gray, lightest);
	// @include tablesaw-stack( 50em );
	background-color: $bg;
	margin: 1.875em auto;

	table {
		display: block;
		overflow-x: auto;
	}

	// Default TH styles
	th {
		@include media($xlarge-up) {
			@include p-md;
		}
	}

	// Default TD styles
	td {
		@include media($to-xlarge) {
			@include p-sm;
		}
	}

	thead tr {

		th {
			font-weight: 600;
			padding: .65em 2em 1.25em 2em;
		}
	}

	// TBody
	tbody tr {
		td {
			vertical-align: bottom;
			padding: .65em 2em 1.25em 2em;
		}
	}
}
