.embed-video {
	display: block;
	padding: 3em 0;

	&:first-of-type {
		padding-top: 0;
	}

	&:last-of-type {
		padding-bottom: 0;
	}
}
