.article-preview {

	.title {
		@include h-content-h2;
		padding-bottom: .1em;

		> .title-link {

			&:hover {
				opacity: 0.6;
			}
		}
	}

	.preview-text {
		padding-top: 1.25em;

		p {
			@include p-sm(1.5);
		}
	}

	// Visual!
	&.-visual {

		.visual {
			align-items: center;
			background: color(gray, lightest);
			display: flex;
			height: 9.5em;
			padding: 1em;
			justify-content: center;


			&.-visual-stacked {
				margin-bottom: 2em;
				width: 100%;
			}

			&.-visual-inline {
				margin-right: 2em;
				width: 16em;
			}

			@include media($to-xlarge) {
				display: none;
			}
		}
	}

	// Inline!
	&.-inline {

		@include media($xlarge-up) {
			align-items: flex-start;
			display: flex;

			.content {
				width: calc(100% - 18em);
			}
		}
	}
}
