.layout-contact-content-v1 {
	position: relative;

	@include media($xxlarge-up) {
		display: flex;
	}

	.block {

		&.-b01 {

			@include media($xxlarge-up) {
				padding-right: $gutter;
				width: 35%;
			}

			@include media($to-xxlarge) {
				padding-bottom: 3em;
				width: 100%;
			}
		}

		&.-b02 {

			@include media($xxlarge-up) {
				width: 65%;
			}

			@include media($to-xxlarge) {
				width: 100%;
			}
		}
	}

	.button-everywhere {

		@include media($large-up) {
			position: absolute;
			bottom: 0;
			left: 0;
		}

		@include media($to-large) {
			display: none;
		}
	}
}

[data-ref="reCaptcha"] > div {
	float: right;
}
