.success-story {
    padding: rem(42) 0;
}

.success-story__inner {
    max-width: 86em;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 rem(26);

    @include media($large-up) {
        flex-direction: row;
        padding: 0 rem(56);
    }
}

.success-story__image {
    width: 100%;
    height: rem(340);
    display: flex;
    flex-direction: column;
    position: relative;

    @include media($large-up) {
        width: 48.5%;
        margin-bottom: 0;
        height: auto;
        padding-top: 48.5%;
    }

    &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: color(gray);
        opacity: .6;
        z-index: 1;
    }
}

.success-story__image-primary {
    object-fit: cover;
    height: 100%;
    width: 100%;

    @include media($large-up) {
        position: absolute;
        top: 0;
    }
}

.success-story__image-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: rem(338);
    z-index: 2;

    svg{
        width: 100%;
        fill: color(white);
        stroke: color(white);
        opacity: .5;
        & > *{
            fill: color(white);
            stroke: color(white);
            & > *{
                fill: color(white);
                stroke: color(white);
                *{
                    stroke: color(white);
                }
            }
        }
    }

    @include media($large-only) {
        padding: 0 rem(20);
    }
}

.success-story__content {
    width: 100%;
    padding: rem(42) 0 0;

    @include media($large-up) {
        width: 51.5%;
        background: color(gray, lightest);
        padding: rem(44) rem(32);
    }

    @include media($xlarge-up) {
        padding: rem(86) rem(32);
    }

    .title {
        font-size: rem(32);
        margin-bottom: rem(36);
        line-height: 1.3;
        letter-spacing: -.005em;

        @include media($large-up) {
            margin-bottom: rem(32);
        }
    }
}

.success-story__content-row {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(28);

    @include media($medium-up) {
        flex-direction: row;
    }

    @include media($large-up) {
        margin-bottom: rem(14);
    }
}

.success-story__content-col {
    @include media($medium-up) {
        width: 50%;

        &:nth-child(odd) {
            padding-right: rem(12);
        }

        &:nth-child(even) {
            padding-left: rem(36);
        }
    }
}

.success-story__content-col-text {
    * {
        font-size: rem(18);
        margin-bottom: rem(16);
        line-height: 1.6;

        @include media($large-up) {
            font-size: rem(14);
            margin-bottom: rem(12);
            line-height: 1.75;
        }
    }
}

.success-story__content-col-heading {
    font-weight: $header-font-weight-heavy;
    font-size: rem(16);
    margin-bottom: rem(20);

    @include media($large-up) {
        font-size: rem(12);
        margin-bottom: rem(16);
    }

    @include caps(0);
}
