.-module-01c {
	$bg-img: 'bg-module-01c';

	@include bg-default;
	margin: 0 auto;
	max-width: 86em;

	// --------------------------------------
	// Moudle 01a Layouts & Styling
	// --------------------------------------
	//

	.icon {
		@include icon();
	}

	.module-visual {
		background: url(#{$bg-img-path}#{$bg-img}.png) center bottom no-repeat;
		height: 30.5em;
		overflow: hidden;

		// 2x
		@include image-2x(url(#{$bg-img-path}#{$bg-img}@2x.png) center bottom no-repeat, 420px, 487px);

		> .graphic {
			width: 170px;
			height: 170px;
		}
	}

	// XXLarge-up
	@include media($xxlarge-up) {

		.module-content {
			margin: 0 auto;
			max-width: 80em;
		}

		// Wrapping Container
		.module-presentation {
			@include clearfix;
			min-height: 32em;
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			@include position( absolute, 0px null null 50% );
			margin-left: (-26.25/2)+em;
			width: 26.25em;

			// Position the graphic(s)
			> .graphic {
				top: 90px;
				left: 128px;
			}
		}

		.module-items-outer {
			columns: 2;
			column-gap: 28em;
			vertical-align: top;
		}

		// Module Items Lists
		.module-items {
			 max-width: 26em;
			 padding: 0 $gutter/2;
		}

		// Module Item
		.module-item {
			break-inside: avoid;
			margin-bottom: 0;
			min-height: 10em;
		}
	}

	// XLarge-only
	@include media($xlarge-only) {

		.module-content {
			max-width: 68em;
		}

		// Wrapping Container
		.module-presentation {
			min-height: 60em;
			padding: 0 0 1em 32em;
			position: relative;
		}

		// Visual/Image Area
		.module-visual {
			@include position( absolute, 0 null null 0 );
			width: 29.125em;

			// Position the graphic(s)
			> .graphic {
				top: 90px;
				left: 150px;
			}
		}

		// Module Items Lists
		.module-items {
			//max-width: 36em;
			padding-right: $gutter;
		}

		// Module Item
		.moudle-item {
			margin-bottom: 1em; // Tighter margin
		}
	}

	// Small - Large
	@include media($to-xlarge) {

		.module-content {
			display: flex;
	  		flex-direction: column;
		}

		.module-visual {
			background-position: center top;
			height: 36.5em;
			position: relative;

			// Position the graphic(s)
			> .graphic {
				position: absolute;
				top: 88px;
				left: 50%;
				margin-left: -83px;
				width: 170px;
				height: 170px;
			}
		}

		.module-intro {
			//order: 2;
		}

		.module-presentation {
		//	order: 1;
		}

		.module-items-outer {
			background-color: color(gray, lighter);
			padding: 2em 0;
		}
	}

	// Large Only
	@include media($large-only) {
		padding: 3em 0 0 0;
	}

	// Medium Only
	@include media($medium-only) {
		padding: 3em 0 0 0;
	}

	// Small Only
	@include media($small-only) {
		padding: 3em 0 0 0;
	}
}
