.link-list {

	.title {
		color: color(secondary);
		font: {
			weight: $header-font-weight;
		}
		margin-bottom: 1em;
	}

	.items {

		> .item {
			@include p-sm;

			> .link {
				display: block;
				padding: .25em 0;

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	.overflow {
		display: none;
		height: 0;
		opacity: 0;
		transition: all 300ms $ease-in-quad;
		visibility: hidden;
	}

	.trigger {
		display: block;
		font-size: 0.9em;
		padding: 1em 0;
		text-decoration: underline;
	}

	.checkbox-toggle {

		&:checked {

			~ .overflow {
				display: block;
				height: inherit;
				opacity: 1;
				visibility: visible;
			}

			// Hide the "Learn More" link when content is open
			~ [data-nav="copy-overflow-more"] {
				display: none;
			}
		}
	}
}
