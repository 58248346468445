.-module-09a {

	@include media($xlarge-up) {
		//padding: 1em;
	}

	> .module-content {
		background-repeat: no-repeat;
		background-color: color(gray, lighter);
		position: relative;
		height: 100%;
		width: 100%;
		z-index: 2;

		@include media($xxlarge-up) {

		}

		@include media($xlarge-up) {
			background-position: center top;
			background-size: cover;
			height: 35.375em;
			margin: 0 auto;
			//max-width: 86em;

		}

		@include media($to-xlarge) {
			background-position: center bottom;
			background-size: contain;
		}

		@include media($large-only) {
			height: 54.5em;
		}

		@include media($medium-only) {
			padding-bottom: 60%;
		}

		@include media($small-only) {
			padding-bottom: 60%;
		}

		> .container {
			display: flex;
			height: 100%;
			justify-content: flex-end;
		}

		.module-item {

			@include media($xlarge-up) {
				margin: 5em 0 1em 1em;
				max-width: 36em;
			}

			@include media($large-only) {
				margin: 4em auto 1em auto;
				max-width: 40em;
			}

			@include media($medium-only) {
				margin: 4em auto 1em auto;
				max-width: 40em;
				padding: 0 $gutter;
			}

			@include media($small-only) {
				margin: 2em auto 1em auto;
				padding: 0 $gutter;
			}
		}
	}

	.subtitle {
		@include h-mod-subitle-strong;
	}

	.title {
		@include h-mod-block-title-xl(300, 1.2, 0.5em);
		display: block;

		@include media($small-only) {
			font-size: 2.5em;
		}
	}

	p {
		@include p-md;
	}
}
