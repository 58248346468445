
// Footer for Blockquote
.footer-blockquote {
	margin-top: 1.75em;

	> .citation {

		> .name {
			@include p-l();
			color: color(secondary);
			display: block;
			font: {
				weight: $header-font-weight;
			}
			line-height: 1.5;
			padding-bottom: .65em;
		}

		> .company,
		> .title {
			line-height: 1.4;
		}
	}

	&.-alt {

		> .citation {

			> .name {
				color: color(sky, dark);
			}
		}
	}
}
