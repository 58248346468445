.article-byline {
	display: block;

	p {
		@include p-xsm(1.5);
	}

	.category-link {
		color: color(secondary);
		display: inline-block;

		&:hover {
			text-decoration: underline;
		}
	}
}
