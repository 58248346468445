// Global Module Styles

// Gradient Backgrounds-----------
$bg-default: linear-gradient(color(white), color(gray, lightest));

@mixin bg-default {
	background-image: $bg-default;
}
//---------------------------------
// Icons-----------
$icon-size: 5em; //80px/16px
$icon-size-small: 4.375em;

//---------------------------------

.module-content {
	margin: 0 auto;
}

.module-content a:not(.button) {
	color: #94C83D;
	// Some links have been wrapped in <strong> tags, others have not, with no apparent rhyme or reason. For the sake of
	// consistency, just make everything bold.
	font-weight: bold;
	text-decoration: underline;
}

.module-intro {
	margin: 0 auto;
	max-width: 60em;

	> .title {
		@include h-mod-h1;
	}

	> .subtitle {
		@include h-mod-subtitle;

		&.-strong {
			@include h-mod-subitle-strong;
		}
	}

	p {
		@include p-md;
	}

	&.-default {
		text-align: center;
		padding: 7em $gutter*2;

		@include media($large-only) {
			padding: 6em $gutter*2;
		}

		@include media($to-large) {
			padding: 5em $gutter;
		}
	}

	&.-secondary {
		padding: 5.25em 0;

		@include media($xlarge-up) {
			margin: 0;
			padding-left: 4em;
		}

		@include media($to-xlarge) {
			text-align: center;
		}

		@include media($to-large) {
			padding: 3.5em 1em;
		}
	}

	&.-floater {
		position: absolute;
		z-index: 2;

		@include media($xlarge-up) {
			margin: 0;
			padding: 4em 0 0 4em;
		}

		@include media($to-xlarge) {
			display: none;
		}
	}

	// When the intro lives with the content
	&.-content {
		padding: 3em 0;

		p {
			@include p-sm;
		}
	}
}

// Module Visual
.module-visual {
	position: relative;

	> .graphic {
		position: relative;

		// Used for screenshot pieces that need to hide overflow
		&.-screenshot {
			overflow: hidden;
		}

		> picture {

			// Absolute Positioning
			&.position {
				position: absolute;
			}

			// Hide by default, module-block-select.js handles visibility
			&.is-hidden {
				opacity: 0;
				transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
				visibility: hidden;
			}

			&.is-visible {
				opacity: 1;
				transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
				visibility: visible;
			}
		}
	}

	.caption {
		@include p-l;
	}
}

// Module Item List
.module-items {

}

// Module Item Block
.module-item {
	list-style: none;

	.link {
		transition: opacity 0.3s ease-in-out;

		&.active {

			.title {
				font: {
					weight: $header-font-weight;
				}
			}

			&:hover {
				opacity: 1;
			}
		}

		&:hover {

			@include media($xlarge-up) {
				opacity: 0.7;
			}
		}
	}

	.title {
		@include h-mod-block-title;

		// Option to add heavier title weight for non-flickity blocks that don't have active classes
		&.-strong {
			@include h-mod-block-title($header-font-weight);
		}

		&.-xl {
			@include h-mod-block-title-xl;
		}
	}

	p {
		@include p-sm;
	}

	.icon {

		> svg {
			display: block;
			& > *{
				fill: color(gray);
			}
		}
	}

	// Icon inline
	&.-inline {

		@include media($to-xlarge) {
			width: 100%;
		}

		.link {
			@include clearfix;
      color: inherit;
			display: block;
			position: relative;
			text-decoration: none;
			width: 100%;

			@include media($to-xlarge) {
				cursor: inherit; // Inherit "drag" cursor from Flickity styling when enabled
			}
		}

		.content {
			margin: 0 auto;
			width: 100%;
		}
	}

	&.-inline-primary {

		@include media($xlarge-up) {
			margin-bottom: 1.75em;
		}

		.icon {

			@include media($xlarge-up) {
				@include position( absolute, 0 null null 0px );
				margin: 0 auto 1.5em auto;
			}

			@include media($to-xlarge) {
				margin: 0 auto 1.5em auto;
			}
		}

		.content {

			@include media($xlarge-up) {
				padding: .5em 0 0 ($icon-size + $gutter/2);
			}

			@include media($to-xlarge) {
				margin: 0 auto;
				text-align: center;
			}
		}

		.link {

			@include media($to-xlarge) {
				margin: 0 auto;
				max-width: 34em;
			}

			@include media($to-large) {
				padding: {
					left: 3em;
					right: 3em;
				}
			}
		}
	}

	&.-inline-secondary {

		@include media($xlarge-up) {
			margin-bottom: 1.75em;
		}

		.icon {

			@include media($large-up) {
				@include position( absolute, 0 null null 0px );
				margin-top: 1.5em;
			}

			@include media($to-large) {
				margin: 1.5em auto;
			}
		}

		.content {

			@include media($large-up) {
				padding: .5em 0 0 ($icon-size + $gutter/2);
			}

			@include media($to-large) {
				text-align: center;
			}
		}

		.link {
      color: inherit;
			padding: 0 $gutter;
			margin: 0 auto;

			@include media($xlarge-up) {
				max-width: 40em;
			}

			@include media($large-only) {
				max-width: 34em;
			}

			@include media($medium-only) {
				max-width: 34em;
				padding: {
					left: 3em;
					right: 3em;
				}
			}

			@include media($small-only) {
				padding: {
					left: 3em;
					right: 3em;
				}
			}
		}
	}

	// Used when blocks are not in carousel
	&.-inline-stacked {
		margin-bottom: 1.75em;
		position: relative;

		.icon {
			@include position( absolute, 0 null null 0px );
			margin: 0 auto 1.5em auto;
		}

		.content {
			padding: .5em 0 0 ($icon-size + $gutter/2);
		}
	}
}

.module-items {
	@include clearfix;

	// Hide Pagination by default
	.flickity-page-dots {
		display: none;
	}

	// Show Pagination
	&.-pagination.flickity-enabled {
		padding-bottom: 5em;

		.flickity-page-dots {
			bottom: 2em;
			display: block;
		}

		.flickity-prev-next-button {
			margin-top: -1em;
			top: 50%;
		}
	}

	// Disable/Enable Flickity
	&:after {

		// Disable Flickity
		@include media($xlarge-up) {
			content: '';
		}

		// Enable Flickity
		@include media($to-xlarge) {
			content: 'flickity';
			display: none;
		}
	}
}
