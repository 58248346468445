.cta-press {
	background: linear-gradient(180deg, color(primary, light) 0%, color(primary) 100%);
	color: color(white);

	@include media($large-up) {
		align-items: center;
		border-radius: 5px;
		display: flex;
		height: 5.625em;
		justify-content: center;
	}

	@include media($to-large) {
		text-align: center;
		padding: 2em $gutter;
	}

	a {
		color: color(secondary);
		font: {
			weight: $header-font-weight-heavy;
		}
		text-decoration: underline;

		&:hover {
			color: color(secondary);
			opacity: 0.7;
		}
	}

	p {
		@include p;
	}

	.header {
		text-align: center;

		@include media($large-up) {
			margin-right: 2em;
		}

		@include media($to-large) {
			padding-bottom: 1em;
		}
	}

	.icon {
		display: block;
		margin: 0 auto .35em auto;
		fill: color(secondary);
		height: 1.75em;
		width: 2.375em;
	}

	.title {
		font: {
			weight: $header-font-weight;
		}
	}

}
