/**
 * Change font properties to `inherit` in all browsers (opinionated).
 */

button,
input,
select,
textarea {
  font: inherit;
}
