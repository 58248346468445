.copy-callout {
    background-color: color(gray, lightest);
    padding: 2em;

    &.-tight {
        padding: 1em 2em;
    }

    > .title {
        @include h-content-h3;
        color: color(primary);
        margin-bottom: .65em;
    }

    p {
        @include p-md;
    }

    iframe {
        border: none;
        opacity: .6;
    }
}
