/**
 * Correct the odd appearance of search inputs in Chrome and Safari.
 */

[type="search"] {
  -webkit-appearance: textfield;
}

/**
 * Remove the inner padding and cancel buttons in Chrome on OS X and
 * Safari on OS X.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
