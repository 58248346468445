//  $$\       $$\ $$\
//  $$ |      \__|$$ |
//  $$ |      $$\ $$$$$$$\   $$$$$$$\
//  $$ |      $$ |$$  __$$\ $$  _____|
//  $$ |      $$ |$$ |  $$ |\$$$$$$\
//  $$ |      $$ |$$ |  $$ | \____$$\
//  $$$$$$$$\ $$ |$$$$$$$  |$$$$$$$  |
//  \________|\__|\_______/ \_______/
//
//
//

@import "../../bower_components/modularized-normalize-scss/_normalize"; // Normalize
@import "../../bower_components/bourbon/core/_bourbon"; // Bourbon
@import "base/grid-settings"; // Grid Override
@import "../../bower_components/neat/app/assets/stylesheets/neat"; // Neat with Bourbon
// @import "../../bower_components/tablesaw/dist/stackonly/tablesaw.stackonly.scss"; // Tablesaw

//  $$$$$$$\             $$$$$$\                     $$\   $$\
//  $$  __$$\           $$  __$$\                    $$ |  $$ |
//  $$ |  $$ | $$$$$$\  $$ /  \__|$$$$$$\  $$\   $$\ $$ |$$$$$$\    $$$$$$$\
//  $$ |  $$ |$$  __$$\ $$$$\     \____$$\ $$ |  $$ |$$ |\_$$  _|  $$  _____|
//  $$ |  $$ |$$$$$$$$ |$$  _|    $$$$$$$ |$$ |  $$ |$$ |  $$ |    \$$$$$$\
//  $$ |  $$ |$$   ____|$$ |     $$  __$$ |$$ |  $$ |$$ |  $$ |$$\  \____$$\
//  $$$$$$$  |\$$$$$$$\ $$ |     \$$$$$$$ |\$$$$$$  |$$ |  \$$$$  |$$$$$$$  |
//  \_______/  \_______|\__|      \_______| \______/ \__|   \____/ \_______/
//
//
//

@import "defaults"; // Deafults
@import "generic/mixins"; // Mixins
@import "generic/debug"; // Debug mode. Toggle true/false in defaults.scss

//  $$$$$$$\
//  $$  __$$\
//  $$ |  $$ | $$$$$$\   $$$$$$$\  $$$$$$\
//  $$$$$$$\ | \____$$\ $$  _____|$$  __$$\
//  $$  __$$\  $$$$$$$ |\$$$$$$\  $$$$$$$$ |
//  $$ |  $$ |$$  __$$ | \____$$\ $$   ____|
//  $$$$$$$  |\$$$$$$$ |$$$$$$$  |\$$$$$$$\
//  \_______/  \_______|\_______/  \_______|
//
//
//

@import "base/animations";
@import "base/forms";
@import "base/images";
@import "base/layout";
@import "base/links";
@import "base/main";
@import "base/typography";
@import "base/tables";

//   $$$$$$\  $$\                                 $$\
//  $$  __$$\ $$ |                                $$ |
//  $$ /  $$ |$$$$$$$\  $$\  $$$$$$\   $$$$$$$\ $$$$$$\    $$$$$$$\
//  $$ |  $$ |$$  __$$\ \__|$$  __$$\ $$  _____|\_$$  _|  $$  _____|
//  $$ |  $$ |$$ |  $$ |$$\ $$$$$$$$ |$$ /        $$ |    \$$$$$$\
//  $$ |  $$ |$$ |  $$ |$$ |$$   ____|$$ |        $$ |$$\  \____$$\
//   $$$$$$  |$$$$$$$  |$$ |\$$$$$$$\ \$$$$$$$\   \$$$$  |$$$$$$$  |
//   \______/ \_______/ $$ | \_______| \_______|   \____/ \_______/
//                $$\   $$ |
//                \$$$$$$  |
//                 \______/

@import "objects/accordions/accordions";
@import "objects/articles/articles";
@import "objects/blockquotes";
@import "objects/buttons";
@import "objects/carousels/carousels";
@import "objects/cards/cards"; // Card Objects
@import "objects/copy/copy"; // Copy Blocks/Type
@import "objects/cta/cta"; // Calls to Action
@import "objects/flash-messages/flash-messages";
@import "objects/footers/footers"; // Footers
@import "objects/headers/headers"; // All the headers
@import "objects/icons";
@import "objects/links/links"; // Lists
@import "objects/lists/lists"; // Lists
@import "objects/logos/logos";
@import "objects/media/media"; // Media like video embeds, images, etc.
@import "objects/modules/modules"; // All the Module Goodness
@import "objects/navigations/navigations"; // Navigations
@import "objects/photo-grids/photo-grids";
@import "objects/tables/tables"; // Custom Table styles
@import "objects/iframe";
@import "objects/heroes/heroes"; // homepage hero

//  $$\                                                $$\
//  $$ |                                               $$ |
//  $$ |      $$$$$$\  $$\   $$\  $$$$$$\  $$\   $$\ $$$$$$\    $$$$$$$\
//  $$ |      \____$$\ $$ |  $$ |$$  __$$\ $$ |  $$ |\_$$  _|  $$  _____|
//  $$ |      $$$$$$$ |$$ |  $$ |$$ /  $$ |$$ |  $$ |  $$ |    \$$$$$$\
//  $$ |     $$  __$$ |$$ |  $$ |$$ |  $$ |$$ |  $$ |  $$ |$$\  \____$$\
//  $$$$$$$$\\$$$$$$$ |\$$$$$$$ |\$$$$$$  |\$$$$$$  |  \$$$$  |$$$$$$$  |
//  \________|\_______| \____$$ | \______/  \______/    \____/ \_______/
//                     $$\   $$ |
//                     \$$$$$$  |
//                      \______/

@import "layouts/layouts";

//  $$$$$$$\  $$\                     $$\
//  $$  __$$\ $$ |                    \__|
//  $$ |  $$ |$$ |$$\   $$\  $$$$$$\  $$\ $$$$$$$\   $$$$$$$\
//  $$$$$$$  |$$ |$$ |  $$ |$$  __$$\ $$ |$$  __$$\ $$  _____|
//  $$  ____/ $$ |$$ |  $$ |$$ /  $$ |$$ |$$ |  $$ |\$$$$$$\
//  $$ |      $$ |$$ |  $$ |$$ |  $$ |$$ |$$ |  $$ | \____$$\
//  $$ |      $$ |\$$$$$$  |\$$$$$$$ |$$ |$$ |  $$ |$$$$$$$  |
//  \__|      \__| \______/  \____$$ |\__|\__|  \__|\_______/
//                          $$\   $$ |
//                          \$$$$$$  |
//                           \______/

// Include any 3rd-party plugin styling
@import "plugins/flickity";
@import "plugins/flickity-fade";
@import "plugins/magnific-popup";

//   $$$$$$\  $$\           $$\                 $$\
//  $$  __$$\ $$ |          $$ |                $$ |
//  $$ /  \__|$$ | $$$$$$\  $$$$$$$\   $$$$$$\  $$ |
//  $$ |$$$$\ $$ |$$  __$$\ $$  __$$\  \____$$\ $$ |
//  $$ |\_$$ |$$ |$$ /  $$ |$$ |  $$ | $$$$$$$ |$$ |
//  $$ |  $$ |$$ |$$ |  $$ |$$ |  $$ |$$  __$$ |$$ |
//  \$$$$$$  |$$ |\$$$$$$  |$$$$$$$  |\$$$$$$$ |$$ |
//   \______/ \__| \______/ \_______/  \_______|\__|
//
//
//

html {
	box-sizing: border-box;
	font-size: 100%;
	line-height: 1;
	min-height: 100%;
	overflow-y: scroll;
	-webkit-font-smoothing: antialiased;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

// Border Box all the things!
*,
*:before,
*:after {
	box-sizing: inherit;
}

*:focus {
	outline: 0;
	outline-style: none !important;
}

body {
	font-feature-settings: "liga", "kern", "pnum", "case";
	color: $default-body-color;
	font-family: $base-font-family;
    overflow-x: hidden;
}

a {
	color: $default-link-color;
	transition: all 0.2s ease-in-out;

	&:hover {
		color: $default-link-color-hover;
	}
}
