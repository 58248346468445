/**
 * Remove the margin in Safari.
 * 1. Remove the margin in Firefox and Safari.
 */

button,
input,
select,
textarea { /* 1 */
  margin: 0;
}
