.cta-solution {
	background-color: color(white, bone);
	background-position: center right;
	background-repeat: no-repeat;
	background-size: 50% auto;
	width: 100%;

	.link {
		align-items: center;
		display: flex;
		height: 100%;
		width: 100%;

		@include media($xlarge-up) {
			justify-content: flex-start;
		}

		@include media($to-xlarge) {
			justify-content: center;
		}

		@include media($xxlarge-up) {
			height: 21em;
		}

		@include media($xlarge-only) {
			height: 16.675em;
		}

		&:hover {

			.logo {
				opacity: 0.7;
				transform: translateY(-3px);
			}
		}
	}

	.container {
		text-align: center;

		@include media($xlarge-up) {
			width: 50%;
		}

		@include media($to-xlarge) {
			padding: $gutter*1.5 $gutter;
		}
	}

	.content {
		margin: 0 auto;

		@include media($xxlarge-up) {
			max-width: 40em;
		}

		@include media($xlarge-only) {
			max-width: 30em;
			padding-left: $gutter*2;
		}

		@include media($to-xlarge) {
			padding: $gutter*1.5 $gutter;
		}

	}

	.title {
		@include p-l;
		color: color(sky, dark);
		margin-bottom: 2em;
	}

	.logo {
		transition: opacity 300ms $ease-in-out-quad, transform 300ms $ease-in-out-quad;

		@include media($to-xxlarge) {
			max-height: 6.75em;
		}
	}
}
