// redesign link styles
.redesign-text-link {
    color: color(primary);
    text-decoration: underline;
    line-height: 1.5;
    font-size: rem(18);
    display: inline-block;
    margin-bottom: rem(10);

    @include media($large-up) {
        font-size: rem(16);
    }
}

