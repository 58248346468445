/*! Flickity v2.0.2
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
	background: none;
	display: block;
	position: absolute;
	top: 60%;
	width: 44px;
	height: 44px;
	border: none;
	cursor: pointer;
	/* vertically center */
	-webkit-transform: translateY(-50%);
	      transform: translateY(-50%);
}

.flickity-prev-next-button:focus {
	outline: none;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
	left: 0px;

	@include media($large-only) {
		left: 5%;
	}
}

.flickity-prev-next-button.next {
	right: 0px;

	@include media($large-only) {
		right: 5%;
	}
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 0;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 0;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 30%;
  top: 30%;
  width: 40%;
  height: 40%;
}

.flickity-prev-next-button .arrow {
  fill: color(gray, light);

  &:hover {
	  opacity: 0.8;
  }
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
	border: 2px solid color(gray, light);
	display: inline-block;
	width: 9px;
	height: 9px;
	margin: 0 6px;
	border-radius: 50%;
	cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
	background-color: color(gray, light);
}
