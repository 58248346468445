// Show/Hide content because sure
.copy-overflow {

	// The checkbox hack
	.checkbox-toggle {

		&:checked {

			~ .content-overflow {
				display: block;
				height: inherit;
				opacity: 1;
				transition: all 300ms $ease-in-quad;
				visibility: visible;
			}

			// Hide the "Learn More" link when content is open
			~ [data-nav="copy-overflow-more"] {
				display: none;
			}
		}
	}

	.trigger {
		color: color(sky, dark);
		display: block;
		text-align: center;
		text-decoration: underline;

		@include media($large-up) {
			display: none;
		}

		&[data-nav="copy-overflow-less"] {
			padding-top: 1em;
		}
	}

	.content-overflow {

		@include media($to-large) {
			display: none;
			height: 0;
			opacity: 0;
			transition: all 300ms $ease-out-quad;
			visibility: hidden;
		}
	}
}
