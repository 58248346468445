.flash-form-success {
	padding: 5em 1em;
	text-align: center;
	width: 100%;

	.title {
		@include h-content-h2;
		color: color(primary);
	}

	.action {
		margin-top: 2em;
	}
}
