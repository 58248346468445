.layout-contact-main {
	background-color: color(white);
	border-radius: 8px;

	@include media($xxlarge-up) {
		padding: 3.75em;
	}

	@include media($xlarge-only) {
		padding: 3em;
	}

	@include media($large-only) {
		padding: 3em 2em;
	}

	@include media($to-large) {
		padding: 3em $gutter;
	}
}
