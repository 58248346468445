//  $$\      $$\           $$\       $$\ $$\
//  $$$\    $$$ |          $$ |      \__|$$ |
//  $$$$\  $$$$ | $$$$$$\  $$$$$$$\  $$\ $$ | $$$$$$\
//  $$\$$\$$ $$ |$$  __$$\ $$  __$$\ $$ |$$ |$$  __$$\
//  $$ \$$$  $$ |$$ /  $$ |$$ |  $$ |$$ |$$ |$$$$$$$$ |
//  $$ |\$  /$$ |$$ |  $$ |$$ |  $$ |$$ |$$ |$$   ____|
//  $$ | \_/ $$ |\$$$$$$  |$$$$$$$  |$$ |$$ |\$$$$$$$\
//  \__|     \__| \______/ \_______/ \__|\__| \_______|
//
//
//

.nav-main {

	&.-mobile {

		@include media($to-xlarge) {
			@include z-index(priority3);
			background: color(primary);
			height: 100%;
			padding-top: $header-height-mobile;
			position: fixed;
			right: 0;
			top: 0;
			transform: translateX(100%);
			transition: transform 400ms $ease-out-sine;
			width: 100%;
			max-width: 500px;
			will-change: transform;
		}
		.nav-dropdown_content{
			display: none;
			background-color: color(gray, lighter);
			padding: 15px;
		}
		.nav-dropdown{
			&.open{
				.nav-dropdown_content{
					display: block;
				}
				.nav-link{
					background-color: #f8f8f8;
					color: color(primary) !important;
				}
			}
		}
		.markets{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.card{
				width: 31%;
				margin: 1%;
				height: 114px;
				min-width: unset;
				.title{
					font-size: 1.1em;
				}
			}
			
		}

		.dropdown-subnavigation{
			.tab-block{
				padding: 23px;
				margin-top: 5px;
				& > .tab{
					display: none;
				}
				&.active{
					background-color: #f8f8f8;
					& > .tab{
						display: block;
					}
				}
			}
			.tab-navigation{
				.tab-title{
					color: color(primary);
					font-size: 1.75em;
    				line-height: 1.875em;
				}
				
			}
			.tab-main{
				.button{
					margin: 22px 0;
				}
				.tab-tags{
					margin-bottom: 20px;
					display: flex;
					flex-direction: row;
					align-items: center;
					span{
						width: 20%;
					}
					ul{
						width: 80%;
						li{
							display: inline-block;
						}
					}
				}
			}
			.tab-sidebar{
				background-color: color(sky, lighter);
				padding: 26px;
				.story-row{
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-bottom: 10px;
					justify-content: space-between;
					.logo{
						width: 50%;
						text-align: right;
					}
					svg{
						width: 35%;
						fill: color(gray, dark);
						stroke: color(gray, dark);
						opacity: .5;
						& > *{
							fill: color(gray, dark);
							stroke: color(gray, dark);
							& > *{
								fill: color(gray, dark);
								stroke: color(gray, dark);
								*{
									stroke: color(gray, dark);
								}
							}
						}
					}
					h5{
						width: 65%;
					}
				}
				.story-bottom{
					margin: 20px 0;
				}
			}
		}

		.nav-block-utility{
			display: flex;
			flex-direction: column;
			padding: 30px 15px;

			.nav-link{
				margin-bottom: 20px;
				font-size: 1.1em;
				padding-left: 1em;
			}

			.language-switcher{
				& > .trigger {
					display: block;
					height: 100%;
					width: 100%;
					font-size: 1.25em;

					&:active {
						background-color: color(white);
						box-shadow: inset 0 1px 1px rgba( color(gray), 0.3 );
					}

					> .icon {
						border-right: 1px solid color(white);
						display: inline-block;
						fill: color(white);
						height: 1.1em;
						margin-right: .35em;
						margin-left: 0;
						vertical-align: middle;
						width: 2.8em;
					}

					> .text {
						border-bottom: 1px solid rgba(color(white), 0.6);
						color: color(white);
						font-size: 1.1rem;
					}
				}

				&.open{
					ul.items{
						position: relative;
						background-color: color(primary);
						li a{
							color: color(white);
						}
					}
				}
			}
			.nav-link{
				color: color(white);
			}
		}

		@include media($xlarge-up) {
			display: none;
		}

		.nav-content {
			align-items: stretch;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			height: 100%;
			position: relative;
		}

		// The parent container for links (probably an <ul>)
		.nav-items {
			// height: calc(100vh - 6em);
			overflow-y: scroll;
			width: 100%;
			-webkit-overflow-scrolling: touch;

			// Item container (probably a <li>)
			 .nav-item {
				border-bottom: 1px solid color(gray, lighter);
				overflow: hidden;

				// Item link element - should be block level
				 .nav-link {
					color: color(white);
					display: block;
					font-weight: $header-font-weight;
					font-size: 1.75em;
					padding: .85em 1.25em;
					width: 100%;

					> .text {
						display: inline-block;
						padding: 0.35em 0;
					}

					> .icon-arrow {
						display: inline-block;
						fill: color(gray, light);
						margin-left: .2em;
						transition: transform 0.5s $ease-in-out-sine;
						width: 5px;
						height: 10px;
					}

					// When parent item is open
					&.-is-open {

						 .icon-arrow {
							 transform: rotate(90deg);
							 transform-origin: center center;
						 }
					}

					// Show/Hide checkbox hack
					&[data-nav="showhide"] {
						cursor: pointer;
						position: relative;

						> .icon-plus {
							position: absolute;
							right: 1em;
							top: 50%;
						}
					}
				}

				&.-is-active {

					> .nav-link {

						&:hover {
							opacity: 1;
						}

						> .text {
							color: color(white);
							font: {
								weight: 600;
							}
							border-bottom: 3px solid color(white);
						}
					}
				}

				// Optical padding for link item(s) that have an arrow
				&.-has-children {

				}
			}
		}

		.nav-children-toggle:checked ~ .nav-items-children {
			height: auto;
			opacity: 1;
			transition: opacity 200ms $ease-in-sine, visibility 500ms $ease-in-sine;
			visibility: visible;
		}

		.nav-items-children {
			background-color: color(white);
			height: 0;
			opacity: 0;
			overflow: hidden;
			transition: opacity 200ms $ease-out-sine, visibility 300ms $ease-out-sine;
			visibility: hidden;
			will-change: height;

			> .nav-item {

				&:not(:last-child) {
					border-bottom: 1px solid color(gray, lighter);
				}

				&:first-child {
					border-top: 1px solid color(gray, lighter);
				}

				&.-secondary {
					border: none;
					font-size: 0.9em;
					padding-left: .75em;

					> .link {
						padding: 1em 1.25em;
					}
				}

				&.-is-active {

					> .link {
						color: color(secondary);
						font: {
							weight: $header-font-weight;
						}
						text-decoration: underline;
					}
				}

				> .link,
				> .nav-link {
					display: block;
					padding: .85em 1.25em;
					position: relative;
					width: 100%;
				}

				> .nav-link {

					> .icon-plus {
						position: absolute;
						right: 1em;
						top: 50%;
					}
				}
			}
		}

		.nav-language,
		.nav-utility {
			align-items: center;
			border-top: 1px solid color(gray, lighter);
			display: flex;
			height: 3em;
			justify-content: center;
			width: 100%;
		}

		.nav-language {
			background-color: color(gray, lightest);
			position: relative;
			@include z-index(priority3);

			
		}

		.nav-utility {
			align-items: center;
			background-color: color(primary);
			border-top: 1px solid color(gray, lighter);
			display: flex;
			height: 3em;
			position: relative;
			justify-content: center;
			width: 100%;
			@include z-index(priority1);

			.link {
				display: block;
				margin: 0 .25em;
				padding: .5em;
				color: color(white);

				&:hover {

					.icon {
						fill: color(white);
					}
				}
			}

			.icon {
				fill: color(white);
				height: 1em;
				width: 1em;
			}
		}
	}
}

// Show mobile Nav - slides in from left
#mobile-nav-toggle:checked ~ .layout-outer > .nav-main.-mobile {
	@include media($to-xlarge) {
		transform: none;
		transition: transform 300ms $ease-in-sine;
		@include z-index(priority4);
	}
}

// Move the .layout-site content over when nav is open
#mobile-nav-toggle:checked ~ .layout-outer > .layout-site {
	@include media($to-xlarge) {
		transform: translateX(-$mobile-nav-width);
		transition: transform 300ms $ease-in-sine;
		@include z-index(priority6);

		&:before {
			@include z-index(priority5);
			opacity: 1;
			transition: opacity 400ms $ease-in-sine, visiblity 400ms $ease-in-sine;
			visibility: visible;
		}
	}
}

// When nav is open, set the height to 100vh and make overflow hidden
#mobile-nav-toggle:checked ~ .layout-outer {
	@include media($to-xlarge) {
		overflow: hidden;
	}
}

// Hamburger Menu
.nav-burger {
	@include z-index(priority1);
	align-items: center;
	cursor: pointer;
	display: flex;
	height: $header-height-mobile;
	justify-content: center;
	right: 12px;
	position: fixed;
	top: 0;
	transition: transform 0.2s ease-in-out;
	width: 4em;

	&:active {
		transform: translateY(1px) scale( 0.95 );
	}

	@include media($xlarge-up) {
		display: none;
	}

	>.bars {
		display: block;
		height: 1.25em;
		transition: transform .4s;
		width: 1.75em;
		will-change: transform;
	}

	.bar {
		background: color(gray, light);
		border-radius: 2px;
		display: block;
		height: 3px;
		position: absolute;
		transition: transform .4s, margin .4s, opacity;
		width: 1.75em;

		&.-two {
			transform: translateY(8px);
		}

		&.-three {
			transform: translateY(16px);
		}
	}

	&.-is-open {
		>.bars {
			transform: translateY(9px);
		}

		.bar {
			&.-one {
    			transform:rotate(45deg);
    		}

    		&.-two {
    			opacity: 0;
    		}

    		&.-three {
    			transform: rotate(-45deg);
    		}
		}
	}
}
