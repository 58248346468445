.logos-inline {
	$border: 1px solid color(gray, lighter);
	background-color: color(white);
	border: {
		top: $border;
		bottom: $border;
	}

	// Only show on widescreen?
	&.-widescreen-only {

		@include media($to-xlarge) {
			display: none;
		}
	}

	> .container {
		align-items: center;
		display: flex;
		flex-flow: row wrap;
		min-height: 9.1875em;
		justify-content: center;

		> .logo {
			margin: 2em;
			max-width: 7.5em;

			img {
				max-width: 100%;
			}
		}
	}
}
